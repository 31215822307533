<template>
    <div>
<!--        <top-bar/>-->
        <div class="flex">
            <div class="container">
                <div class="content">
                    <router-view :key="key"/>
                </div>
            </div>
<!--            <div class="side">-->
<!--                <slide-bar/>-->
<!--            </div>-->
        </div>

    </div>
</template>

<script>
import slideBar from '../components/sideBar'
import topBar from "../components/topBar";
import devPixelRatio from "../utils/devicePixelRatio";

export default {
    name: "Layout",
    components: {slideBar, topBar},
    data() {
        return {};
    },
    created() {
        new devPixelRatio().init(); // 初始化页面比例
    },
    computed: {
        key() {
            return this.$route.path + Math.random();
        }
    },
    watch: {
    },
    methods: {},
    mounted() {
    },
}
</script>

<style scoped lang="scss">

//.layout {
//    width: 100vw;
//    height: 100%;
//    background: #EEF4F9;
//}

.container {
    width: 100vw;
    display: flex;
    //overflow: hidden;
}
.flex {
    width: 100vw;
    //height: 93%;
    display: flex;
    justify-content: space-between;
}
.side {
    width: 19%;
}
.content {
    width: 100vw;
}
</style>
