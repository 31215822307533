import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


// 解决vue-router在3.0版本以上重复点击的报错问题
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalReplace.call(this, location, onResolve, onReject);
    return originalReplace.call(this, location).catch(err => err);
};
const routes = [
    {
        path: '/Login',
        name: 'Login',
        component: () => import( '../views/Login.vue'),
        meta: {
            title: '登录'
        }
    },
    {
        path: '/Layout',
        name: 'Layout',
        component: () => import( '../views/Layout.vue'),
        meta: {},
        children: [
            {
                path: '/Index',
                name: 'Index',
                component: () => import( '../views/Index.vue'),
                meta: {
                    title: '首页'
                }
            },
        ]
    },

]



const router = new VueRouter({
    mode: 'hash',
    routes,
    scrollBehavior: () => {
        history.pushState(null, null, document.URL)
    }
})

export default router
