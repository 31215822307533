<template>
    <div @click.stop>
        <el-dialog custom-class="dialog"
                   :destroy-on-close="true"
                   :close-on-click-modal = "false"
                   title=""
                   :visible.sync="takeOrderVisible"
                   ref="dialog"
                   :before-close="takeOrderClose"
                   width="76vw"
                   top="5vw"
        >
            <div ref="takeCenter" style="width: 2rem;margin-right: 0.2rem;height: 7.68rem;">
                <el-table
                    v-loading="loading"
                    style="width: 100%"
                    class=""
                    stripe
                    ref="takeTable"
                    height= "7.68rem"
                    :data="orderList"
                    :row-style="rowStyle"
                    @row-click="handledbsupplier"
                    :row-class-name="tableRowClassName"
                    @row-dblclick="handledbClick"
                >
                    <el-table-column v-for="(item,index) in orderTitle" :key="index" :prop="item.value" :label="item.name"
                                     :width="item.width" :align="item.align || 'center'  " :formatter="item.formatter">
                    </el-table-column>
                </el-table>
            </div>
            <el-table
                v-loading="loading"
                style="width: 100%"
                class=""
                stripe
                height= "7.68rem"
                ref="table"
                :data="tableData"
            >
<!--                //:row-style="rowStyle"-->
<!--                @row-click="handledbsupplier"-->
<!--                :row-class-name="tableRowClassName"-->
                <el-table-column v-for="item in tableTitle" :key="item.name" :prop="item.value" :label="item.name"
                                 :width="item.width" :align="item.align || 'left'  " :formatter="item.formatter">
                </el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
               <div class="button_box">
                   <el-button type="primary" style="background: #CF7AE2;border-color: #CF7AE2;color: #FFFFFF;" @click="del">删除(del)</el-button>
                   <el-button type="primary" @click="takeOrderEnter">确认(enter)</el-button>
                   <el-button type="warning" style="background: #FFFFFF;border-color: #E9E9E9;color: #666666;"  @click="takeOrderClose">取消(esc)</el-button>
               </div>
            </div>
        </el-dialog>

    </div>
</template>

<script>
export default {
    name: "takeOrder",
    props:{
        /**
         * 弹框点开关闭
         * */
        takeOrderVisible: {
            default: false,
            type: Boolean
        },
        /**
         * 传参
         * */
        // formShop: {
        //     default: () => {
        //         return {
        //             keyword: '',
        //             page: 1,
        //             length: 10,
        //         }
        //     },
        //     type: Object
        // },
        /**
         * 键盘事件
         * */
        keynum: Number,
        /**
         * 判断退货
         * */
        returnGoods:Number

    },

    data() {
        return {
            // searchData: [],
            tableTitle: [
                {
                    name: '商品编号',
                    value: 'goods_code',
                    width:'120'
                },
                {
                    name: '商品名称',
                    value: 'goods_name',
                    // width:'150'
                },
                {
                    name: '商品条码',
                    value: 'goods_sku',
                    width:'200'
                },
                // {
                //     name: '规格',
                //     value: 'goods_spec',
                //     // width:'100'
                // },
                // {
                //     name: '型号',
                //     value: 'goods_model',
                //     // width:'100'
                // },
                {
                    name: '单位',
                    value: 'unit_name',
                    width:'80'
                },
                {
                    name: '数量',
                    value: 'stock_sales',
                    // width:'100'
                },
                {
                    name: '原价',
                    value: 'price_selling',
                    // width:'100'
                },
                {
                    name: '折扣',
                    value: 'discount_rate',
                    // width:'100'
                },
                {
                    name: '实价',
                    value: 'real_price',
                    // width:'100'
                },
                {
                    name: '成交金额',
                    value: 'deal_amount',
                    // width:'100'
                },
            ],
            orderTitle:[
                {
                    name: '单据编号',
                    value: 'order_no',
                    // width:'200'
                }
            ],
            orderList:[],
            tableData: [],
            loading: false,
            dataDetail:'',// 当前已选择行
            dataindex: 0, // 当前选择行的index
        }
    },
    mounted() {
    },
    created() {
    },
    methods: {
        /**
         * 双击选中
         * */
        handledbClick(row) {
            this.dataDetail=row
            this.dataindex=row.index
            this.takeOrderEnter()
        },
        assignment() {
            this.tableData = []
            this.dataindex = 0
            this.dataDetail = {}
        },
        /**
         * 删除
         * */
        del() {
            this.$axios.post('api/pos_client/order/remove_bak_order',{
                order_no:this.orderList[this.dataindex].order_no
            }).then(res => {
                    this.InterfaceList()
                }
            )
        },
        tabClick(index) {
            this.dataindex = index
            this.dataChange()
            this.$forceUpdate()
        },
        /**
         * table 滚动条
         * */
        scrollFileLocation() {
            // 整个table的高度
            const tableHeight = this.$refs.takeCenter.offsetHeight
            // console.log('整个table的高度',tableHeight)
            // table表格的头高度
            const tableHeader = this.$refs.takeTable.$refs.bodyWrapper.children[0].children[1].children[0].clientHeight
            // console.log('table表格的头高度',tableHeader)
            const tableBodyHeight = tableHeight - tableHeader;
            // console.log('整个table内容高度',tableBodyHeight)
            // 每个table中元素的高度
            const itemHeight = this.$refs.takeTable.$refs.bodyWrapper.children[0].children[1].children[0].clientHeight
            // console.log("每个table中元素的高度",itemHeight)
            this.$nextTick(() => {
                // 一个窗口可以展示的数据数量
                const showNum = tableBodyHeight / itemHeight;
                // console.log('一个窗口可以展示的数据数量',showNum)
                // 当前滚动条已经滚动的高度
                let scrolled = this.$refs.takeTable.bodyWrapper.scrollTop;
                // console.log('当前滚动条已经滚动的高度',scrolled)
                // 获取当前窗口第一个元素的下标
                const start = Math.round(scrolled / itemHeight);
                // console.log('获取当前窗口第一个元素的下标',start)
                // 获取当前窗口最后一个元素的下标
                const end = Math.round(start + showNum) - 1;
                // console.log('获取当前窗口最后一个元素的下标',end)
                // 为了文件完整显示的偏移量
                let offSet = 0;
                // 如果滚动条移动的很远再按上下键会重新定位
                if (start - this.dataindex > 1) {
                    this.$refs.takeTable.bodyWrapper.scrollTop = this.dataindex * itemHeight;
                    return;
                }
                if (this.dataindex - end > 1) {
                    this.$refs.takeTable.bodyWrapper.scrollTop = (this.dataindex - showNum + 1) * itemHeight;
                    return;
                }
                // 如果上下选择文件大于窗口则移动滚动条
                if (this.dataindex < start) {
                    offSet = scrolled % itemHeight;
                    scrolled -= itemHeight + offSet;
                    // console.log('this.dataindex < start',scrolled)
                } else if (this.dataindex > end) {
                    offSet = (scrolled + itemHeight * showNum) % itemHeight;
                    scrolled += 50;
                    // console.log('this.dataindex > end',scrolled)
                } else {
                    return;
                }
                // console.log('end',scrolled)
                this.$refs.takeTable.bodyWrapper.scrollTop = scrolled;
            });
        },
        keyupVip() {
            let Detail = {}
            Detail = this.utils.keyupVipCilck(this.orderList,this.keynum,-1,-1,this.dataindex,this.dataDetail)
            this.dataindex = Detail.dataindex
            this.dataDetail = Detail.dataDetail
            if(this.keynum == 38) {
                this.scrollFileLocation()
                // const table = this.$refs.takeTable;
                // // 拿到表格中承载数据的div元素
                // const divData = table.bodyWrapper;
                // if(this.dataindex > 14) {
                //     this.$nextTick(() => {
                //         divData.scrollTop -= 80
                //     })
                // }  else if(this.dataindex == this.orderList.length-1) {
                //     // // 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果(此配置为每1秒移动20像素)
                //     this.$nextTick(() => {
                //         this.$refs.takeTable.bodyWrapper.scrollTop = this.$refs.takeTable.bodyWrapper.scrollHeight;
                //     })
                //     // divData.scrollTop += (80*(Number(this.dataindex)-14));
                // } else if (this.dataindex < 14 ) {
                //     this.$nextTick(() => {
                //         divData.scrollTop = 0;
                //     })
                // }
            } else if(this.keynum == 40) {
                this.scrollFileLocation()
                // const table = this.$refs.takeTable;
                // // 拿到表格中承载数据的div元素
                // const divData = table.bodyWrapper;
                // if(this.dataindex > 14) {
                //     // // 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果(此配置为每1秒移动20像素)
                //     this.$nextTick(() => {
                //         divData.scrollTop += 80;
                //     })
                // } else if (this.dataindex  ==  this.orderList.length-1 ) {
                //     this.$nextTick(() => {
                //         divData.scrollTop = 0;
                //     })
                // } else if (this.dataindex<14) {
                //     this.$nextTick(() => {
                //         divData.scrollTop = 0;
                //     })
                // }
            }
            this.dataChange()
        },
        //改变表格选中某一行    的颜色
        rowStyle(row) {
            if(row.rowIndex == this.dataindex){
                let rowBackground = {}
                rowBackground.background = '#DCEDFF'
                return rowBackground
            }
        },
        tableRowClassName({ row, rowIndex }) {
            row.index = rowIndex;
        },
        //选择某一行
        handledbsupplier(row) {
            // console.log('row',row)
            this.dataDetail=row
            this.dataindex=row.index
            this.dataChange()
        },
        /**
         *  分页
         * */
        handleCurrentChangePage(e) {
            this.InterfaceList()
        },
        /**
         * 确认
         * */
        takeOrderEnter() {
            this.dataDetail = this.orderList[this.dataindex]
            this.$emit('takeOrderClick', this.dataDetail);
            this.$parent.takeOrderClose();
        },
        /**
         * 弹框关闭
         * */
        takeOrderClose() {
            this.$parent.takeOrderClose();
        },
        // 刷新
        onRefresh() {
            this.loading = true
            // this.formShop.page = 1
            this.InterfaceList()
        },
        dataChange() {
            this.tableData = this.orderList[this.dataindex].orderitem
        },
        //列表接口
        async InterfaceList() {
            await this.$axios.get('api/pos_client/order/bak_order_list',{
                params:{
                    store_code:this.$store.state.user.userInfo.other_info.store_code,
                    desk_code:this.$store.state.user.userInfo.other_info.desk_code,
                    order_type:this.returnGoods==0?1:2
                    // ...this.formShop
                }}).then(res => {
                    this.orderList = res.data.data
                    this.dataindex = 0
                    // this.searchData = res.data.data.page
                    this.loading = false
                    this.dataChange()
                }
            )
        },
    }
}
</script>

<style scoped lang="scss">
::v-deep {
    .el-dialog__body {
        display: flex;
    }
    .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
        background: none;
    }
    .dialog {
        background: #F1F1F1;
        height: 9.1rem;
    }
    .el-dialog__footer {
        text-align: center;
    }
    .el-dialog__header {
        //border-bottom: 0.01rem solid #E9E9E9;
    }
    .el-dialog__body {
        padding:0.1rem 0.2rem 0.2rem 0.2rem;
    }
    .el-button {
        //width: 1rem;
    }
    .el-table {
        height: 7.68rem;
        background-color: #FFF;
        overflow-y: auto;
    }
    .el-table::before {
        height: 0!important;
    }
    .el-table__cell {
        padding: 0.12rem!important;
    }
    .el-table .cell {
        line-height: 0.2rem;
        font-size: 0.14rem;
    }
}
.background {
    //border: 1px solid #4B83F0;
    background: #DCEDFF;
    border-radius: 0.02rem;
}
.backgroundnone {
    background: #FFFFFF;
}
.tabBox {
    width: 2rem;
    background-color: #FFF;
    margin-right: 0.1rem;
    >div {
        height: 0.5rem;
        line-height: 0.5rem;
        text-align: center;
    }
    >div:nth-child(1) {
        font-size: 0.16rem;
        font-weight: 500;
        color: #333333;
    }
}
.bottom_bottom {
    padding: 0.05rem 0.45rem;
    background-color: #FFF;
}
.bottom_bottom .el-pagination {
    display: flex;
    justify-content: flex-end;
}
</style>
