export default {
    /**
     * 当前日期 时分秒
     * */
    formatDate() {
        const nowDate = new Date();
        const date = {
            year: nowDate.getFullYear(),
            month: nowDate.getMonth() + 1,
            date: nowDate.getDate(),
            hours: nowDate.getHours(),
            minutes: nowDate.getMinutes(),
            seconds: nowDate.getSeconds()
        };

        const newmonth = date.month < 10 ? "0" + date.month : date.month;
        const newday = date.date < 10 ? "0" + date.date :date.date;
        const newminutes = date.minutes < 10 ? "0" + date.minutes : date.minutes;
        const newseconds = date.seconds < 10 ? "0" + date.seconds : date.seconds;
        const value =
            date.year +
            "-" +
            newmonth +
            "-" +
            newday +
            " " +
            date.hours +
            ":" +
            newminutes +
            ":" +
            newseconds;
        return value
    },
    /**
     * 转换日期
     * */
    dateTypeFormat (fmt, date) {
        let ret
        const opt = {
            'Y+': date.getFullYear().toString(), // 年
            'm+': (date.getMonth() + 1).toString(), // 月
            'd+': date.getDate().toString(), // 日
            'H+': date.getHours().toString(), // 时
            'M+': date.getMinutes().toString(), // 分
            'S+': date.getSeconds().toString() // 秒
            // 有其他格式化字符需求可以继续添加，必须转化成字符串
        }
        for (const k in opt) {
            ret = new RegExp('(' + k + ')').exec(fmt)
            if (ret) {
                fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
            }
        }
        return fmt
    },
    onlyNum(number_only) {
        //先把非数字的都替换掉，除了数字和小数点
        number_only = number_only.replace(/[^\d]/g, "");
        return number_only;
    },
    onlyNumOnePoint(number_only) {
        //先把非数字的都替换掉，除了数字和小数点
        number_only = number_only.replace(/[^\d.]/g, "");
        //必须保证第一个为数字而不是小数点
        number_only = number_only.replace(/^\./g, "");
        //保证只有出现一个小数点而没有多个小数点
        number_only = number_only.replace(/\.{2,}/g, ".");
        //保证小数点只出现一次，而不能出现两次以上
        number_only = number_only.replace(".","$#$").replace(/\./g, "").replace("$#$", ".");
        //保证只能输入两个小数
        number_only = number_only.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');
        return number_only;
    },
    /**
     * 键盘上下左右键控制表格选中行
     * */
    keyupVipCilck: function(list,keynum,page,total,dataindex,dataDetail) {
        // 表格为空，不执行下方
        if (list.length == 0) {
            return
        }
        if (keynum == 40) { // 下键
            if(dataindex == list.length-1){
                if(page !== -1) {
                    if(page < total) {
                        page++
                    } else if (page == total){
                        page = 1
                    }
                }
                dataindex = 0  //当选中的index与数据的天数相等时，在按下键就要返回第一个
                dataDetail = list[0]
            } else {
                dataindex = dataindex + 1
                dataDetail= list[dataindex]// 当前已选择行
            }
        } else if (keynum == 38) { // 上键
            if(dataindex == 0){  //当选中的index等于0时，说明到顶了，再按上键就要返回到表格的底部，并计算距离表格顶部的距离
                if(page !== -1) {
                    if(page == 1)  {
                        dataindex = list.length-1
                    } else {
                        page--
                        dataindex = 9
                    }
                } else {
                    dataindex = list.length-1
                }
                dataDetail = list[dataindex]// 当前已选择行
            } else {
                dataindex = dataindex-1
                dataDetail = list[dataindex]
            }
        } else if (keynum == 37) { //左键
            if (page > 1) {
                dataindex = 0
                page--
            }
        } else if (keynum == 39) { //右键
            if (page < total) {
                dataindex = 0
                page++
            }
        }
        let keyupVipdata = {}
        keyupVipdata.dataindex = dataindex
        keyupVipdata.dataDetail = dataDetail
        keyupVipdata.page = page
        return keyupVipdata
    }
}
