<template>
    <div class="menu">
        <div class="one_box">
            <div class="top_text">上一单 0090999200206</div>
            <div class="flex">
                <div class="text"><div class="mr-10">数量</div><div>20</div></div>
                <div class="text"><div class="mr-10">金额</div><div>20</div></div>
            </div>
            <div class="flex">
                <div class="text"><div class="mr-10">优惠</div><div>20</div></div>
                <div class="text"><div class="mr-10">找零</div><div>20</div></div>
            </div>
        </div>
        <div class="search_box">
            <img src="../assets/images/search.png">
            <el-input v-model="keyword" placeholder="请输入关键词" clearable></el-input>
        </div>
        <div class="member_box">
            <div class="member_box_ziliao">
                <img src="../assets/images/avater.png">
                <div>杨夕颜</div>
            </div>
            <div class="flex member_box_title">
                <div>
                    <div>465,300</div>
                    <div>消费(元)</div>
                </div>
                <div>
                    <div>300</div>
                    <div>积分</div>
                </div>
                <div>
                    <div>300</div>
                    <div>余额(元)</div>
                </div>
            </div>
            <div class="flex member_box_button">
                <div>
                    会员发卡
                    <img src="../assets/images/right.png">
                </div>
                <div>
                    会员维护
                    <img src="../assets/images/right.png">
                </div>
                <div>
                    积分处理
                    <img src="../assets/images/right.png">
                </div>
                <div>
                    会员储值
                    <img src="../assets/images/right.png">
                </div>
            </div>
        </div>
        <div class="shop_box">
            <div class="flex shop_box_title">
                <div>商品信息</div>
                <div>促销方案</div>
            </div>
            <div class="shop_box_content">
                <div>
                    <div>导购人员</div>
                    <div>李佳佳</div>
                </div>
                <div>
                    <div>商品条码</div>
                    <div>  0909302334</div>
                </div>
                <div>
                    <div>单品优惠</div>
                    <div>20</div>
                </div>
                <div>
                    <div>单品备注</div>
                    <div>备注</div>
                </div>
                <div>
                    <div>剩余库存</div>
                    <div>200</div>
                </div>
            </div>
        </div>
        <div class="grid-content">
            <div class="accounts">结算方式</div>
            <div class="pay">
                <div class="pay_but">
                    <img src="../assets/images/wx.png" alt="">
                    <div>微信支付</div>
                </div>
                <div class="pay_but">
                    <img src="../assets/images/zfb.png" alt="">
                    <div>支付宝</div>
                </div>
                <div  class="pay_but">
                    <img src="../assets/images/zft.png" alt="">
                    <div>现金支付</div>
                </div>
                <div class="pay_but">
                    <img src="../assets/images/xj.png" alt="">
                    <div>支付通</div>
                </div>
            </div>
        </div>
        <el-button class="button">结算+</el-button>
    </div>
</template>

<script>
export default {
    name: "sideBar",
    data() {
        return {
            keyword:''
        };
    },
    computed: {},
    watch: {},
    mounted() {
    },
    methods: {

    },
}
</script>

<style scoped lang="scss">
.menu {
    width: 100%;
    height: 100%;
}
.flex {
    display: flex;
}
.mr-10 {
    margin-right: 0.1rem;
    color: #999999;
}
.shop_box {
    background: #FFFFFF;
    border-radius: 0.2rem;
    margin-bottom: 0.1rem;
    padding: 0 0 24px 0;
    .shop_box_content {
        >div {
            display: flex;
            font-size: 0.16rem;
            font-weight: 500;
            color: #999999;
            margin-top: 20px;
            margin-left: 20px;
            >div:nth-child(2) {
                color: #333333;
                margin-left: 20px;
            }
        }
    }
    .shop_box_title {
        background: #999999 linear-gradient(180deg, #444643 0%, #0C0C0C 100%);
        font-size: 18px;
        font-weight: 600;
        color: #FFFFFF;
        justify-content: space-between;
        padding: 16px 0;
        >div{
            width: 50%;
            text-align: center;
            border-right: 1px solid #979797;
            cursor: pointer;
        }
        >div:nth-child(2) {
            border: none!important;
        }
    }
}
.member_box {
    padding: 15px;
    background: #FFFFFF;
    border-radius: 0.2rem;
    margin-bottom: 0.1rem;
    .member_box_button {
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 17px;
        >div {
            width: 49%;
            height: 44px;
            display: flex;
            align-items: center;
            background: #F6FBFF;
            border-radius: 0.2rem;
            justify-content: center;
            font-size: 0.16rem;
            font-weight: 400;
            color: #333333;
            margin-bottom: 5px;
            cursor: pointer;
            img {
                margin-left: 5px;
            }
        }
    }
    .member_box_ziliao {
        img {
            width: 55px;
            height: 0.55rem;
            margin-right: 17px;
        }
        font-size: 20px;
        font-weight: 500;
        color: #333333;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }
    .member_box_title {
        >div {
            width: 33%;
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            color: #333333;
            border-right: 1px solid #E5E5E5;
            >div:nth-child(2) {
                font-size: 11px;
                font-weight: 400;
                color: #999999;
            }

        }
        >div:nth-child(3) {
            border: none!important;
        }
    }
}
.button {
    width: 100%;
    background: #4B83F0;
    border-radius: 0.2rem;
    font-size: 20px;
    font-weight: 500;
    color: #FFFFFF;
    margin-top: 10px;
}
.grid-content {
    background: #FFFFFF;
    border-radius: 0.2rem;
    padding: 23px 12px 15px 21px;
    img {
        width: 25px;
        height: 25px;
        margin-right: 11px;
        margin-left: 22px;
    }
    .accounts {
        width: 100%;
        font-size: 20px;
        font-weight: 600;
        color: #333333;
        margin-bottom: 26px;
    }
    .pay {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .pay_but {
        width: 162px;
        height: 0.55rem;
        cursor:pointer;
        background-color: #F6FBFF;
        border-radius: 0.2rem;
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        display: flex;
        align-items: center;
        margin-bottom: 8px;
    }
}
.search_box {
    background: #FFFFFF;
    border-radius: 0.2rem;
    display: flex;
    align-items: center;
    padding: 0 15px;
    margin: 10px 0;
    img {
        width: 18px;
        height: 18px;
    }
    ::v-deep {
        .el-input__inner {
            height: 48px;
            border: none!important;
        }
        .el-input {

        }
    }
}
.one_box {
    background: #FFFFFF;
    border-radius: 0.2rem;
    margin-top: 10px;
    padding: 15px 10px;
    font-size: 14px;
    .top_text {
        font-size: 20px;
        font-weight: 500;
        color: #333333;
    }
    .text {
        width: 50%;
        margin-top: 12px;
        display: flex;
    }
}
</style>
