<template>
    <div @click.stop>
        <el-dialog custom-class="dialog"
                   :destroy-on-close="true"
                   :close-on-click-modal = "false"
                   title=""
                   :visible.sync="takeStockVisible"
                   ref="dialog"
                   :before-close="takeStockClose"
                   width="90vw"
                   top="6vw"
        >
            <div slot="title" class="new-evaluationTitle">
                {{title_text}}
            </div>
            <div class="flex flex-justify-content-between flex-child-center" style="margin-bottom: 0.1rem">
                <div style="display: flex;align-items: center;">
                    <div style="display: flex;align-items: center;cursor: pointer;">
                        <div style="margin-right: 0.1rem;color: #999999;">开始日期</div>
                        <el-date-picker type="date" placeholder="选择日期"  value-format="yyyy-MM-dd"
                                        v-model="formShop.start_time"></el-date-picker>
                    </div>
                    <div style="display: flex;align-items: center;cursor: pointer;">
                        <div style="margin-right: 0.1rem;color: #999999;">结束日期</div>
                        <el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd"
                                        v-model="formShop.end_time"></el-date-picker>
                    </div>
                    <div style="display: flex;align-items: center;">
                        <div style="margin-right: 0.1rem;color: #999999;">仓库</div>
                        <el-select v-model="formShop.ware_code" placeholder="请选择"@change="staffType($event)">
                            <el-option v-for="item in staff_typeList" :key="item.ware_code" :label="item.name"
                                       :value="item.ware_code"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="button" @click="search">查询</div>
            </div>
            <div class="flex">
                <div style="width: 9rem;margin-right: 0.2rem;height: 6rem" ref="takeCenter">
                    <el-table
                        v-loading="loading"
                        style="width: 100%"
                        class=""
                        stripe
                        ref="shopTable"
                        height= "6rem"
                        :data="orderList"
                        :row-style="rowStyle"
                        @row-click="handledbsupplier"
                        :row-class-name="tableRowClassName"
                        @selection-change="handleSelectionChange"
                    >
                        <el-table-column
                            center
                            type="selection"
                            align="center"
                            width="70">
                        </el-table-column>
                        <el-table-column v-for="(item,index) in orderTitle" :key="index" :prop="item.value" :label="item.name"
                                         :width="item.width" :align="item.align || 'center'  " :formatter="item.formatter">
                        </el-table-column>
                    </el-table>

                </div>
                <div style="width: 9.4rem;">
                    <el-table
                        v-loading="loading"
                        style="width: 100%"
                        class=""
                        stripe
                        height= "6rem"
                        ref="table"
                        :data="tableData"
                        :row-class-name="tableRowClassName"
                    >
                        <!--                //:row-style="rowStyle"-->
                        <!--                @row-click="handledbsupplier"-->
                        <!--                :row-class-name="tableRowClassName"-->
                        <el-table-column v-for="item in tableTitle" :key="item.name" :prop="item.value" :label="item.name"
                                         :width="item.width" :align="item.align || 'left'  " :formatter="item.formatter">
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="bottom_bottom">
                <el-pagination
                    background
                    @current-change="handleCurrentChangePage"
                    :current-page.sync="formShop.page"
                    :pager-count="9"
                    layout="prev, pager, next"
                    :hide-on-single-page="true"
                    :total="searchData.total"
                >
                </el-pagination>
            </div>
            <div slot="footer" class="dialog-footer padding-30 no-padding-left no-padding-right no-padding-top">
                <div class="button_box">
                    <el-button type="primary" @click="addStock">新增</el-button>
                    <el-button type="primary"style="background: #3EB679;border-color: #3EB679;color: #FFFFFF;" @click="editStock">修改</el-button>
                    <el-button type="primary" style="background: #CF7AE2;border-color: #CF7AE2;color: #FFFFFF;" @click="del">删除</el-button>
                    <!--                <el-button type="primary"style="width: 120px"  @click="takeStockEnter">生成报损报溢</el-button>-->
                    <el-button type="warning" style="background: #FFFFFF;border-color: #E9E9E9;color: #666666;"  @click="takeStockClose">退出</el-button>
                </div>
            </div>
        </el-dialog>

    </div>
</template>

<script>
export default {
    name: "takeStock",
    props:{
        /**
         * 弹框点开关闭
         * */
        takeStockVisible: {
            default: false,
            type: Boolean
        },
        /**
         * 键盘事件
         * */
        keynum: Number,
        /**
         * 头部文字
         * */
        title_text: {
            default: '库存盘点',
            type: String
        },

    },

    data() {
        return {
            // searchData: [],
            tableTitle: [
                {
                    name: '行号',
                    value: 'index',
                    width:'70',
                    formatter: (row) => {
                        return  row.index+1;
                    }
                },
                {
                    name: '货号',
                    value: 'goods_code',
                },
                {
                    name: '商品名称',
                    value: 'goods.name',
                },
                {
                    name: '商品条码',
                    value: 'goods_sku',
                    width:'200',
                },
                // {
                //     name: '规格',
                //     value: 'goods_spec',
                // },
                {
                    name: '到期日期',
                    value: 'validity',
                },
                {
                    name: '单位',
                    value: 'unit.name',
                    width:'80',

                },
                {
                    name: '盘点数',
                    value: 'number',
                },
            ],
            orderTitle:[
                {
                    name: '行号',
                    value: 'check_code',
                    width:'70',
                    formatter: (row) => {
                        return  row.index+1;
                    }
                },
                {
                    name: '单据编号',
                    value: 'check_code',
                    width:'130',
                },
                {
                    name: '盘点日期',
                    value: 'create_at',
                },
                {
                    name: '仓库',
                    value: 'ware.name',
                },
                {
                    name: '盘点人',
                    value: 'preparation.username',
                    width:'100',
                },
                {
                    name: '截止日期',
                    value: 'check_time',
                    // width:'120'
                },
            ],
            orderList:[],
            tableData: [],
            loading: false,
            dataDetail:'',// 当前已选择行
            dataindex: 0, // 当前选择行的index
            warehouseDetail:{},
            /**
             * 传参
             * */
            formShop: {
                ware_code:'',
                start_time: '',
                end_time: '',
                page: 1,
                length: 10,
            },
            searchData: [],
            multipleSelection:[],
            staff_typeList:[]
        }
    },
    mounted() {
    },
    created() {
        this.$store.watch((state, getters) => {
            this.warehouseDetail = state.warehouseDetail
            this.formShop.ware_code = this.warehouseDetail.ware_code
        }, () => {

        })
    },
    methods: {
        /**
         * 确认
         * */
        takeStockEnter() {
            this.dataDetail = this.orderList[this.dataindex]
            this.$emit('takeStockClick', this.dataDetail);
            this.$parent.takeStockClose();
        },
        /**
         * 新增盘点点击
         * */
        addStock() {
            this.$parent.addnewCount();
        },
        /**
         * 编辑盘点点击
         * */
        editStock() {
            this.dataDetail = this.orderList[this.dataindex]
            this.$emit('editStockClick', this.dataDetail);
        },
        staffType(e) {
            this.formShop.ware_code = e
        },
        search() {
            this.loading = true
            this.formShop.page = 1
            this.InterfaceList()
        },
        //多选
        handleSelectionChange(val) {
            let ids = val.map(item => {
                return item.id
            })
            this.multipleSelection = ids;
        },
        assignment() {
            this.$axios.get('api/pos_client/base/get_ware_list').then(res => {
                this.staff_typeList = res.data.data
            })
            this.formShop = {
                ware_code: this.warehouseDetail.ware_code,
                start_time: '',
                end_time: '',
                page: 1,
                length: 10
            }
        },
        /**
         * 删除
         * */
        del() {
            if(this.multipleSelection.length<=0) {
                this.$message.error('请选择要删除的盘点单!');
                return;
            }
            this.$confirm('确定要删除选择的盘点单吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.post('api/pos_client/order/remove_check',{
                    check_ids:this.multipleSelection
                }).then(res => {
                        this.InterfaceList()
                    }
                )
            }).catch(() => {
            });
        },
        tabClick(index) {
            this.dataindex = index
            this.dataChange()
            this.$forceUpdate()
        },
        /**
         * table 滚动条
         * */
        scrollFileLocation() {
            const tableHeight = this.$refs.takeCenter.offsetHeight
            const tableHeader = this.$refs.shopTable.$refs.bodyWrapper.children[0].children[1].children[0].clientHeight
            const tableBodyHeight = tableHeight - tableHeader;
            const itemHeight = this.$refs.shopTable.$refs.bodyWrapper.children[0].children[1].children[0].clientHeight
            this.$nextTick(() => {
                const showNum = tableBodyHeight / itemHeight;
                let scrolled = this.$refs.shopTable.bodyWrapper.scrollTop;
                const start = Math.round(scrolled / itemHeight);
                const end = Math.round(start + showNum) - 1;
                let offSet = 0;
                if (start - this.dataindex > 1) {
                    this.$refs.shopTable.bodyWrapper.scrollTop = this.dataindex * itemHeight;
                    return;
                }
                if (this.dataindex - end > 1) {
                    this.$refs.shopTable.bodyWrapper.scrollTop = (this.dataindex - showNum + 1) * itemHeight;
                    return;
                }
                if (this.dataindex < start) {
                    offSet = scrolled % itemHeight;
                    scrolled -= itemHeight + offSet;
                } else if (this.dataindex > end) {
                    offSet = (scrolled + itemHeight * showNum) % itemHeight;
                    scrolled += 50;
                } else {
                    return;
                }
                this.$refs.shopTable.bodyWrapper.scrollTop = scrolled;
            });
        },
        keyupVip() {
            let Detail = {}
            Detail = this.utils.keyupVipCilck(this.orderList,this.keynum,this.formShop.page,this.searchData.pages,this.dataindex,this.dataDetail)
            this.dataindex = Detail.dataindex
            this.dataDetail = Detail.dataDetail
            if (this.formShop.page !== Detail.page) {
                this.formShop.page = Detail.page
                this.InterfaceList()
            }
            if(this.keynum == 38) {
                this.scrollFileLocation()
            }  else if(this.keynum == 40) {
                this.scrollFileLocation()
            }
            // if(this.keynum == 38) {
            //     const table = this.$refs.stockTable;
            //     // 拿到表格中承载数据的div元素
            //     const divData = table.bodyWrapper;
            //     if(this.dataindex == this.orderList.length-1) {
            //         // // 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果(此配置为每1秒移动20像素)
            //         console.log(this.dataindex,this.orderList.length-1)
            //         divData.scrollTop += (80*(Number(this.dataindex)-14));
            //     } else if (this.dataindex < 5 ) {
            //         divData.scrollTop = 0;
            //     }
            // } else if(this.keynum == 40) {
            //     const table = this.$refs.stockTable;
            //     // 拿到表格中承载数据的div元素
            //     const divData = table.bodyWrapper;
            //     if(this.dataindex > 14) {
            //         // // 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果(此配置为每1秒移动20像素)
            //         divData.scrollTop += 80;
            //     } else if (this.dataindex  ==  this.orderList.length-1 ) {
            //         divData.scrollTop = 0;
            //     } else if (this.dataindex<5) {
            //         divData.scrollTop = 0;
            //     }
            // }
            this.dataChange()
        },
        //改变表格选中某一行    的颜色
        rowStyle(row) {
            if(row.rowIndex == this.dataindex){
                let rowBackground = {}
                rowBackground.background = '#DCEDFF'
                return rowBackground
            }
        },
        tableRowClassName({ row, rowIndex }) {
            row.index = rowIndex;
        },
        //选择某一行
        handledbsupplier(row) {
            // console.log('row',row)
            this.dataDetail=row
            this.dataindex=row.index
            this.dataChange()
        },
        /**
         *  分页
         * */
        handleCurrentChangePage(e) {
            this.InterfaceList()
        },

        /**
         * 弹框关闭
         * */
        takeStockClose() {
            this.$parent.takeStockClose();
        },
        // 刷新
        onRefresh() {
            this.loading = true
            this.formShop.page = 1
            this.InterfaceList()
        },
        dataChange() {
            if(this.orderList.length>0) {
                this.tableData = this.orderList[this.dataindex].checkitem
            }
        },
        //列表接口
        async InterfaceList() {
            await this.$axios.get('api/pos_client/order/stock_check_list',{
                params:{
                    ...this.formShop
                }}).then(res => {
                    this.orderList = res.data.data.list
                    this.dataindex = 0
                    this.tableData = []
                    this.searchData = res.data.data.page
                    this.loading = false
                    this.dataChange()
                }
            )
        },
    }
}
</script>

<style scoped lang="scss">
.button {
    width: 1rem;
    height: 0.55rem;
    background: #4B83F0;
    box-shadow: 0px 0px 0.2rem 0px rgba(146,146,146,0.5);
    border-radius: 0.05rem;
    font-size: 0.16rem;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 0.55rem;
    text-shadow: 0px 0px 0.2rem rgba(146,146,146,0.5);
    text-align:  center;
    cursor: pointer;
}
::v-deep {
    .el-dialog__body {
        padding: 0.1rem 0.2rem!important;
    }
    .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
        background: none;
    }
    .dialog {
        background: #F1F1F1;
        height: 8.5rem;
    }
    .el-dialog__footer {
        text-align: right;
    }
    .el-dialog__header {
        border-bottom: 0.01rem solid #E9E9E9;
        font-weight: 600;
    }
    .el-input {
        width: 4.4rem;
        height: 0.55rem!important;
        margin-right: 0.3rem;

    }
    .el-input__inner {
        height: 0.55rem!important;
        border: none!important;
    }
    .el-dialog__body {
        padding:0.1rem 0.2rem 0.2rem 0.2rem;
    }
    .el-button {
        width: 1rem;
    }
    .el-table {
        height: 6rem;
        background-color: #FFF;
        overflow-y: auto;
    }
}
.background {
    //border: 1px solid #4B83F0;
    background: #DCEDFF;
    border-radius: 0.02rem;
}
.backgroundnone {
    background: #FFFFFF;
}
.tabBox {
    width: 2rem;
    background-color: #FFF;
    margin-right: 0.1rem;
    >div {
        height: 0.5rem;
        line-height: 0.5rem;
        text-align: center;
    }
    >div:nth-child(1) {
        font-size: 0.16rem;
        font-weight: 500;
        color: #333333;
    }
}
.bottom_bottom {
    float: left;
    padding: 0.05rem 0.45rem;
    //background-color: #FFF;
}
.bottom_bottom .el-pagination {
    display: flex;
    justify-content: flex-end;
}
</style>
