<template>
    <div @click.stop>
        <el-dialog custom-class="dialog"
                   :destroy-on-close="true"
                   :close-on-click-modal = "false"
                   title=""
                   :visible.sync="storedVisible"
                   ref="dialog"
                   :before-close="storedClose"
                   width="30vw"
                   top="12vw"
        >
            <div slot="title" class="new-evaluationTitle">
                {{title_text}}
            </div>
            <div class="new-evaluation">
                <el-form  :model="queryParams" @submit.native.prevent >
                    <el-form-item label="积分" v-if="title_text == '积分支付'">
                        <el-input v-model="queryParams.amount" ref="amount"  @keyup.enter.native="amountEnter"></el-input>
                    </el-form-item>
                    <el-form-item label="金额" v-if="title_text == '储值卡支付'">
                        <el-input v-model="queryParams.amount" ref="amount"  @keyup.enter.native="amountEnter"></el-input>
                    </el-form-item>
                    <el-form-item label="卡号">
                        <el-input v-model="queryParams.card_code" ref="card_code" @keyup.enter.native="card_codeEnter"></el-input>
                    </el-form-item>
                    <!--                    <el-button type="primary" class="cardbutton" @click="cardclick()">读IC卡</el-button>-->
                </el-form>

            </div>
            <div slot="footer" class="dialog-footer padding-30 no-padding-left no-padding-right no-padding-top">
                <div class="button_box">
                    <el-button type="primary" @click="storedEnter">确认(Enter)</el-button>
                    <el-button type="warning" style="background: #FFFFFF;border-color: #E9E9E9;color: #666666;"  @click="storedClose">取消(Esc)</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "stored",
    props:{
        /**
         * 弹框点开关闭
         * */
        storedVisible: {
            default: false,
            type: Boolean
        },
        /**
         * 头部文字
         * */
        title_text: {
            default: '储值卡支付',
            type: String
        },
        /**
         * 金额
         * */
        surplus_real: {
            default: '',
            type: Number
        },
        /**
         * 会员信息
         * */
        userform: Object,
    },
    data() {
        return {
            queryParams:{
                card_code:'',
                amount:''
            }
        }
    },
    methods: {
        assignment() {
            // console.log('this.userform',this.userform)
            if(this.userform.card_code) {
                this.queryParams.card_code = this.userform.card_code
            }
            // console.log('this.surplus_real11111',this.surplus_real)
            this.queryParams.amount = this.surplus_real
            // console.log('this.queryParams.amount',this.queryParams.amount)
        },
        /**
         * 输入框获取焦点
         * */
        inputFocus() {
            //获取输入框焦点
            this.$nextTick(() => {
                this.$refs.amount.focus();
            })
        },
        inputFocus1() {
            //获取输入框焦点
            this.$nextTick(() => {
                this.$refs.card_code.focus();
            })
        },
        amountEnter() {
            if(this.queryParams.amount =='') {
                this.inputFocus()
            } else {
                this.$emit('storedClick1', this.queryParams);
                this.inputBlurs()
                this.inputFocus1()
            }

        },
        card_codeEnter() {
            if(this.queryParams.card_code =='') {
                this.inputFocus1()
            } else {
                this.$emit('storedClick1', this.queryParams);
                this.inputBlurs1()
            }
        },
        /**
         * 输入框失去焦点
         * */
        inputBlurs() {
            //获取输入框焦点
            this.$nextTick(() => {
                this.$refs.amount.blur();
            })
        },
        inputBlurs1() {
            //获取输入框焦点
            this.$nextTick(() => {
                this.$refs.card_code.blur();
            })
        },
        /**
         * 弹框关闭
         * */
        storedClose() {
            this.$parent.storedClose();
        },
        /**
         * 输入款回车事件 确认事件
         * */
        storedEnter() {
            if(this.queryParams.amount!=='' && this.queryParams.card_code !=='') {
                this.$emit('storedClick', this.queryParams);
            }

        },
        /**
         * 清空
         * */
        qingkong() {
            this.queryParams = {
                card_code:'',
                amount:''
            }
            this.$emit('storedClick1', this.queryParams);
        },
        qingkong1() {
            this.queryParams.card_code = ''
            this.$emit('storedClick1', this.queryParams);
        },
    }
}
</script>

<style scoped lang="scss">
::v-deep {
    .el-dialog__footer {
        text-align: center;
    }
    .el-dialog__header {
        border-bottom: 0.01rem solid #E9E9E9;
    }
    .el-input {
        width: 4rem;
    }
    .el-form-item {
        display: flex;
        align-items: center;
        margin-bottom: 0.2rem;
    }
    .el-form {
        padding: 0 0.3rem;
        //display: flex;
        //align-items: center;
        //justify-content: space-between;
    }

}
.cardbutton {
    background: linear-gradient(180deg, #2F302E 0%, #606060 100%);
    border-radius: 0.05rem;
    border: none;
}
</style>
