import fa from "element-ui/src/locale/lang/fa";

export default {
    state    : {
        token      : '',
        loginStatus: false,
        userInfo   : '',
        username   : '',
        avatar      :'',
    },
    getters  : {},
    mutations: {
        setUserInfo(state, userinfo) {
            state.loginStatus = userinfo.status;
            state.userInfo    = userinfo.data;
            state.username    = userinfo.username;
            state.avatar      = userinfo.avatar;
        },

        setToken(state, token) {
            state.token = token;
        },
    },
    actions  : {}
}
