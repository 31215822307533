<template>
    <div @click.stop>
        <el-dialog custom-class="dialog"
                   :destroy-on-close="true"
                   :close-on-click-modal = "false"
                   title=""
                   :visible.sync="cashVisible"
                   ref="dialog"
                   :before-close="cashClose"
                   width="50vw"
                   top="2vw"
        >
            <div slot="title" class="new-evaluationTitle">
                {{title_text}}
            </div>
            <div class="new-evaluation">
                <el-form>
                    <el-form-item label="门店名称" prop="startTime">
                        <el-input v-model="details.store_name" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="收银员" prop="startTime">
                        <el-input v-model="details.staff_name" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="收银台" prop="startTime">
                        <el-input v-model="details.desk_name" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="开始日期" prop="startTime">
                        <el-input v-model="details.start_time" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="首笔" prop="startTime">
                        <el-input v-model="details.first_order" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="结束日期" prop="startTime">
                        <el-input v-model="details.end_time" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="末笔" prop="startTime">
                        <el-input v-model="details.last_order" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="笔数" prop="startTime">
                        <el-input v-model="details.order_count" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="销售数量" prop="startTime">
                        <el-input v-model="details.sale_count" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="成交金额" prop="startTime">
                        <el-input v-model="details.amount_total" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="退货数量" prop="startTime">
                        <el-input v-model="details.refund_number_goods" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="退货金额" prop="startTime">
                        <el-input v-model="details.refund_amount_total" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="数量合计" prop="startTime">
                        <el-input v-model="details.number_total" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="实销金额" prop="startTime">
                        <el-input v-model="details.amount_shixiao" disabled></el-input>
                    </el-form-item>
                </el-form>
                <div class="tabbox">
                    <div class="tabitem" >
                        销售汇总
                    </div>
                </div>
                <div class="two">
                    <el-form>
                        <el-form-item label="零售金额" prop="startTime">
                            <el-input v-model="details.sale_amount" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="优惠金额" prop="startTime">
                            <el-input v-model="details.amount_discount" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="销售收款" prop="startTime">
                            <el-input v-model="details.sale_pay_amount" disabled></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="tabbox">
                    <div class="tabitem" >
                        结算明细
                    </div>
                </div>
                <div class="two">
                    <el-form>
                        <el-form-item label="现金收银" prop="startTime">
                            <el-input v-model="details.cash" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="银行卡收银" prop="startTime">
                            <el-input v-model="details.bank" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="储值卡收银" prop="startTime">
                            <el-input v-model="details.card" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="微信收银" prop="startTime">
                            <el-input v-model="details.wechat" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="支付宝收银" prop="startTime">
                            <el-input v-model="details.alipay" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="积分收银" prop="startTime">
                            <el-input v-model="details.integral" disabled></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="tabbox">
                    <div class="tabitem" >
                        其它
                    </div>
                </div>
                <div class="two">
                    <el-form>
                        <el-form-item label="充值金额" prop="startTime">
                            <el-input v-model="details.rechange_amount" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="赠送金额" prop="startTime">
                            <el-input v-model="details.rechange_give_amount" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="" prop="startTime">
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div slot="footer" class="dialog-footer padding-30 no-padding-left no-padding-right no-padding-top">
                <div class="button_box">
                    <!--                <el-button type="primary" @click="cashEnter">打印</el-button>-->
                    <el-button type="warning" style="background: #FFFFFF;border-color: #E9E9E9;color: #666666;"  @click="cashClose">退出(Esc)</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "cash",
    props:{
        /**
         * 弹框点开关闭
         * */
        cashVisible: {
            default: false,
            type: Boolean
        },
        /**
         * 头部文字
         * */
        title_text: {
            default: '现金台账',
            type: String
        },

    },
    data() {
        return {
            warehouseDetail:{},
            details:{}
        }
    },
    created() {
        this.$store.watch((state, getters) => {
            this.warehouseDetail = state.warehouseDetail
        }, () => {

        })
    },
    methods: {
        /**
         * 弹框关闭
         * */
        cashClose() {
            this.$parent.cashClose();
        },
        /**
         * 接口
         * */
        async onRefresh() {
            await this.$axios.get('api/pos_client/order/cash_ledger',{
                params:{
                    desk_code:this.$store.state.user.userInfo.other_info.desk_code,
                    store_code:this.$store.state.user.userInfo.other_info.store_code,
                }}).then(res => {
                    this.details = res.data.data
                }
            )
        },
        /**
         * 重新赋值
         * */
        assignment() {

        }
    }
}
</script>

<style scoped lang="scss">
.tabbox{
    display: flex;
    margin-bottom: 0.2rem;
    .tabitem {
        width: 1.22rem;
        height: 0.36rem;
        border: 0.01rem solid #4B83F0;
        background: #4B83F0;
        font-size: 0.16rem;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 0.36rem;
        text-align: center;
    }
}
::v-deep {
    .el-form-item__content {
        line-height: 0.3rem;
    }
    .el-dialog__body {
        padding: 0.1rem 0.2rem 0 0.2rem!important;
    }
    //.el-dialog {
    //    height: 10.2rem;
    //}
    .el-input.is-disabled .el-input__inner {
        color: #333333;
        background: #FFFFFF;
    }
    .el-dialog__footer {
        text-align: right;
    }
    .el-dialog__header {
        border-bottom: 0.01rem solid #E9E9E9;
    }
    .el-input {
        width: 3.1rem;
    }
    .el-form-item {
        width: 4rem;
        display: flex;
        align-items: center;
        margin-bottom: 0.1rem;
    }
    .el-form {
        //padding: 0 0.3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .el-form-item__label {
        text-align: right;
        font-size: 0.16rem;
        font-weight: 400;
        color: #999999;
        width: 1rem;
    }
}
.two {
    ::v-deep {
        .el-input {
            width: 1.8rem;
        }
        .el-form-item {
            width: 2.8rem;
        }
    }
}
.three {

}
</style>
