<template>
    <div @click.stop>
        <el-dialog custom-class="dialog"
                   :destroy-on-close="true"
                   :close-on-click-modal = "false"
                   title=""
                   :visible.sync="detailsVisible"
                   ref="dialog"
                   :before-close="detailsClose"
                   width="65vw"
                   top="5vw"
        >
            <div slot="title" class="new-evaluationTitle">
                {{title_text}}
            </div>
            <div style="width: 6rem;margin-right: 0.2rem;height: 7.68rem;" ref="takeCenter">
                <el-table
                    v-loading="loading"
                    style="width: 100%"
                    class=""
                    stripe
                    ref="shopTable"
                    height= "7.68rem"
                    :data="orderList"
                    :row-style="rowStyle"
                    @row-click="handledbsupplier"
                    :row-class-name="tableRowClassName"
                >
                    <el-table-column v-for="(item,index) in orderTitle" :key="index" :prop="item.value" :label="item.name"
                                     :width="item.width" :align="item.align || 'center'  " :formatter="item.formatter">
                    </el-table-column>
                </el-table>
            </div>
            <el-table
                v-loading="loading"
                style="width: 100%"
                class=""
                stripe
                height= "7.68rem"
                ref="table"
                :data="tableData"
            >
<!--                //:row-style="rowStyle"-->
<!--                @row-click="handledbsupplier"-->
<!--                :row-class-name="tableRowClassName"-->
                <el-table-column v-for="item in tableTitle" :key="item.name" :prop="item.value" :label="item.name"
                                 :width="item.width" :align="item.align || 'left'  " :formatter="item.formatter">
                </el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer padding-30 no-padding-left no-padding-right no-padding-top">
                <div class="button_box">
                    <el-button type="warning" style="background: #FFFFFF;border-color: #E9E9E9;color: #666666;"  @click="detailsClose">取消(esc)</el-button>
                </div>
            </div>
        </el-dialog>

    </div>
</template>

<script>
export default {
    name: "shopDetail",
    props:{
        /**
         * 弹框点开关闭
         * */
        detailsVisible: {
            default: false,
            type: Boolean
        },
        /**
         *  商品列表
         * */
        tableDataIndex: Array,
        /**
         * 键盘事件
         * */
        keynum: Number,
        /**
         * 头部文字
         * */
        title_text: {
            default: '商品信息',
            type: String
        },

    },

    data() {
        return {
            // searchData: [],
            tableTitle: [
                {
                    name: '行号',
                    value: 'index',
                    width:'80',
                },
                {
                    name: '列名',
                    value: 'name',
                    width:'150'
                },
                {
                    name: '信息',
                    value: 'information',
                }

            ],
            orderTitle:[
                {
                    name: '行号',
                    value: 'index',
                    width:'80',
                    formatter: (row) => {
                        return  row.index+1;
                    }
                },
                {
                    name: '货号',
                    value: 'goods_code',
                    // width:'200'
                },
                // {
                //     name: 'sku',
                //     value: 'goods_sku',
                //     width:'200'
                // },
                {
                    name: '品名',
                    value: 'goods_name',
                    // width:'200'
                },
                {
                    name: '单位',
                    value: 'unit_name',
                    width:'80'
                },
            ],
            orderList:[],
            tableData: [],
            loading: false,
            dataDetail:'',// 当前已选择行
            dataindex: 0, // 当前选择行的index
            detai:{}
        }
    },
    mounted() {
    },
    created() {
    },
    methods: {
        /**
         * table 滚动条
         * */
        scrollFileLocation() {
            const tableHeight = this.$refs.takeCenter.offsetHeight
            const tableHeader = this.$refs.shopTable.$refs.bodyWrapper.children[0].children[1].children[0].clientHeight
            const tableBodyHeight = tableHeight - tableHeader;
            const itemHeight = this.$refs.shopTable.$refs.bodyWrapper.children[0].children[1].children[0].clientHeight
            this.$nextTick(() => {
                const showNum = tableBodyHeight / itemHeight;
                let scrolled = this.$refs.shopTable.bodyWrapper.scrollTop;
                const start = Math.round(scrolled / itemHeight);
                const end = Math.round(start + showNum) - 1;
                let offSet = 0;
                if (start - this.dataindex > 1) {
                    this.$refs.shopTable.bodyWrapper.scrollTop = this.dataindex * itemHeight;
                    return;
                }
                if (this.dataindex - end > 1) {
                    this.$refs.shopTable.bodyWrapper.scrollTop = (this.dataindex - showNum + 1) * itemHeight;
                    return;
                }
                if (this.dataindex < start) {
                    offSet = scrolled % itemHeight;
                    scrolled -= itemHeight + offSet;
                } else if (this.dataindex > end) {
                    offSet = (scrolled + itemHeight * showNum) % itemHeight;
                    scrolled += 50;
                } else {
                    return;
                }
                this.$refs.shopTable.bodyWrapper.scrollTop = scrolled;
            });
        },
        assignment() {
            this.tableData = []
            this.orderList = this.tableDataIndex
            this.dataChange()
        },
        keyupVip() {
            let Detail = {}
            Detail = this.utils.keyupVipCilck(this.orderList,this.keynum,-1,-1,this.dataindex,this.dataDetail)
            this.dataindex = Detail.dataindex
            this.dataDetail = Detail.dataDetail
            if(this.keynum == 38) {
                this.scrollFileLocation()
            } else if(this.keynum == 40) {
                this.scrollFileLocation()
            }
            this.dataChange()
        },
        //改变表格选中某一行    的颜色
        rowStyle(row) {
            if(row.rowIndex == this.dataindex){
                let rowBackground = {}
                rowBackground.background = '#DCEDFF'
                return rowBackground
            }
        },
        tableRowClassName({ row, rowIndex }) {
            row.index = rowIndex;
        },
        //选择某一行
        handledbsupplier(row) {
            // console.log('row',row)
            this.dataDetail=row
            this.dataindex=row.index
            this.dataChange()
        },
        /**
         *  分页
         * */
        handleCurrentChangePage(e) {
            this.InterfaceList()
        },
        /**
         * 确认
         * */
        detailsEnter() {
            this.dataDetail = this.orderList[this.dataindex]
            this.$emit('detailsClick', this.dataDetail);
            this.$parent.detailsClose();
        },
        /**
         * 弹框关闭
         * */
        detailsClose() {
            this.$parent.detailsClose();
        },
        dataChange() {
            this.$axios.get('api/pos_client/base/get_goods_stock',{
                params:{
                    ware_code:this.orderList[this.dataindex].ware_code,
                    goods_code:this.orderList[this.dataindex].goods_code,
                    goods_item_id:this.orderList[this.dataindex].goods_item_id,
                }}).then(res => {
                    this.detai = res.data.data
                }
            )
            setTimeout(() => {
                const b = []
                for (let a = 0; a<6;a++) {
                    let c = {
                        index:a+1,
                        name:'',
                        information:''
                    }
                    b.push(c)
                }
                b[0].name = '导购'
                b[0].information = this.$store.state.user.userInfo.user_info.username
                b[1].name = '商品条码'
                b[1].information = this.orderList[this.dataindex].goods_sku
                // b[2].name = '商品规格'
                // b[2].information = this.orderList[this.dataindex].goods_spec
                let dis_real = Number(this.orderList[this.dataindex].retail_price) - Number(this.orderList[this.dataindex].real_price)
                dis_real = dis_real.toFixed(2)
                b[2].name = '单品优惠'
                b[2].information = dis_real
                b[3].name = '仓库名称'
                b[3].information = this.detai.ware_name
                b[4].name = '剩余库存'
                b[4].information = this.detai.stock
                b[5].name = '单品备注'
                b[5].information = this.orderList[this.dataindex].remark
                this.tableData = b
                // console.log('this.orderList',this.detai)
                // console.log(this.tableData)
            },200)
        },
    }
}
</script>

<style scoped lang="scss">
::v-deep {
    .el-dialog__body {
        display: flex;
    }
    .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
        background: none;
    }
    .dialog {
        background: #F1F1F1;
        height: 9.3rem;
    }
    .el-dialog__footer {
        text-align: right;
    }
    .el-dialog__header {
        //border-bottom: 0.01rem solid #E9E9E9;
    }
    .el-dialog__body {
        padding:0.1rem 0.2rem 0.2rem 0.2rem;
    }
    .el-button {
        width: 1rem;
    }
    .el-table {
        height: 7.68rem;
        background-color: #FFF;
        overflow-y: auto;
    }
}
.background {
    //border: 1px solid #4B83F0;
    background: #DCEDFF;
    border-radius: 0.2rem;
}
.backgroundnone {
    background: #FFFFFF;
}
.bottom_bottom {
    padding: 0.05rem 0.45rem;
    background-color: #FFF;
}
.bottom_bottom .el-pagination {
    display: flex;
    justify-content: flex-end;
}
</style>
