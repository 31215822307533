<template>
  <div class="top">
    <div class="top_left">
        <div class="top_left_logo"><img src="../assets/images/logo_con.png"></div>
      <div  class="login-content-tab">
          <div>
              门店：{{$store.state.user.userInfo.other_info.store_name}}
          </div>
          <div>
              收银机：{{$store.state.user.userInfo.other_info.desk_name}}
          </div>
          <div>
              导购：{{$store.state.user.userInfo.user_info.username}}
          </div>
          <div @click="cangkuclick" style="cursor: pointer;">
              仓库：{{ warehouseDetail.name }}
          </div>
      </div>

    </div>
      <div class="flex">
<!--          <div @click="small" style="margin-right: 30px;">-->
<!--              <img src="../assets/images/small.png">-->
<!--          </div>-->
          <div @click="exit">
              <img src="../assets/images/guanbi.png">
          </div>
      </div>

      <el-dialog custom-class="dialog"
                 :destroy-on-close="true"
                 :close-on-click-modal = "false"
                 title=""
                 :visible.sync="warehouseVisible"
                 ref="dialog"
                 :before-close="Close"
                 width="30vw"
                 top="10vw"
      >
          <div slot="title" class="new-evaluationTitle">
              选择仓库
          </div>
          <el-table
              v-loading="loading"
              style="width: 100%"
              class=""
              stripe
              ref="multipleTable"
              :data="tableData"
              tooltip-effect="dark"
              highlight-current-row
              @current-change="handleCurrentChange"
          >
              <el-table-column v-for="item in tableTitle" :key="item.name" :prop="item.value" :label="item.name"
                               :width="item.width" :align="item.align || 'left'  " :formatter="item.formatter">
              </el-table-column>
          </el-table>
      </el-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
  name: "topBar",
  data() {
    return {
        // keyword:'',
        name:'',
        warehouseVisible:false,
        tableData:[],
        tableTitle: [
            {
                name: '库房名称',
                value: 'name',
                width:'300'
            },
            {
                name: '库房编号',
                value: 'ware_code',
                width:'300'
            }
        ],
        warehouseDetail:{},
        loading:false
    };
  },
  computed: {
		route(){
			return this.$route
		},
      // ...mapState(['GwcNum'])
	},

  watch: {

  },
    mounted() {
        this.getbase()
    },

    methods: {
        small() {
            // window.window.minimize()
        },
        exit() {
            this.$confirm('确定退出系统吗', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                window.window.close()
            }).catch(() => {
            });
        },
        /**
         * 关闭
         * */
        Close() {
            this.warehouseVisible = false
        },
        /**
         * 仓库接口
         * */
        async getbase() {
            //获取卡类型
            await this.$axios.get('api/pos_client/base/get_ware_list').then(res => {
                res.data.data.map((item,index) => {
                    if(item.is_default == 1) {
                        this.warehouseDetail = item
                        this.$store.commit('setwarehouseDetail', this.warehouseDetail)
                    }
                })
            })
        },
        /**
         * 仓库点击
         * */
        async cangkuclick() {
            this.warehouseVisible = true
            //获取卡类型
            await this.$axios.get('api/pos_client/base/get_ware_list').then(res => {
                this.tableData =  res.data.data
            })
        },
        /**
         * 选中行
         * */
        handleCurrentChange(e) {
            this.warehouseDetail = e
            this.$store.commit('setwarehouseDetail', this.warehouseDetail)
            this.warehouseVisible = false
        },
    },

}
</script>

<style scoped lang="scss">
::v-deep {
    .dialog {
        background: #F1F1F1;
        max-height: 750px;
    }
    .el-dialog__header {
        border-bottom: 0.01rem solid #E9E9E9;
    }
    .el-dialog__body {
        padding:0.1rem 0.2rem 0.2rem 0.2rem;
    }
    .el-button {
        width: 1rem;
    }
    .el-table {
        max-height: 528px;
        background-color: #FFF;
        overflow-y: auto;
    }
}
.login-content-tab {
    display: flex;
    color: #FFFFFF;
    font-weight: 600;
    margin-left: 80px;
    >div {
        margin-right: 30px;
    }
}
//.login-content-tab {
//    margin-left: 60px;
//    width: 120px;
//    display: flex;
//    justify-content: space-around;
//    font-size: 0.16rem;
//    color: #DBE6FC;
//}
//.login-content-tab_list {
//}
//.login_content_hover {
//    color: #ffffff;
//    border-bottom: 4px solid #CF7AE2;
//}
//.login_content_hover_none {
//    border-bottom: none!important;
//}
//.top_left div a {
//    color: #DBE6FC;
//}
//.top-button-image {
//    width: 42px;
//    height: 42px;
//    border-radius: 50%;
//}
::v-deep .el-button--primary {
    background-color: #386AFC;
    border-color: #386AFC;
}
::v-deep .el-button--text {
    color: #386AFC;
}
::v-deep {
    .el-switch__core:after {
        background-color: #386AFC;
    }
    .el-input__inner:hover {
        border-color: #386AFC!important;
    }
    .el-input__inner {
        background: #FFFFFF;
        border-radius: 4px;
        border: none!important;
        font-size: 0.16rem;
        color: #CBCBCB;
    }
    .el-form-item__content {
        border-radius: 4px;
        background: #FFFFFF;
        line-height: 42px;
        display: flex;
        align-items: center;
        img {
            width: 18px;
            height: 18px;
            margin-right: 0.1rem;
        }
    }
    .el-form-item {
        width: 250px;
        margin-bottom: 0;
    }
}
.top {
  position: relative;
  height: 7%;
  background: #2F302E;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px 0 80px;
}
.top_left .top_left_logo {
    cursor: pointer;
    img {
        width: 263px;
        height: 37px;
    }
}
.top_left, .top_right {
  display: flex;
  align-items: center;
}



.top_left div b {
  display: block;
  height: 42px;
  border-left: 1px solid #b5b5b5;
  margin: 0 30px;
}

.top_left div p {
  background: #7A40F2;
  height: 60px;
  line-height: 60px;
  width: 175px;
  text-align: center;
  color: #fff;
  font-size: 20px;
  border-radius: 4px;
  cursor: pointer;
}

.top_left div p .other {
  background: #DEF5DD;
  color: #7A40F2;
}

.top_right .top_right_quit {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #555;
}

.top_right div img {
  margin-left: 5px;
}


.login_name {
  display: inline-block;
  font-size: 18px;
}

.top-button {
  border: none;
  background-color: transparent;
    cursor: pointer;
}

</style>
