<template>
    <div @click.stop>
        <el-dialog custom-class="dialog"
                   :close-on-click-modal = "false"
                   :destroy-on-close="true"
                   :close-on-press-escape="true"
                   title=""
                   :visible.sync="Visible"
                   ref="dialog"
                   @close="onDialogClose"
                   width="80vw"
                   top="3.5vw"
        >
            <div slot="title" class="new-evaluationTitle">
                <img src="../../assets/images/membershipCard.png">
                {{title_text}}
            </div>
            <div class="new-evaluation">
                <el-form ref="form" :rules="rules" :model="formData" label-width="80px">
                    <div class="formbox">
                        <el-form-item label="卡号" prop="card_code">
                            <el-input v-model="formData.card_code"  placeholder="请输入"></el-input>
                        </el-form-item>
                        <el-form-item label="卡类型"  prop="card_type">
                            <el-select v-model="formData.card_type" placeholder="请选择"@change="cardType($event)">
                                <el-option v-for="item in card_typeList" :key="item.id" :label="item.type_name"
                                           :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="有效期" prop="expiry_date">
                            <el-date-picker type="date" placeholder="请选择" v-model="formData.expiry_date"
                                            value-format="yyyy-MM-dd" :picker-options="pickerOptions"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="会员价" prop="member_price">
                            <el-input v-model="formData.member_price"  @input="allNumber()" placeholder="请输入" type="number"></el-input>
                        </el-form-item>
                        <el-form-item label="发卡机构" prop="desk_code">
                            <el-input v-model="formData.desk_code" placeholder="请输入" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="发卡日期" prop="send_time">
                            <el-date-picker type="date" placeholder="请选择" v-model="formData.send_time"
                                            value-format="yyyy-MM-dd"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="" prop="status">
                            <el-radio style="width: 3rem;" label="1" v-model="formData.status" @click.native.prevent="statusChange(formData.status)">启用</el-radio>
                        </el-form-item>
                        <el-form-item label="关联职员"  prop="staff_id">
                            <el-select v-model="formData.staff_id" placeholder="请选择"@change="staffType($event)">
                                <el-option v-for="item in staff_typeList" :key="item.id" :label="item.username"
                                           :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <div style="width: 3.8rem"></div>
                    </div>
                    <div  class="form_title">
                        持卡人信息
                    </div>
                    <div class="formbox" style="align-items: flex-start;">
                        <div class="flex flex-wrap flex-justify-content-between" style="width: 9.5rem;">
                            <el-form-item label="持卡人" prop="username">
                                <el-input v-model="formData.username" @blur="userBlur"  placeholder="请输入"></el-input>
                            </el-form-item>
                            <el-form-item label="首字母" prop="username_initial">
                                <el-input v-model="formData.username_initial"  placeholder="请输入"></el-input>
                            </el-form-item>
                            <el-form-item label="电话" prop="phone">
                                <el-input  placeholder="请输入" v-model="formData.phone" @input="
                                    formData.phone=formData.phone.replace(
                                        /[^\d]/g,
                                        ''
                                    )"></el-input>
                            </el-form-item>
                            <el-form-item label="性别" prop="base_sex">
                                <el-radio-group v-model="formData.base_sex" style="width: 3.3rem;">
                                    <el-radio :label="0" name="0">男</el-radio>
                                    <el-radio :label="1" name="1">女</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="证件号" prop="idcard">
                                <el-input  placeholder="请输入"
                                    v-model="formData.idcard"
                                    @input="
                                    formData.idcard=formData.idcard.replace(
                                        /[^\X0-9]/g,
                                        ''
                                    )"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="E-mail" prop="email">
                                <el-input  placeholder="请输入" v-model="formData.email"></el-input>
                            </el-form-item>
                            <el-form-item label="地址" prop="address">
                                <el-input  placeholder="请输入" v-model="formData.address"></el-input>
                            </el-form-item>
                            <el-form-item label="工作单位" prop="work_unit">
                                <el-input  placeholder="请输入" v-model="formData.work_unit"></el-input>
                            </el-form-item>
                            <el-form-item label="备注" prop="remark">
                                <el-input  placeholder="请输入" v-model="formData.remark"></el-input>
                            </el-form-item>
                        </div>
                        <div>
                            <el-form-item label="照片" prop="headimg">
                                <div class="flex">
                                    <div class="image_box">
                                        <img v-if="formData.headimg" :src="formData.headimg" style="width: 100%;height: 100%;">
                                        <div v-else>照片(无)</div>
                                    </div>
                                    <div style="margin-left: 0.1rem;">
                                        <el-upload
                                            class="avatar-uploader border"
                                            :show-file-list="false"
                                            :action="`${$axios.defaults.baseURL}/api/files/upload`"
                                            :headers={token}
                                            :on-success="handleAvatarSuccess"
                                            :before-upload="beforeAvatarUpload">
                                            <!--                                <img v-if="(form || '').logo" :src="(form || '').logo" class="avatar">-->
                                            <!--                                <div v-else>-->
                                            <!--                                    照片(无)-->
                                            <!--                                </div>-->
                                            <div class="img_button">
                                                <img src="../../assets/images/pic.png">
                                                提取
                                            </div>
                                        </el-upload>
                                        <div class="img_button" style="margin-top: 0.2rem;cursor: pointer;" @click="clearimage">
                                            <img src="../../assets/images/clear.png">
                                            清空
                                        </div>
                                    </div>
                                </div>
                            </el-form-item>
                        </div>

                    </div>
                </el-form>

            </div>
            <div slot="footer" class="dialog-footer padding-30 no-padding-left no-padding-right no-padding-top">
                <div class="button_box">
                    <el-button type="primary" style="background: #CF7AE2;border-color: #CF7AE2" @click="buttonSubmit(1)">保存并使用</el-button>
                    <el-button type="primary" @click="buttonSubmit(2)">保存</el-button>
                    <el-button type="warning" style="background: #FFFFFF;border-color: #E9E9E9;color: #666666;"  @click="onDialogClose">退出(Esc)</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import store from "../../store";
import { pinyin } from 'pinyin-pro'
export default {
    name: "membershipCard",
    props:{
        /**
         * 弹框点开关闭
         * */
        dialogFormVisible: {
            default: false,
            type: Boolean
        },
        /**
         * 头部文字
         * */
        title_text: {
            default: '会员卡发卡',
            type: String
        },
        /**
         *  会员卡选中值
         * */
        topUpForm: Object,
    },

    data() {
        return {
            Visible:false,
            // 设置不能选则当天之前的时间    写data()中
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                },
            },
            rules: {
                card_code: [
                    {required: true,message: '请输入卡号', trigger: 'blur'}
                ],
                card_type: [
                    {required: true,message: '请选择卡类型', trigger: 'blur'}
                ],
                expiry_date: [
                    {required: true,message: '请选择有效期', trigger: 'blur'}
                ],
                send_time: [
                    {required: true,message: '请选择发卡日期', trigger: 'blur'}
                ],
                username: [
                    {required: true,message: '请输入持卡人', trigger: 'blur'}
                ],
                username_initial: [
                    {required: true,message: '请输入首字母', trigger: 'blur'}
                ],
                base_sex: [
                    {required: true,message: '请选择性别', trigger: 'blur'}
                ],
                idcard: [
                    {required: true,message: '请输入证件号', trigger: 'blur'}
                ],
                phone: [
                    {required: true,message: '请输入手机号', trigger: 'blur'}
                ],

            },
            formData:{
                card_code:'',
                card_type:'',
                expiry_date:'2099-01-01',
                member_price:'',
                desk_code:'',
                send_time:new Date(),
                status:'1',
                staff_id:'',
                username:'',
                username_initial:'',
                phone:'',
                base_sex:0,
                idcard:'',
                email:'',
                address:'',
                work_unit:'',
                remark:'',
                headimg:''
            },
            card_typeList:[],
            token: '',
            staff_typeList:[]
        };
    },
    computed: {

    },
    watch:{
        dialogFormVisible(newVal,oldVal) {
            this.Visible = newVal
        }
    },
    async mounted() {
        this.formData.desk_code = this.$store.state.user.userInfo.other_info.store_name
        this.token = store.state.token
    },
    // created() {
    //     window.addEventListener('keydown', this.handkeyCode, true)//开启监听键盘按下事件
    // },
    updated() {

    },
    methods: {
        allNumber() {
            this.formData.member_price = this.utils.onlyNumOnePoint(this.formData.member_price);
        },
        /**
         * 获取首字母
         * */
        userBlur() {
            const pinYinCode = pinyin(this.formData.username, {
                pattern: 'first',
                toneType: 'none',
                type: 'array',
            }).join('').toUpperCase()
            this.formData.username_initial = pinYinCode.charAt(0)
        },
        /**
         * 编辑会员卡详情
         * */
        async membershipDetail() {
            await this.$axios.get('api/pos_client/user/get_card_info',{
                params:{
                    card_code:this.topUpForm.card_code
                }}).then(res => {
                // console.log('res',res)
                    if(res.data.code == 200) {
                        let data = res.data.data
                        // console.log('res.data',res.data.data)
                        this.formData = {
                            card_code:data.usercard.card_code,
                            card_type:data.usercard.card_type,
                            expiry_date:data.usercard.expiry_date,
                            member_price:data.usercard.member_price,
                            desk_code:data.usercard.desk_code,
                            send_time:data.usercard.send_time,
                            status:String(data.usercard.status),
                            staff_id:data.usercard.staff_id,
                            username:data.username,
                            username_initial:data.username_initial,
                            phone:data.phone,
                            base_sex:data.base_sex=='女' ?1:0,
                            idcard:data.idcard,
                            email:data.email,
                            address:data.address,
                            work_unit:data.work_unit,
                            remark:data.remark,
                            headimg:data.headimg,
                        }
                    }
                }
            )
        },
        // 回车和空格键提交右侧信息
        // handkeyCode(e) {
        //     if(e.keyCode === 13){
        //         this.buttonSubmit()
        //     }
        // },
        /**
         * 图片获取成功
         * */
        handleAvatarSuccess(res) {
            // console.log(res)
            this.formData.headimg = res.data.url;
        },
        /**
         * 图片获取成功
         * */
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        /**
         * 清空图片
         * */
        clearimage() {
            this.formData.headimg = ''
        },
        /**
         * 启用单选框点击
         * */
        statusChange(val) {
            // console.log('val',val)
            this.formData.status = val == '1' ? '0' : '1' ;
        },
        /**
         *  卡类型选择
         * */
        cardType(e){
            this.formData.card_type = e
        },
        /**
         *  员工类型选择
         * */
        staffType(e) {
            this.formData.staff_id = e
        },
        /**
         *  获取接口
         * */
        async getData() {
            //获取卡类型
            await this.$axios.get('api/pos_client/base/get_user_card_type').then(res => {
                this.card_typeList = res.data.data;
            })
            //获取员工
            await this.$axios.get('api/pos_client/base/get_staff_list').then(res => {
                this.staff_typeList = res.data.data;
            })
            if(this.title_text == '会员卡发卡') {
                //获取会员卡号
                await this.$axios.get('api/pos_client/base/get_user_card_no').then(res => {
                    this.formData.card_code = res.data.data.card_code;
                })
            }
        },
        /**
         * 关闭弹框
         * */
        onDialogClose() {
            this.$parent.DialogClose();
            this.$refs.form.resetFields();
            // this.dialogFormVisible = false
        },
        /**
         * 保存
         * */
        buttonSubmit(num) {
            if(this.title_text == '会员卡发卡') {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.formData.base_sex = this.formData.base_sex == 0?'男':'女'
                        let formData = {...this.formData};
                        //添加
                        // console.log(formData)
                        this.$axios.post('api/pos_client/user/send_card', this.$qs.stringify(formData)).then(res => {
                            if (res.data.code == 200) {
                                this.$parent.DialogClose();
                                if(num == 1) {
                                    // this.$parent.getuser();
                                    this.$emit('membershipCardClick', this.formData.card_code);
                                }
                                this.$refs.form.resetFields();
                            }
                        })
                    }
                });
            } else {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.formData.base_sex = this.formData.base_sex == 0?'男':'女'
                        this.formData.card_id = this.topUpForm.usercard.id
                        this.formData.user_id = this.topUpForm.id
                        let formData = {...this.formData};
                        //添加
                        // console.log(formData)
                        this.$axios.post('api/pos_client/user/edit_card', this.$qs.stringify(formData)).then(res => {
                            if (res.data.code == 200) {
                                this.$parent.DialogClose();
                                if(num == 1) {
                                    // this.$parent.getuser();
                                    this.$emit('membershipCardClick', this.formData.card_code);
                                }
                                this.$refs.form.resetFields();
                            }
                        })
                    }
                });
            }

        }
    },
}
</script>
<style>

</style>
<style scoped lang="scss">
::v-deep {
    .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before, .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label:before {
        content: "";
    }
    .el-dialog__header {
        padding: 0;
    }
    .el-form-item__label {
        text-align-last: justify;
    }
    .el-input {
        width: 3.33rem;
    }
    .el-form {

    }
    .el-input.is-disabled .el-input__inner {
        background-color: #FFFFFF;
        color: #606266;
    }
    .el-dialog__body {
        padding: 0;
    }
}
.dialog-footer {
    ::v-deep .el-button {

    }
}
.img_button {
    padding: 0.1rem 0.33rem;
    display: flex;
    align-items: center;
    border-radius: 0.05rem;
    border: 0.01rem solid #E9E9E9;
    img {
        margin-right: 0.07rem;
    }
}
.image_box {
    width: 2.08rem;
    height: 2.35rem;
    background: #F1F1F1;
    border-radius: 0.04rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.16rem;
    font-weight: 400;
    color: #CDCDCD;
}
.formbox {
    padding: 0.2rem 0.7rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.form_title {
    width: 100%;
    font-size: 0.16rem;
    color: #4B83F0;
    margin-top: 0.2rem;
    padding-top: 0.33rem;
    padding-bottom: 0.2rem;
    border-top: 0.01rem solid #E9E9E9;
    padding-left: 0.3rem;
}
.new-evaluationTitle {
    font-size: 0.16rem;
    font-weight: 600;
    color: #333333;
    border-bottom: 0.01rem solid #E9E9E9;
    padding: 0.15rem 0;
    img {
        margin-left: 0.2rem;
    }
}
</style>
