<template>
    <div @click.stop>
        <el-dialog
                   width="30vw"
                   top="5vw"
                   custom-class="dialog"
                   :destroy-on-close="true"
                   :close-on-click-modal = "false"
                   title=""
                   :visible.sync="warehouseVisible"
                   ref="dialog"
                   :before-close="warehouseClose"

        >
            <div slot="title" class="new-evaluationTitle">
                选择仓库
            </div>
            <el-table
                v-loading="loading"
                style="width: 100%"
                class=""
                stripe
                ref="multipleTabless"
                height= "7.68rem"
                :data="tableData"
                :row-style="rowStyle"
                @row-click="handledbsupplier"
                :row-class-name="tableRowClassName"
                @row-dblclick="handledbClick"
            >
                <el-table-column v-for="item in tableTitle" :key="item.name" :prop="item.value" :label="item.name"
                                 :width="item.width" :align="item.align || 'left'  " :formatter="item.formatter">
                </el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "warehouse",
    props:{
        /**
         * 弹框点开关闭
         * */
        warehouseVisible: {
            default: false,
            type: Boolean
        },
        /**
         * 头部文字
         * */
        title_text: {
            default: '请输入价格',
            type: String
        },
        /**
         * 键盘事件
         * */
        keynum: Number,
    },
    data() {
        return {
            tableData:[],
            tableTitle: [
                {
                    name: '库房名称',
                    value: 'name',
                    width:'300'
                },
                {
                    name: '库房编号',
                    value: 'ware_code',
                    width:'300'
                }
            ],
            warehouseDetail:{},
            loading: false,
            dataDetail:'',// 当前已选择行
            dataindex: 0, // 当前选择行的index
        }
    },
    methods: {
        /**
         * 双击选中
         * */
        handledbClick(row) {
            // console.log('handledbClick',row)
            this.dataDetail=row
            this.dataindex=row.index
            this.warehouseEnter()
        },
        /**
         * 确认
         * */
        warehouseEnter() {
            this.dataDetail = this.tableData[this.dataindex]
            this.$emit('warehouseClick', this.dataDetail);
            this.$parent.warehouseClose();
        },
        keyupVip() {
            let Detail = {}
            Detail = this.utils.keyupVipCilck(this.tableData,this.keynum,-1,-1,this.dataindex,this.dataDetail)
            this.dataindex = Detail.dataindex
            this.dataDetail = Detail.dataDetail
            if(this.keynum == 38) {
                const table = this.$refs.multipleTabless;
                // 拿到表格中承载数据的div元素
                const divData = table.bodyWrapper;
                if(this.dataindex == this.tableData.length-1) {
                    // // 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果(此配置为每1秒移动20像素)
                    // console.log(this.dataindex,this.tableData.length-1)
                    divData.scrollTop += (80*(Number(this.dataindex)-14));
                } else if (this.dataindex < 5 ) {
                    divData.scrollTop = 0;
                }
            } else if(this.keynum == 40) {
                const table = this.$refs.multipleTabless;
                // 拿到表格中承载数据的div元素
                const divData = table.bodyWrapper;
                if(this.dataindex > 14) {
                    // // 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果(此配置为每1秒移动20像素)
                    divData.scrollTop += 80;
                } else if (this.dataindex  ==  this.tableData.length-1 ) {
                    divData.scrollTop = 0;
                } else if (this.dataindex<5) {
                    divData.scrollTop = 0;
                }
            }
        },
        /**
         * 进页面重新赋值
         * */
        assignment() {
            this.queryParams.retail_price = ''
        },
        /**
         * 关闭
         * */
        warehouseClose() {
            this.$parent.warehouseClose();
        },
        tableRowClassName({ row, rowIndex }) {
            row.index = rowIndex;
        },

        //改变表格选中某一行    的颜色
        rowStyle(row) {
            if(row.rowIndex == this.dataindex){
                let rowBackground = {}
                rowBackground.background = '#DCEDFF'
                return rowBackground
            }
        },
        //双击选择某一行
        handledbsupplier(row) {
            this.dataDetail=row
            this.dataindex=row.index
        },
        // 刷新
        onRefresh() {
            this.loading = true
            this.InterfaceList()
        },
        //列表接口
        async InterfaceList() {
            await this.$axios.get('api/pos_client/user/get_ware_list',{
                params:{
                    store_code:this.$store.state.user.userInfo.other_info.store_code,
                    desk_code:this.$store.state.user.userInfo.other_info.desk_code,
                }}).then(res => {
                this.tableData =  res.data.data
                this.loading = false
                this.dataindex = 0
            })
        },

        /**
         * 选中行
         * */
        handleCurrentChange(e) {
            // this.warehouseDetail = e
            // this.$store.commit('setwarehouseDetail', this.warehouseDetail)
            // this.warehouseVisible = false
        },
    }
}
</script>

<style scoped lang="scss">
::v-deep {
.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background: none;
}
.dialog {
    background: #F1F1F1;
    height: 8.5rem;
}
.el-dialog__footer {
    text-align: center;
}
.el-dialog__header {
//border-bottom: 0.01rem solid #E9E9E9;
}
.el-dialog__body {
    padding:0.1rem 0.2rem 0.2rem 0.2rem;
}
.el-button {
    width: 1rem;
}
.el-table {
    height: 7.68rem;
    overflow-y: auto;
}
}
.bottom_bottom {
    padding: 0.05rem 0.45rem;
    background-color: #FFF;
}
.bottom_bottom .el-pagination {
    display: flex;
    justify-content: flex-end;
}
</style>
