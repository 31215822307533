<template>
    <div @click.stop>
        <el-dialog custom-class="dialog"
                   :destroy-on-close="true"
                   :close-on-click-modal = "false"
                   title=""
                   :visible.sync="chooseShopVisible"
                   ref="dialog"
                   :before-close="chooseShopClose"
                   width="42vw"
                   top="7vw"
        >
            <div slot="title" class="new-evaluationTitle">
                {{title_text}}
            </div>
            <div class="flex flex-justify-content-between " style="margin-bottom: 0.2rem">
                <el-input v-model="formShop.keyword"></el-input>
                <div class="button" @click="search">查询</div>
            </div>
            <div style="height: 5.7rem;" ref="takeCenter">
                <el-table
                    v-loading="loading"
                    style="width: 100%"
                    class=""
                    stripe
                    ref="shopTable"
                    :data="tableData"
                    height= "5.7rem"
                    :row-style="rowStyle"
                    @row-click="handledbsupplier"
                    :row-class-name="tableRowClassName"
                    @row-dblclick="handledbClick"
                >
                    <el-table-column v-for="item in tableTitle" :key="item.name" :prop="item.value" :label="item.name"
                                     :width="item.width" :align="item.align || 'left'  " :formatter="item.formatter">
                    </el-table-column>
                </el-table>
            </div>

            <div class="bottom_bottom">
                <el-pagination
                    background
                    @current-change="handleCurrentChangePage"
                    :current-page.sync="formShop.page"
                    :pager-count="9"
                    layout="prev, pager, next"
                    :hide-on-single-page="true"
                    :total="searchData.total">
                </el-pagination>
            </div>
            <div slot="footer" class="dialog-footer padding-30 no-padding-left no-padding-right no-padding-top">
               <div class="button_box">
                   <el-button type="primary" @click="chooseShopEnter">选中(Enter)</el-button>
                   <!--                <el-button type="warning" style="background: #FFFFFF;border-color: #E9E9E9;color: #666666;"  @click="chooseShopClose">取消(Esc)</el-button>-->
               </div>
            </div>
        </el-dialog>

    </div>
</template>

<script>
export default {
    name: "chooseShop",
    props:{
        /**
         * 弹框点开关闭
         * */
        chooseShopVisible: {
            default: false,
            type: Boolean
        },
        /**
         * 传参
         * */
        formShop: {
            default: () => {
                return {
                    // ware_code:'',
                    keyword: '',
                    page: 1,
                    length: 10,
                }
            },
            type: Object
        },
        /**
         * 键盘事件
         * */
        keynum: Number,
        /**
         * 判断从哪个页面点开的弹框
         * */
        chooseNum: Number,
        /**
         * 头部文字
         * */
        title_text: {
            default: '商品选择',
            type: String
        },
    },

    data() {
        return {
            searchData: [],
            tableTitle: [
                {
                    name: '货号',
                    value: 'code',
                    width:'150'
                },
                {
                    name: '商品名称',
                    value: 'name',
                    width:'200'
                },
                {
                    name: '商品条码',
                    value: 'goods_sku',
                    // width:'160'
                },
                // {
                //     name: '规格',
                //     value: 'goods_spec',
                //     // width:'180'
                // }
            ],
            tableData: [],
            loading: false,
            warehouseDetail:{},
            dataDetail:'',// 当前已选择行
            dataindex: 0, // 当前选择行的index
        }
    },
    mounted() {
    },
    created() {
        // this.$store.watch((state, getters) => {
        //     this.warehouseDetail = state.warehouseDetail
        // }, () => {
        //
        // })
    },
    methods: {
        /**
         * 双击选中
         * */
        handledbClick(row) {
            this.dataDetail=row
            this.dataindex=row.index
            this.chooseShopEnter()
        },
        /**
         * 筛选
         * */
        search() {
            this.formShop.page = 1
            this.dataindex = 0
            this.dataDetail = this.tableData[this.dataindex]
            this.InterfaceList()
        },
        /**
         * table 滚动条
         * */
        scrollFileLocation() {
            const tableHeight = this.$refs.takeCenter.offsetHeight
            const tableHeader = this.$refs.shopTable.$refs.bodyWrapper.children[0].children[1].children[0].clientHeight
            const tableBodyHeight = tableHeight - tableHeader;
            const itemHeight = this.$refs.shopTable.$refs.bodyWrapper.children[0].children[1].children[0].clientHeight
            this.$nextTick(() => {
                const showNum = tableBodyHeight / itemHeight;
                let scrolled = this.$refs.shopTable.bodyWrapper.scrollTop;
                const start = Math.round(scrolled / itemHeight);
                const end = Math.round(start + showNum) - 1;
                let offSet = 0;
                if (start - this.dataindex > 1) {
                    this.$refs.shopTable.bodyWrapper.scrollTop = this.dataindex * itemHeight;
                    return;
                }
                if (this.dataindex - end > 1) {
                    this.$refs.shopTable.bodyWrapper.scrollTop = (this.dataindex - showNum + 1) * itemHeight;
                    return;
                }
                if (this.dataindex < start) {
                    offSet = scrolled % itemHeight;
                    scrolled -= itemHeight + offSet;
                } else if (this.dataindex > end) {
                    offSet = (scrolled + itemHeight * showNum) % itemHeight;
                    scrolled += 50;
                } else {
                    return;
                }
                this.$refs.shopTable.bodyWrapper.scrollTop = scrolled;
            });
        },
        keyupVip() {
            let Detail = {}
            Detail = this.utils.keyupVipCilck(this.tableData,this.keynum,this.formShop.page,this.searchData.pages,this.dataindex,this.dataDetail)
            this.dataindex = Detail.dataindex
            this.dataDetail = Detail.dataDetail
            if (this.formShop.page !== Detail.page) {
                this.formShop.page = Detail.page
                this.InterfaceList()
            }
            if(this.keynum == 38) {
                this.scrollFileLocation()
            }  else if(this.keynum == 40) {
                this.scrollFileLocation()
            }
        },
        //改变表格选中某一行    的颜色
        rowStyle(row) {
            if(row.rowIndex == this.dataindex){
                let rowBackground = {}
                rowBackground.background = '#DCEDFF'
                return rowBackground
            }
        },
        tableRowClassName({ row, rowIndex }) {
            row.index = rowIndex;
        },
        //选择某一行
        handledbsupplier(row) {
            console.log('row',row)
            this.dataDetail=row
            this.dataindex=row.index
        },
        /**
         *  分页
         * */
        handleCurrentChangePage(e) {
            this.InterfaceList()
        },
        /**
         * 确认
         * */
        chooseShopEnter() {
            this.dataDetail = this.tableData[this.dataindex]
            this.$emit('chooseShopClick', this.dataDetail);
            this.$parent.chooseShopClose();
        },
        /**
         * 弹框关闭
         * */
        chooseShopClose() {
            this.$parent.chooseShopClose();
        },
        // 刷新
        onRefresh() {
            this.loading = true
            this.formShop.page = 1
            this.InterfaceList()
        },
        //列表接口
        async InterfaceList() {
            // this.formShop.ware_code = this.warehouseDetail.ware_code
            console.log('this.chooseNum',this.chooseNum)
            if(this.chooseNum == 0) {
                await this.$axios.get('api/pos_client/base/get_search_goods_list',{
                    params:{
                        ...this.formShop
                    }}).then(res => {
                        this.tableData = res.data.data.list
                        this.searchData = res.data.data.page
                        this.loading = false
                    }
                )
            } else {
                this.formShop.ware_code = this.$store.state.count_ware
                await this.$axios.get('api/pos_client/base/get_batch_goods_list',{
                    params:{
                        ...this.formShop
                    }}).then(res => {
                        this.tableData = res.data.data.list
                        this.searchData = res.data.data.page
                        this.loading = false
                    }
                )
            }

        },
    }
}
</script>

<style scoped lang="scss">
::v-deep {
    .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
        background: none;
    }
    .dialog {
        background: #F1F1F1;
        height: 8.6rem;
    }
    .el-dialog__footer {
        text-align: left;
    }
    .el-dialog__header {
        border-bottom: 0.01rem solid #E9E9E9;
    }
    .el-dialog__body {
        padding:0.1rem 0.2rem 0.2rem 0.2rem;
    }
    .el-button {
        //width: 1rem;
    }
    .el-table {
        height: 5.7rem;
        background-color: #FFF;
        overflow-y: auto;
    }
    .el-button {
        //width: 1rem;
    }
    .el-input {
        //width: 6.1rem;
        width: 100%;
        height: 0.55rem!important;
        margin-right: 0.2rem;

    }
    .el-input__inner {
        height: 0.55rem!important;
        border: none!important;
    }
}
.button {
    width: 1rem;
    height: 0.55rem;
    background: #4B83F0;
    box-shadow: 0px 0px 0.2rem 0px rgba(146,146,146,0.5);
    border-radius: 0.05rem;
    font-size: 0.16rem;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 0.55rem;
    text-shadow: 0px 0px 0.2rem rgba(146,146,146,0.5);
    text-align:  center;
    cursor: pointer;
}
.bottom_bottom {
    padding: 0.05rem 0.45rem;
    background-color: #FFF;
}
.bottom_bottom .el-pagination {
    display: flex;
    justify-content: flex-end;
}
</style>
