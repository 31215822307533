<template>
    <div @click.stop>
        <el-dialog custom-class="dialog"
                   :destroy-on-close="true"
                   :close-on-click-modal = "false"
                   title=""
                   :visible.sync="numberVisible"
                   ref="dialog"
                   :before-close="numberClose"
                   width="30vw"
                   top="12vw"
        >
            <div slot="title" class="new-evaluationTitle">
                {{title_text}}
            </div>
            <div class="new-evaluation">
<!--                <el-form-item label="数量">-->
<!--                    <el-input v-model="stock_sales"  @keyup.enter.native="numberEnter"></el-input>-->
<!--                </el-form-item>-->
                <el-form :model="queryParams" @submit.native.prevent>
                    <el-form-item label="数量">
                        <el-input v-model="queryParams.stock_sales"  @input="allNumber()" ref="inputNum" @keyup.enter.native="numberEnter"></el-input>
                    </el-form-item>
                    <!--                    <el-button type="primary" class="cardbutton" @click="cardclick()">读IC卡</el-button>-->
                </el-form>

            </div>
            <div slot="footer" class="dialog-footer padding-30 no-padding-left no-padding-right no-padding-top">
                <div class="button_box">
                    <el-button type="primary" @click="numberEnter">确认(Enter)</el-button>
                    <el-button type="warning" style="background: #FFFFFF;border-color: #E9E9E9;color: #666666;"  @click="numberClose">取消(Esc)</el-button>
                </div>

            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "number",
    props:{
        /**
         * 弹框点开关闭
         * */
        numberVisible: {
            default: false,
            type: Boolean
        },
        /**
         * 头部文字
         * */
        title_text: {
            default: '请输入数量',
            type: String
        },

    },
    data() {
        return {
            queryParams :{
                stock_sales:''
            }

        }
    },
    methods: {
        allNumber() {
            this.queryParams.stock_sales = this.utils.onlyNum(this.queryParams.stock_sales);
        },
        /**
         * 进页面重新赋值
         * */
        assignment() {
            this.queryParams.stock_sales = ''
        },
        /**
         * 输入框获取焦点
         * */
        inputFocus() {
            //获取输入框焦点
            this.$nextTick(() => {
                this.$refs.inputNum.focus();
            })
        },
        /**
         * 输入框失去焦点
         * */
        inputBlurs() {
            //获取输入框焦点
            this.$nextTick(() => {
                this.$refs.inputNum.blur();
            })
        },
        /**
         * 弹框关闭
         * */
        numberClose() {
            this.$parent.NumberClose();
        },
        /**
         * 输入款回车事件 确认事件
         * */
        numberEnter() {
            // console.log('111111')
            this.$emit('numberClick', this.queryParams.stock_sales);
            this.inputBlurs()
        },
    }
}
</script>

<style scoped lang="scss">
.new-evaluationTitle {
    font-size: 0.2rem;
}
::v-deep {
    .el-dialog__footer {
        text-align: center;
    }
    .el-dialog__header {
        border-bottom: 0.01rem solid #E9E9E9;
    }
    .el-input {
        width:4.16rem;
    }
    .el-form-item {
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }
    .el-form {
        padding: 0 0.3125rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

}
.cardbutton {
    background: linear-gradient(180deg, #2F302E 0%, #606060 100%);
    border-radius: 0.05rem;
    border: none;
}
</style>
