import Vue from 'vue'
import Vuex from 'vuex'
import user from "./modules/user";
import ws from "./modules/ws";
import createPersiste from 'vue-savedata'
import axios from "axios";
import VueViewer from 'v-viewer'

const persiste = createPersiste({
    // 加密存本地, 默认为false
    ciphertext: true,
    mode: 'SS',
    LS: {
        module: user,
        storePath: 'user'
    },
    SS: [
        {
            module: ws,
            storePath: 'ws'
        }
    ],

})
Vue.use(Vuex)
Vue.use(VueViewer)

export default new Vuex.Store({
    state: {
        cmenu: '',
        turnOnOff:'',
        warehouseDetail:{},
        count_ware:''
    },
    mutations: {
        setWare(state, count_ware) {
            state.count_ware = count_ware
        },
        setTurnOnOff(state, turnOnOff) {
            state.turnOnOff = turnOnOff;
        },
        setCmenu(state, menu) {
            state.cmenu = menu
        },
        setwarehouseDetail(state, warehouseDetail) {
            state.warehouseDetail = warehouseDetail
        },
    },
    actions: {

    },
    modules: {user, ws},
    plugins: [persiste],
})
