<template>
    <div @click.stop>
        <el-dialog custom-class="dialog"
                   :destroy-on-close="true"
                   :close-on-click-modal = "false"
                   title=""
                   :visible.sync="reqlpriceVisible"
                   ref="dialog"
                   :before-close="reqlpriceClose"
                   width="30vw"
                   top="12vw"
        >
            <div slot="title" class="new-evaluationTitle">
                {{title_text}}
            </div>
            <div class="new-evaluation">
                <el-form  :model="queryParams" @submit.native.prevent>
                    <el-form-item label="价格">
                        <el-input v-model="queryParams.real_price" @input="allNumber()"  ref="inputReal" @keyup.enter.native="reqlpriceEnter"></el-input>
                    </el-form-item>
                    <!--                    <el-button type="primary" class="cardbutton" @click="cardclick()">读IC卡</el-button>-->
                </el-form>

            </div>
            <div slot="footer" class="dialog-footer padding-30 no-padding-left no-padding-right no-padding-top">
                <div class="button_box">
                    <el-button type="primary" @click="reqlpriceEnter">确认(Enter)</el-button>
                    <el-button type="warning" style="background: #FFFFFF;border-color: #E9E9E9;color: #666666;"  @click="reqlpriceClose">取消(Esc)</el-button>
                </div>

            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "reqlprice",
    props:{
        /**
         * 弹框点开关闭
         * */
        reqlpriceVisible: {
            default: false,
            type: Boolean
        },
        /**
         * 头部文字
         * */
        title_text: {
            default: '请输入金额',
            type: String
        },

    },
    data() {
        return {
            queryParams:{
                real_price:''
            }

        }
    },
    methods: {
        allNumber() {
            this.queryParams.real_price = this.utils.onlyNumOnePoint(this.queryParams.real_price);
        },
        /**
         * 进页面重新赋值
         * */
        assignment() {
            this.queryParams.real_price = ''
        },
        /**
         * 输入框获取焦点
         * */
        inputFocus() {
            //获取输入框焦点
            this.$nextTick(() => {
                this.$refs.inputReal.focus();
            })
        },
        /**
         * 输入框失去焦点
         * */
        inputBlurs() {
            //获取输入框焦点
            this.$nextTick(() => {
                this.$refs.inputReal.blur();
            })
        },
        /**
         * 弹框关闭
         * */
        reqlpriceClose() {
            this.$parent.reqlpriceClose();
        },
        /**
         * 输入款回车事件 确认事件
         * */
        reqlpriceEnter() {
            this.$emit('reqlpriceClick', this.queryParams.real_price);
            this.inputBlurs()
        },
    }
}
</script>

<style scoped lang="scss">
.new-evaluationTitle {
    font-size: 0.2rem;
}
::v-deep {
    .el-dialog__footer {
        text-align: center;
    }
    .el-dialog__header {
        border-bottom: 0.01rem solid #E9E9E9;
    }
    .el-input {
        width:4.16rem;
    }
    .el-form-item {
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }
    .el-form {
        padding: 0 0.3125rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

}
.cardbutton {
    background: linear-gradient(180deg, #2F302E 0%, #606060 100%);
    border-radius: 0.05rem;
    border: none;
}
</style>
