import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import {Message} from "element-ui";
import qs from 'qs'
import MyLoading from './plugs/loading'
import Print from 'vue-print-nb'
import * as echarts from 'echarts'
import ElementUI from 'element-ui';
import './assets/css/element-variables.scss';
import { VueMasonryPlugin } from 'vue-masonry';
import utils from './utils/utils'


// 全局修改默认配置，点击空白处不能关闭弹窗
ElementUI.Dialog.props.closeOnClickModal.default = false;
// 全局修改默认配置，按下ESC不能关闭弹窗
ElementUI.Dialog.props.closeOnPressEscape.default = false;

// 请求处理
// axios.defaults.baseURL = 'http://erp.d33221.com:6300/'
axios.defaults.baseURL = 'https://1974.mzth.cn/'
axios.interceptors.request.use(config => {
    let token = ((store.state || {}).user || {}).token;
    if (token) {
        config.headers.token = token;
    }
    return config;
}, error => {
    console.log('error',error)
    return error;
});
axios.interceptors.response.use(
    response => {
        switch (parseInt(response.data.code)) {
            case 200:
                // 成功
                if (response.data.msg && response.data.msg !== 'success') {
                    // Message.success(response.data.msg)
                }
                return response;
            case 305:
                Message.error('请登录后操作');
                // 需要登录
                store.commit('setToken', '');
                store.commit('setUserInfo', {data: '', status: false});
                router.push('/Login')
                return response;
            default:
                // 失败
                Message.error(response.data.info);
                return response
        }
    },
    error => {
        Message.error('网络异常，请稍后再试')
        return error;
    }
);

// 路由处理
router.beforeEach((to, from, next) => {
    // 路由发生变化修改页面title
    if (to.meta.title) {
        document.title = to.meta.title
    }
    if (to.name == 'Login') {
        next();
    } else {
        if (store.state.user.loginStatus){
            if(!to.name) {
                next('/Login')
            } else {
                next()
            }
        }else {
            next('/Login')
        }

    }
});

Vue.use(MyLoading)
Vue.use(Print);
Vue.prototype.$qs = qs;
Vue.prototype.$echarts = echarts
Vue.prototype.$axios = axios;
Vue.prototype.utils = utils;
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VueMasonryPlugin);
Vue.prototype.$bus = new Vue()

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
window.addEventListener('popstate',function () {
    history.pushState(null,null,document.URL)
})
