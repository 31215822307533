<template>
    <div @click.stop>
        <el-dialog custom-class="dialog"
                   :destroy-on-close="true"
                   :close-on-click-modal = "false"
                   title=""
                   :visible.sync="shopVisible"
                   ref="dialog"
                   :before-close="shopClose"
                   width="64vw"
                   top="7vw"
        >
            <div style="height: 6rem;" ref="takeCenter">
                <el-table
                    v-loading="loading"
                    style="width: 100%"
                    class=""
                    stripe
                    ref="shopTable"
                    :data="tableData"
                    height= "6rem"
                    :row-style="rowStyle"
                    @row-click="handledbsupplier"
                    :row-class-name="tableRowClassName"
                    @row-dblclick="handledbClick"
                >
                    <el-table-column v-for="item in tableTitle" :key="item.name" :prop="item.value" :label="item.name"
                                     :width="item.width" :align="item.align || 'left'  " :formatter="item.formatter">
                    </el-table-column>
                </el-table>
            </div>

            <div class="bottom_bottom">
                <el-pagination
                    background
                    @current-change="handleCurrentChangePage"
                    :current-page.sync="formShop.page"
                    :pager-count="9"
                    layout="prev, pager, next"
                    :hide-on-single-page="true"
                    :total="searchData.total">
                </el-pagination>
            </div>
            <div slot="footer" class="dialog-footer padding-30 no-padding-left no-padding-right no-padding-top">
                <div class="button_box">
                    <el-button type="primary" @click="shopEnter">确认(Enter)</el-button>
                    <el-button type="warning" style="background: #FFFFFF;border-color: #E9E9E9;color: #666666;"  @click="shopClose">取消(Esc)</el-button>
                </div>
            </div>
        </el-dialog>

    </div>
</template>

<script>
export default {
    name: "shopList",
    props:{
        /**
         * 弹框点开关闭
         * */
        shopVisible: {
            default: false,
            type: Boolean
        },
        /**
         * 传参
         * */
        formShop: {
            default: () => {
                return {
                    ware_code:'',
                    keyword: '',
                    page: 1,
                    length: 10,
                }
            },
            type: Object
        },
        /**
         * 键盘事件
         * */
        keynum: Number,
    },

    data() {
        return {
            searchData: [],
            tableTitle: [
                {
                    name: '货号',
                    value: 'code',
                    // width:'150'
                },
                {
                    name: '商品名称',
                    value: 'name',
                    // width:'170'
                },
                {
                    name: '商品条码',
                    value: 'goods_sku',
                    width:'200'
                },
                // {
                //     name: '规格',
                //     value: 'goods_spec',
                //     // width:'280'
                // },
                // {
                //     name: '型号',
                //     value: 'goods_model',
                //     // width:'100'
                // },
                {
                    name: '单位',
                    value: 'price_item.name',
                    width:'80'
                },
                {
                    name: '零售价',
                    value: 'price_item.retail_price',
                    width:'120'
                },
                {
                    name: '基本单位库存',
                    value: 'stock',
                    // width:'150'
                },
            ],
            tableData: [],
            loading: false,
            warehouseDetail:{},
            dataDetail:'',// 当前已选择行
            dataindex: 0, // 当前选择行的index
        }
    },
    mounted() {
    },
    created() {
        this.$store.watch((state, getters) => {
            this.warehouseDetail = state.warehouseDetail
        }, () => {

        })
    },
    methods: {
        /**
         * 进页面重新赋值
         * */
        assignment() {
            this.dataindex = 0
            this.dataDetail = {}
            this.tableData = []
            this.searchData = []
        },
        /**
         * 双击选中
         * */
        handledbClick(row) {
            this.dataDetail=row
            this.dataindex=row.index
            this.shopEnter()
        },
        /**
         * table 滚动条
         * */
        scrollFileLocation() {
            const tableHeight = this.$refs.takeCenter.offsetHeight
            const tableHeader = this.$refs.shopTable.$refs.bodyWrapper.children[0].children[1].children[0].clientHeight
            const tableBodyHeight = tableHeight - tableHeader;
            const itemHeight = this.$refs.shopTable.$refs.bodyWrapper.children[0].children[1].children[0].clientHeight
            this.$nextTick(() => {
                const showNum = tableBodyHeight / itemHeight;
                let scrolled = this.$refs.shopTable.bodyWrapper.scrollTop;
                const start = Math.round(scrolled / itemHeight);
                const end = Math.round(start + showNum) - 1;
                let offSet = 0;
                if (start - this.dataindex > 1) {
                    this.$refs.shopTable.bodyWrapper.scrollTop = this.dataindex * itemHeight;
                    return;
                }
                if (this.dataindex - end > 1) {
                    this.$refs.shopTable.bodyWrapper.scrollTop = (this.dataindex - showNum + 1) * itemHeight;
                    return;
                }
                if (this.dataindex < start) {
                    offSet = scrolled % itemHeight;
                    scrolled -= itemHeight + offSet;
                } else if (this.dataindex > end) {
                    offSet = (scrolled + itemHeight * showNum) % itemHeight;
                    scrolled += 50;
                } else {
                    return;
                }
                this.$refs.shopTable.bodyWrapper.scrollTop = scrolled;
            });
        },
        keyupVip() {
            let Detail = {}
            Detail = this.utils.keyupVipCilck(this.tableData,this.keynum,this.formShop.page,this.searchData.pages,this.dataindex,this.dataDetail)
            this.dataindex = Detail.dataindex
            this.dataDetail = Detail.dataDetail
            if (this.formShop.page !== Detail.page) {
                this.formShop.page = Detail.page
                this.InterfaceList()
            }
            if(this.keynum == 38) {
                this.scrollFileLocation()
            }  else if(this.keynum == 40) {
                this.scrollFileLocation()
            }
        },
        //改变表格选中某一行    的颜色
        rowStyle(row) {
            if(row.rowIndex == this.dataindex){
                let rowBackground = {}
                rowBackground.background = '#DCEDFF'
                return rowBackground
            }
        },
        tableRowClassName({ row, rowIndex }) {
            row.index = rowIndex;
        },
        //选择某一行
        handledbsupplier(row) {
            // console.log('row',row)
            this.dataDetail=row
            this.dataindex=row.index
        },
        /**
         *  分页
         * */
        handleCurrentChangePage(e) {
            this.InterfaceList()
        },
        /**
         * 确认
         * */
        shopEnter() {
            this.dataDetail = this.tableData[this.dataindex]
            this.$emit('shopClick', this.dataDetail);
            this.$parent.shopClose();
        },
        /**
         * 弹框关闭
         * */
        shopClose() {
            this.$parent.shopClose();
        },
        // 刷新
        onRefresh() {
            this.loading = true
            this.formShop.page = 1
            this.InterfaceList()
        },
        //列表接口
        async InterfaceList() {
            this.formShop.ware_code = this.warehouseDetail.ware_code
            await this.$axios.get('api/pos_client/base/get_goods_list',{
                params:{
                    ...this.formShop
                }}).then(res => {
                    this.tableData = res.data.data.list
                    this.searchData = res.data.data.page
                    this.loading = false
                }
            )
        },
    }
}
</script>

<style scoped lang="scss">
::v-deep {
    .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
        background: none;
    }
    .dialog {
        background: #F1F1F1;
        height: 8.5rem;
    }
    .el-dialog__footer {
        text-align: center;
    }
    .el-dialog__header {
        //border-bottom: 0.01rem solid #E9E9E9;
    }
    .el-dialog__body {
        padding:0.1rem 0.2rem 0.2rem 0.2rem;
    }
    .el-button {
        //width: 1rem;
    }
    .el-table {
        height: 6rem;
        background-color: #FFF;
        overflow-y: auto;
    }
}
.bottom_bottom {
    padding: 0.05rem 0.45rem;
    background-color: #FFF;
}
.bottom_bottom .el-pagination {
    margin: 0.3rem auto;
    display: flex;
    justify-content: flex-end;
}
</style>
