<template>
    <div @click.stop>
        <el-dialog custom-class="dialog"
                   :destroy-on-close="true"
                   :close-on-click-modal = "false"
                   title=""
                   :visible.sync="shiftVisible"
                   ref="dialog"
                   :before-close="shiftClose"
                   width="50vw"
                   top="6vw"
        >
            <div slot="title" class="new-evaluationTitle">
                {{title_text}}
            </div>
            <div class="new-evaluation" style="font-size: 0.14rem">
                <div class="tabbox">
                    <div v-for="(item,index) in tabList" class="tabitem"  @click="tabClick(index)" :class="tabIndex == index?'background':'backgroundnone'">
                        {{item.text}}
                    </div>
                </div>
                <div class="flex text_box" >
                    <div style="width: 4.2rem;">门店名称：{{ $store.state.user.userInfo.other_info.store_name }}</div>
                    <div style="width: 4.2rem;">默认仓库：{{ warehouseDetail.name }}</div>
                </div>
                <div class="time_box">
                    <el-form
                        ref="form"
                        :model="form"
                        :rules="rules"
                        label-width="80"
                        class="form"
                    >
                        <el-form-item label="起始时间" prop="startTime">
                            <el-date-picker
                                v-model="form.start_time"
                                type="datetime"
                                style="width: 75%"
                                placeholder="选择起始时间"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                @change="changeStartTime"
                                :disabled="tabIndex == 0?true:false"
                            >
                            </el-date-picker>
                        </el-form-item>

                        <el-form-item label="结束时间" prop="endTime">
                            <el-date-picker
                                v-model="form.end_time"
                                style="width: 75%"
                                type="datetime"
                                placeholder="选择结束时间"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                @change="changeEndTime"
                                :disabled="tabIndex == 0?true:false"
                            >
                            </el-date-picker>
                        </el-form-item>
                        <el-button type="primary" @click="shiftEnter">查询</el-button>
                    </el-form>
                </div>
                <div class="flex text_box" >
                    <div style="width: 2.1rem;">收银员：{{ $store.state.user.userInfo.user_info.username }}</div>
                    <div style="width: 2.1rem;">交班时间：{{ recharge_time }}</div>
                    <div style="width: 2.1rem;">收银台：{{ $store.state.user.userInfo.other_info.desk_name }}</div>
                </div>
                <div style="background: #F6F6F6;border-radius: 0.01rem;padding:0 0.1rem;display: flex;justify-content: space-between;align-items: center">
                    <el-radio-group v-model="type" style="width: 3.3rem;" @change="radioChange">
                        <el-radio :label="0" name="0">收银对账</el-radio>
                        <el-radio :label="1" name="1">商品对账</el-radio>
                    </el-radio-group>
                    <el-switch
                        v-model="value"
                        active-color="#4B83F0"
                        inactive-color="#CDCDCD"
                        active-text="对账后自动开钱箱"
                        v-if="tabIndex == 0"
                    >
                    </el-switch>
                    <el-button type="primary" @click="reconciliation" style="background: #CF7AE2;border: #CF7AE2" v-if="tabIndex == 0">对账</el-button>
                </div>
                <div style="background: #F6F6F6;border-radius: 0.01rem;padding:0.2rem 0.1rem;display: flex;justify-content: space-between;align-items: center;margin-top: 0.1rem;" v-if="type == 0">
                    <div style="width: 2.1rem;">小票首笔：{{ dataFrom?dataFrom.first_order:'' }}</div>
                    <div style="width: 2.1rem;">小票末笔：{{ dataFrom?dataFrom.last_order:'' }}</div>
                    <div style="width: 2.1rem;">小票笔数：{{ dataFrom?dataFrom.order_count:'' }}</div>
                </div>
                <div class="shouyin" v-if="type == 0">
                    <div style="width: 50%;margin-right: 0.1rem; border: 0.01rem solid #E9E9E9;height: 3rem;overflow-y: scroll;">
                        <div class="flex" style="background: #F6F6F6;border-radius: 0.01rem;padding:0.1rem 0.2rem;">
                            <div style="width: 33%">收款方式</div>
                            <div style="width: 33%">笔数</div>
                            <div style="width: 33%">金额</div>
                        </div>
                        <div class="flex" style="border-radius: 0.01rem;padding:0.1rem 0.2rem;">
                            <div style="width: 33%">{{ dataFrom.alipay?dataFrom.alipay.name:'' }}</div>
                            <div style="width: 33%">{{ dataFrom.alipay?dataFrom.alipay.count:'' }}</div>
                            <div style="width: 33%">{{ dataFrom.alipay?dataFrom.alipay.amount:'' }}</div>
                        </div>
                        <div class="flex" style="border-radius: 0.01rem;padding:0.1rem 0.2rem;">
                            <div style="width: 33%">{{ dataFrom.bank?dataFrom.bank.name:'' }}</div>
                            <div style="width: 33%">{{ dataFrom.bank?dataFrom.bank.count:'' }}</div>
                            <div style="width: 33%">{{ dataFrom.bank?dataFrom.bank.amount:'' }}</div>
                        </div>
                        <div class="flex" style="border-radius: 0.01rem;padding:0.1rem 0.2rem;">
                            <div style="width: 33%">{{ dataFrom.card?dataFrom.card.name:'' }}</div>
                            <div style="width: 33%">{{ dataFrom.card?dataFrom.card.count:'' }}</div>
                            <div style="width: 33%">{{ dataFrom.card?dataFrom.card.amount:'' }}</div>
                        </div>
                        <div class="flex" style="border-radius: 0.01rem;padding:0.1rem 0.2rem;">
                            <div style="width: 33%">{{ dataFrom.cash?dataFrom.cash.name:'' }}</div>
                            <div style="width: 33%">{{ dataFrom.cash?dataFrom.cash.count:'' }}</div>
                            <div style="width: 33%">{{ dataFrom.cash?dataFrom.cash.amount:'' }}</div>
                        </div>
                        <div class="flex" style="border-radius: 0.01rem;padding:0.1rem 0.2rem;">
                            <div style="width: 33%">{{ dataFrom.integral?dataFrom.integral.name:'' }}</div>
                            <div style="width: 33%">{{ dataFrom.integral?dataFrom.integral.count:'' }}</div>
                            <div style="width: 33%">{{ dataFrom.integral?dataFrom.integral.amount:'' }}</div>
                        </div>
                        <div class="flex" style="border-radius: 0.01rem;padding:0.1rem 0.2rem;">
                            <div style="width: 33%">{{ dataFrom.wechat?dataFrom.wechat.name:'' }}</div>
                            <div style="width: 33%">{{ dataFrom.wechat?dataFrom.wechat.count:'' }}</div>
                            <div style="width: 33%">{{ dataFrom.wechat?dataFrom.wechat.amount:'' }}</div>
                        </div>
                    </div>
<!--                    <div style="width: 50%;border: 0.01rem solid #E9E9E9;height: 350px;overflow-y: scroll;">-->
<!--                        <div class="flex" style="background: #F6F6F6;border-radius: 1px;padding:10px 20px;">-->
<!--                            <div style="width: 50%;">备注</div>-->
<!--                            <div style="width: 50%;"></div>-->
<!--                        </div>-->
<!--                        <div class="flex"  style="border-radius: 1px;padding:10px 20px;">-->
<!--                            <div style="width: 50%;">{{ dataFrom.recharge?dataFrom.recharge.name:'' }}</div>-->
<!--                            <div style="width: 50%;">{{ dataFrom.recharge?dataFrom.recharge.amount:'' }}</div>-->
<!--                        </div>-->
<!--                        <div class="flex"  style="border-radius: 1px;padding:10px 20px;">-->
<!--                            <div style="width: 50%;">{{ dataFrom.recharge_give?dataFrom.recharge.name:'' }}</div>-->
<!--                            <div style="width: 50%;">{{ dataFrom.recharge_give?dataFrom.recharge.amount:'' }}</div>-->
<!--                        </div>-->
<!--                        -->
<!--                    </div>-->
                </div>
                <div class="shop" v-if="type == 1" style="margin-top: 10px;">
                    <div style="width: 100%;margin-right: 0.1rem; border: 0.01rem solid #E9E9E9;height: 3.5rem;overflow-y: scroll;">
                        <div class="flex" style="background: #F6F6F6;border-radius: 0.01rem;padding:0.1rem 0.2rem;">
                            <div style="width: 20%">货号</div>
                            <div style="width: 20%">商品名称</div>
                            <div style="width: 20%">数量</div>
                            <div style="width: 20%">销售均价</div>
                            <div style="width: 20%">成交金额</div>
                        </div>
                        <div class="flex" style="border-radius: 0.01rem;padding:0.1rem 0.2rem;" v-for="(item,index) in dataList" :key="index">
                            <div style="width: 20%">{{ item.goods_code }}</div>
                            <div style="width: 20%">{{ item.goods.name }}</div>
                            <div style="width: 20%">{{ item.stock_sales }}</div>
                            <div style="width: 20%">{{ item.average_price }}</div>
                            <div style="width: 20%">{{ item.deal_amount }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <div class="button_box">
<!--                    <el-button type="primary" @click="shiftEnter">打印</el-button>-->
                    <el-button type="warning" style="background: #FFFFFF;border-color: #E9E9E9;color: #666666;"  @click="shiftClose">退出(Esc)</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "shiftOver",
    props:{
        /**
         * 弹框点开关闭
         * */
        shiftVisible: {
            default: false,
            type: Boolean
        },
        /**
         * 头部文字
         * */
        title_text: {
            default: 'POS交班',
            type: String
        },

    },
    data() {
        return {
            tabList:[
                {
                    id:1,
                    text:'收银对账'
                },
                {
                    id:2,
                    text:'对账查询'
                }
            ],
            tabIndex:0,
            warehouseDetail:{},
            endTime:'',
            startTime:'',
            recharge_time:this.utils.dateTypeFormat('YYYY-mm-dd', new Date()),
            form:{
                start_time:'',
                end_time:'',
            },
            rules: {
            },
            type:0,
            value:false,
            dataFrom:{},
            dataList:[],

        }
    },
    created() {
        this.$store.watch((state, getters) => {
            this.warehouseDetail = state.warehouseDetail
        }, () => {

        })
    },
    methods: {
        //选择日期后触发change方法，将开始和结束时间转为时间戳进行对比，不符合约束条件就清空该日期重新选择
        changeStartTime(val) {
            let date = new Date(val).getTime();
            this.startTime = date;
            if (this.endTime) {
                if (this.endTime < this.startTime) {
                    this.$message.error("开始时间不能大于结束时间");
                    this.form.start_time = "";
                    this.startTime = null;
                }
            }
        },
        changeEndTime(val) {
            let date = new Date(val).getTime();
            this.endTime = date;
            if (this.startTime) {
                if (this.endTime < this.startTime) {
                    this.$message.error("结束时间不能小开始时间");
                    this.form.end_time = "";
                    this.endTime = null;
                }
            }
        },
        tabClick(index) {
            this.tabIndex = index
            if(this.tabIndex == 0) {
                this.time()
            }
        },
        radioChange() {
            this.shiftEnter()
        },
        /**
         * 输入框获取焦点
         * */
        inputFocus() {
            //获取输入框焦点
            this.$nextTick(() => {
                this.$refs.inputPrice.focus();
            })
        },
        /**
         * 输入框失去焦点
         * */
        inputBlurs() {
            //获取输入框焦点
            this.$nextTick(() => {
                this.$refs.inputPrice.blur();
            })
        },
        /**
         * 弹框关闭
         * */
        shiftClose() {
            this.$parent.shiftClose();
        },
        async time() {
            this.tabIndex = 0
            await this.$axios.get('api/pos_client/user/get_last_time',{
                params:{
                    desk_code:this.$store.state.user.userInfo.other_info.desk_code,
                    store_code:this.$store.state.user.userInfo.other_info.store_code,
                }}).then(res => {
                    this.form.start_time = res.data.data
                    this.form.end_time = this.utils.formatDate()
                    this.shiftEnter()
                }
            )
        },
        /**
         * 对账
         * */
        async reconciliation() {
            if(this.form.start_time == '') {
                this.$message.error("开始时间不能为空");
                return;
            }
            if(this.form.end_time == '') {
                this.$message.error("结束时间不能为空");
                return;
            }
            await this.$axios.post('api/pos_client/user/pass_work',{
                desk_code:this.$store.state.user.userInfo.other_info.desk_code,
                store_code:this.$store.state.user.userInfo.other_info.store_code,
                ...this.form
            }).then(res => {
                if(res.data.code == 200) {
                    this.$message.success("交班成功");
                }
            })
        },
        /**
         * 查询
         * */
        async shiftEnter() {
           if(this.form.start_time == '') {
               this.$message.error("开始时间不能为空");
               return;
           }
            if(this.form.end_time == '') {
                this.$message.error("结束时间不能为空");
                return;
            }
            if(this.type == 0) {
                await this.$axios.get('api/pos_client/user/pos_account_check',{
                    params:{
                        desk_code:this.$store.state.user.userInfo.other_info.desk_code,
                        store_code:this.$store.state.user.userInfo.other_info.store_code,
                        ...this.form
                    }}).then(res => {
                        this.dataFrom = res.data.data
                    }
                )
            } else {
                await this.$axios.get('api/pos_client/user/pos_goods_check',{
                    params:{
                        desk_code:this.$store.state.user.userInfo.other_info.desk_code,
                        store_code:this.$store.state.user.userInfo.other_info.store_code,
                        ...this.form
                    }}).then(res => {
                        this.dataList = res.data.data
                    }
                )
            }

        },
    }
}
</script>

<style scoped lang="scss">
.shouyin {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.1rem;
    font-size: 0.16rem;
    font-weight: 400;
    color: #333333;
}
.time_box {
    margin-bottom: 0.2rem;
}
.text_box {
    font-size: 0.16rem;
    font-weight: 400;
    color: #333333;
    margin-bottom: 0.2rem;
}
.tabbox{
    display: flex;
    margin-bottom: 0.2rem;
    .tabitem {
        width: 1.22rem;
        height: 0.36rem;
        border: 0.01rem solid #4B83F0;
        font-size: 0.16rem;
        font-weight: 400;
        color: #333333;
        line-height: 0.36rem;
        text-align: center;
    }
}
.background {
    border: 0.01rem solid #4B83F0;
    background: #4B83F0;
    font-size: 0.16rem;
    font-weight: 600;
    color: #FFFFFF!important;
}
.backgroundnone {
    background: #FFFFFF;
}
::v-deep {
    .el-input.is-disabled .el-input__inner {
        color: #333333;
        background: #FFFFFF;
    }
    .el-dialog__footer {
        text-align: right;
    }
    .el-dialog__header {
        border-bottom: 0.01rem solid #E9E9E9;
    }
    .el-input {
        //width: 4rem;
    }
    .el-form-item {
        width: 4.2rem;
        //display: flex;
        //align-items: center;
        margin-bottom: 0;
    }
    .el-radio-group {
        width: 4.2rem!important;
        padding:0.2rem 0;
    }
    .el-switch {
        width: 4.2rem;
    }
    .el-form {
        //padding: 0 0.3rem;
        display: flex;
        align-items: center;
        //justify-content: space-between;
    }
    .el-form-item__label {
        text-align: left;
        font-size: 0.16rem;
        font-weight: 400;
        color: #999999;
    }

}
.cardbutton {
    background: linear-gradient(180deg, #2F302E 0%, #606060 100%);
    border-radius: 0.05rem;
    border: none;
}
</style>
