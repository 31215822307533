<template>
    <div ref="vipList" @click.stop>
        <el-dialog custom-class="dialog"
                   :destroy-on-close="true"
                   :close-on-click-modal = "false"
                   title=""
                   :visible.sync="vipCardVisible"
                   ref="dialog"
                   :before-close="vipCardClose"
                   width="40vw"
                   top="10vw"
        >
            <div slot="title" class="new-evaluationTitle">
                {{title_text}}
            </div>
            <div class="flex " style="margin-bottom: 0.2rem">
                <el-input v-model="form.keywords"></el-input>
                <div class="button" @click="search">查询</div>
            </div>
            <div class="table_box">
                <el-table
                    v-loading="loading"
                    style="width: 100%"
                    class=""
                    stripe
                    ref="multipleTabless"
                    :data="tableData"
                    :row-style="rowStyle"
                    @row-click="handledbsupplier"
                    :row-class-name="tableRowClassName"
                    @row-dblclick="handledbClick"
                >
                    <el-table-column v-for="item in tableTitle" :key="item.name" :prop="item.value" :label="item.name"
                                     :width="item.width" :align="item.align || 'left'  " :formatter="item.formatter">
                    </el-table-column>
                </el-table>
            </div>
            <div class="bottom_bottom">
                <el-pagination
                    background
                    @current-change="handleCurrentChangePage"
                    :current-page.sync="form.page"
                    :pager-count="9"
                    layout="prev, pager, next"
                    :hide-on-single-page="true"
                    :total="searchData.total">
                </el-pagination>
            </div>
            <div slot="footer" class="dialog-footer padding-30 no-padding-left no-padding-right no-padding-top">
                <div class="button_box">
                    <el-button type="primary" @click="vipCardEnter">选中</el-button>
                    <!--                <el-button type="warning" style="background: #FFFFFF;border-color: #E9E9E9;color: #666666;"  @click="vipCardClose">取消(Esc)</el-button>-->
                </div>
            </div>
        </el-dialog>

    </div>
</template>

<script>
export default {
    name: "vipCardList",
    props:{
        /**
         * 弹框点开关闭
         * */
        vipCardVisible: {
            default: false,
            type: Boolean
        },
        /**
         * 头部文字
         * */
        title_text: {
            default: '会员卡',
            type: String
        },

        /**
         * 键盘事件
         * */
        keynum: Number,

    },

    data() {
        return {
            searchData: [],
            tableTitle: [
                {
                    name: '行号',
                    value: 'index',
                    width:'80',
                    formatter: (row) => {
                        return  row.index+1;
                    }
                },
                {
                    name: '卡号',
                    value: 'card_code',
                    // width:'300'
                },
                {
                    name: '持卡人',
                    value: 'username'
                }
            ],
            tableData: [],
            loading: false,
            dataDetail:'',// 当前已选择行
            dataindex: 0, // 当前选择行的index
            /**
             * 传参
             * */
            form: {
                keywords: '',
                page: 1,
                length: 10,
            },

        }
    },
    mounted() {

    },
    watch:{
    },
    methods: {
        /**
         * 双击选中
         * */
        handledbClick(row) {
            this.dataDetail=row
            this.dataindex=row.index
            this.vipCardEnter()
        },
        tableRowClassName({ row, rowIndex }) {
            row.index = rowIndex;
        },
        /**
         * 筛选
         * */
        search() {
            this.form.page = 1
            this.dataindex = 0
            this.dataDetail = this.tableData[this.dataindex]
            this.InterfaceList()
        },
        keyupVip() {
            let Detail = {}
            Detail = this.utils.keyupVipCilck(this.tableData,this.keynum,this.form.page,this.searchData.pages,this.dataindex,this.dataDetail)
            this.dataindex = Detail.dataindex
            this.dataDetail = Detail.dataDetail
            if (this.form.page !== Detail.page) {
                this.form.page = Detail.page
                this.InterfaceList()
            }
        },
        //改变表格选中某一行    的颜色
        rowStyle(row) {
            if(row.rowIndex == this.dataindex){
                let rowBackground = {}
                rowBackground.background = '#DCEDFF'
                return rowBackground
            }
        },
        //双击选择某一行
        handledbsupplier(row) {
            this.dataDetail=row
            this.dataindex=row.index
        },
        /**
         *  分页
         * */
        handleCurrentChangePage(e) {
            this.InterfaceList()
        },
        /**
         * 确认
         * */
        vipCardEnter() {
            this.dataDetail = this.tableData[this.dataindex]
            this.$emit('vipCardClick', this.dataDetail);
            this.$parent.vipCardClose();
        },
        /**
         * 弹框关闭
         * */
        vipCardClose() {
            this.$parent.vipCardClose();
        },
        // 刷新
        onRefresh() {
            this.loading = true
            this.form.page = 1
            this.InterfaceList()
        },
        //列表接口
        async InterfaceList() {
            await this.$axios.get('api/pos_client/user/get_user_card_list',{
                params:{
                    ...this.form
                }}).then(res => {
                    this.tableData = res.data.data.list
                    this.searchData = res.data.data.page
                    this.loading = false
                }
            )
        },
    }
}
</script>

<style scoped lang="scss">
::v-deep {
    .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
        background: none;
    }
    .dialog {
        background: #F1F1F1;
        height: 8.2rem;
    }
    .el-dialog__footer {
        //text-align: center;
        text-align: left;
    }
    .el-dialog__header {
        //border-bottom: 0.01rem solid #E9E9E9;
    }
    .el-dialog__body {
        padding:0.1rem 0.2rem 0.2rem 0.2rem;
    }
    .el-button {
        width: 1rem;
    }
    .el-table {
        height: 5.38rem;
        overflow-y: auto;
    }
    .el-input {
        //width: 6.1rem;
        width: 100%;
        height: 0.55rem!important;
        margin-right: 0.2rem;

    }
    .el-input__inner {
        height: 0.55rem!important;
        border: none!important;
    }
}
.button {
    width: 1rem;
    height: 0.55rem;
    background: #4B83F0;
    box-shadow: 0px 0px 0.2rem 0px rgba(146,146,146,0.5);
    border-radius: 0.05rem;
    font-size: 0.16rem;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 0.55rem;
    text-shadow: 0px 0px 0.2rem rgba(146,146,146,0.5);
    text-align:  center;
    cursor: pointer;
}
.table_box {
    height: 5.28rem;
    background-color: #FFF;
    overflow-y: scroll;
}
.bottom_bottom {
    padding: 0.05rem 0.45rem;
    background-color: #FFF;
}
.bottom_bottom .el-pagination {
    display: flex;
    justify-content: flex-end;
}
</style>
