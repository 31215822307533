<template>
    <div class="layout">
        <div class="top"  @click="inputFocus">
            <div class="top_left">
                <img  class="top_left_logo" src="../assets/images/logo_con.png">
<!--                <div class="top_left_logo"></div>-->
                <div  class="login-content-tab">
                    <div>
                        门店：{{$store.state.user.userInfo.other_info.store_name}}
                    </div>
                    <div>
                        收银机：{{$store.state.user.userInfo.other_info.desk_name}}
                    </div>
                    <div>
                        导购：{{$store.state.user.userInfo.user_info.username}}
                    </div>
                    <div @click.stop="cangkuclick" style="cursor: pointer;">
                        仓库：{{ warehouseDetail.name }}
                    </div>
                </div>

            </div>
            <div class="flex top_right">
                <!--          <div @click="small" style="margin-right: 30px;">-->
                <!--              <img src="../assets/images/small.png">-->
                <!--          </div>-->
                <img  @click.stop="exit" class="img1" src="../assets/images/guanbi.png">
            </div>
        </div>
        <div id="index" @click="inputFocus" style="width: 100%">
            <div style="width: 80%;">
                <div class="content_top">
                    <div class="content_top_box color-dff">
                        <div class="content_top_box_value">{{ totalText.order_no }}</div>
                        <div class="content_top_box_title">单号</div>
                    </div>
                    <div class="content_top_box">
                        <div class="content_top_box_value"><span v-if="returnGoods == 1">-</span>{{ num_real?num_real:0 }}</div>
                        <div class="content_top_box_title">合计数量</div>
                    </div>
                    <div class="content_top_box">
                        <div class="content_top_box_value">¥{{ dis_real?dis_real:0 }}</div>
                        <div class="content_top_box_title">优惠金额</div>
                    </div>
                    <div class="content_top_box color-1ff">
                        <div class="content_top_box_value color-ae2">¥<span v-if="returnGoods == 1">-</span>{{ amount_real?amount_real:0 }}</div>
                        <div class="content_top_box_title">合计金额</div>
                    </div>
                </div>
                <div class="content_center" ref="content_center">
                    <el-table
                        v-loading="loading"
                        style="width: 100%"
                        class=""
                        stripe
                        height="7.86rem"
                        ref="IndexTable"
                        :data="tableDataIndex"
                        :row-style="rowStyle"
                        @row-click="handledbsupplier"
                        :row-class-name="tableRowClassName"
                    >
                        <el-table-column
                            center
                            width="60">
                            <template slot-scope="scope">
                                <div v-if="scope.row.is_give == 1"style="width: 0.25rem;height: 0.25rem;">
                                    <img src="../assets/images/zeng.png" style="width: 0.25rem;height: 0.25rem;">
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            center
                            label="行号"
                            width="70">
                            <template slot-scope="scope">
                                <div >
                                    {{scope.row.index+1}}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            center
                            label="货号"
                            width="140">
                            <template slot-scope="scope">
                                <div >
                                    {{scope.row.goods_code}}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            center
                            label="品名"
                            width="200"
                            >
                            <template slot-scope="scope">
                                <el-popover
                                    placement="top-start"
                                    title="品名"
                                    trigger="hover"
                                    :content="scope.row.goods_name">
                                    <div slot="reference">{{scope.row.goods_name}}</div>
<!--                                    <el-button slot="reference"></el-button>-->
                                </el-popover>

<!--                                <div >-->
<!--                                    {{scope.row.goods_name}}-->
<!--                                </div>-->
                            </template>
                        </el-table-column>

                        <el-table-column
                            center
                            label="单位"
                            width="80"
                        >
                            <template slot-scope="scope">
                                <div >
                                    {{scope.row.unit_name}}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            center
                            label="商品条码"
                            width="200"
                        >
                            <template slot-scope="scope">
                                <el-popover
                                    placement="top-start"
                                    title="商品条码"
                                    width="200"
                                    trigger="hover"
                                    :content="scope.row.goods_sku">
                                    <div slot="reference">{{scope.row.goods_sku}}</div>
                                    <!--                                    <el-button slot="reference"></el-button>-->
                                </el-popover>
<!--                                <div >-->
<!--                                    {{scope.row.goods_sku}}-->
<!--                                </div>-->
                            </template>
                        </el-table-column>
                        <el-table-column v-for="item in tableTitle" :key="item.name" :prop="item.value" :label="item.name"
                                         :width="item.width" :align="item.align || 'left'  " :formatter="item.formatter">
                        </el-table-column>
                    </el-table>
                </div>
                <div class="content_bottom">
                    <div style="display: flex;align-items: center;">
                        <div style="display: flex;align-items: center;" v-if="returnGoods == 0">
                            <img src="../assets/images/zhengchang.png" style="margin-right: 0.1rem;">
                            销售状态：正常销售
                        </div>
                        <div style="display: flex;align-items: center;"  v-if="returnGoods == 1">
                            <img src="../assets/images/tuihuo.png" style="margin-right: 0.1rem;">
                            销售状态：零售退货<span style="color: #FFC866;margin-left: 0.3rem;">提示消息：已切换到【零售退货】状态</span>
                        </div>
                        <div v-if="textshow && tableDataIndex.length<=0" style="color: #4B83F0;margin-left: 0.2rem">没有输入的商品信息</div>
                    </div>
                    <div>{{nowTime}}</div>
                </div>
                <div class="content_bottom_event">
                    <div v-for="(item,index) in eventList" :key="index" @click.stop="eventClick(item)" class="content_bottom_event_box">
                        <div>{{item.text}}</div>
                        <div>{{item.id}}</div>
                    </div>
                    <div @mouseenter="overOther" @mouseleave="outOther"  class="content_bottom_event_box">
                        <div>其他</div>
                    </div>
                    <div v-if="otherShow" class="content_bottom_other" @mouseenter="overOther" @mouseleave="outOther" >
                        <div v-for="(item,index) in otherList" :key="index" @click.stop="eventClick(item)" class="content_bottom_event_box">
                            <div>{{item.text}}</div>
                            <div>{{item.id}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="menu"  style="width: 19%;">
                <div class="one_box">
                    <div class="top_text">上一单 {{last_orderform.order_no}}</div>
                    <div class="flex">
                        <div class="text"><div class="mr-10">数量</div><div>{{last_orderform.number_goods}}</div></div>
                        <div class="text"><div class="mr-10">金额</div><div>{{last_orderform.amount_real}}</div></div>
                    </div>
                    <div class="flex">
                        <div class="text"><div class="mr-10">优惠</div><div>{{last_orderform.coupon_amount}}</div></div>
                        <div class="text"><div class="mr-10">找零</div><div>{{last_orderform.zhaoling_amount}}</div></div>
                    </div>
                </div>
                <div class="search_box">
                    <img src="../assets/images/search.png">
                    <el-input v-model="formShop.keyword" @input="inputChange" placeholder="请输入关键词"  ref="inputName" @blur="inputblur" @keyup.enter.native="shopEnter" clearable></el-input>
                </div>
                <div class="member_box">
                    <div class="member_box_ziliao">
                        <img v-if="!userform.headimg" src="../assets/images/avater.png">
                        <img v-else :src="userform.headimg">
                        <div>
                            <div class="flex" style="align-items: center;">
                                <div>{{ userform.username }}</div>
                                <div class="member_box_ziliao_type">{{ userform.card_type_text }}</div>
                            </div>
                            <div class="cardImage_box" v-if="userform.card_code">
                                <img src="../assets/images/cardImage.png">
                                {{ userform.card_code }}
                            </div>
                        </div>
                    </div>
                    <div class="flex member_box_title">
                        <div>
                            <div>{{ userform.consume_total?userform.consume_total:0 }}</div>
                            <div>消费(元)</div>
                        </div>
                        <div>
                            <div>{{ userform.account && userform.account.integral?userform.account.integral:0 }}</div>
                            <div>积分</div>
                        </div>
                        <div>
                            <div>{{userform.usercard && userform.usercard.balance?userform.usercard.balance:0 }}</div>
                            <div>余额(元)</div>
                        </div>
                    </div>
                    <div class="flex member_box_button">
                        <div @click.stop="hairpin">
                            会员发卡
                            <img src="../assets/images/right.png">
                        </div>
                        <div @click.stop="maintenance">
                            会员维护
                            <img src="../assets/images/right.png">
                        </div>
                        <!--                    <div>-->
                        <!--                        积分处理-->
                        <!--                        <img src="../assets/images/right.png">-->
                        <!--                    </div>-->
                        <div @click.stop="topUp">
                            会员储值
                            <img src="../assets/images/right.png">
                        </div>
                    </div>
                </div>
                <div class="shop_box">
                    <div class="flex shop_box_title">
                        <div>商品信息</div>
                        <!--                    <div>促销方案</div>-->
                    </div>
                    <div class="shop_box_content">
                        <div>
                            <div>导购人员</div>
                            <div>{{$store.state.user.userInfo.user_info.username}}</div>
                        </div>
                        <div>
                            <div>商品条码</div>
                            <div v-if="dataDetail && dataDetail.goods_code!==''">{{ dataDetail.goods_code }}</div>
                        </div>
                        <div>
                            <div>单品优惠</div>
                            <div v-if="dataDetail && dataDetail.dis_real!==''">{{ dataDetail.dis_real }}</div>
                        </div>
                        <div>
                            <div>单品备注</div>
                            <div v-if="dataDetail && dataDetail.remark!==''">{{ dataDetail.remark }}</div>
                        </div>
                        <div>
                            <div>剩余库存</div>
                            <div v-if="dataDetail && dataDetail.stock!==''">{{ dataDetail.stock }}</div>
                        </div>
                    </div>
                </div>
                <div class="grid-content">
                    <div class="accounts">结算方式</div>
                    <div class="pay">
                        <div class="pay_but" @click.stop="payClick(4,'微信')">
                            <img src="../assets/images/wx.png" alt="">
                            <div>微信支付</div>
                        </div>
                        <div class="pay_but" @click.stop="payClick(5,'支付宝')">
                            <img src="../assets/images/zfb.png" alt="">
                            <div>支付宝</div>
                        </div>
                        <div  class="pay_but" @click.stop="payClick(1,'现金')">
                            <img src="../assets/images/xj.png" alt="">
                            <div>现金支付</div>
                        </div>
                        <div  class="pay_but" @click.stop="payClick(6,'积分')">
                            <img src="../assets/images/jfzf.png" alt="">
                            <div>积分支付</div>
                        </div>
                        <!--                    <div class="pay_but" @click.stop="payClick(1,'支付通')">-->
                        <!--                        <img src="../assets/images/zft.png" alt="">-->
                        <!--                        <div>支付通</div>-->
                        <!--                    </div>-->
                    </div>
                </div>
                <div class="button" @click.stop="jiesuanClick">
                    结算+
                </div>
            </div>
        </div>
        <!-- 会员发卡-->
        <membershipCard
            ref="membershipCard"
            :dialogFormVisible=dialogFormVisible
            @membershipCardClick="membershipCardClick"
            :topUpForm="topUpForm"
            :title_text="title_text"
        ></membershipCard>
        <!-- 会员-->
        <hairpin
            ref="hairpin"
            :hairpinVisible=hairpinVisible
            @hairpinClick="hairpinClick"
            :form="form"
        ></hairpin>
        <!-- 会员-->
        <vipList
            ref="table"
            :vipVisible=vipVisible
            @vipClick="vipClick"
            :form="form"
            :keynum="keynum"
        ></vipList>
        <!-- 商品-->
        <shopList
            ref="shop"
            :shopVisible=shopVisible
            @shopClick="shopClick"
            :formShop="formShop"
            :keynum="keynum"
        ></shopList>
        <!-- 价格-->
        <price
            ref="price"
            :priceVisible=priceVisible
            @priceClick="priceClick"
        ></price>
        <!-- 数量 -->
        <number
            ref="number"
            :numberVisible=numberVisible
            @numberClick="numberClick"
        ></number>
        <!-- 折扣 -->
        <discount
            ref="discount"
            :discountVisible=discountVisible
            @discountClick="discountClick"
        ></discount>
        <!-- 折扣 -->
        <reqlprice
            ref="reqlprice"
            :reqlpriceVisible=reqlpriceVisible
            @reqlpriceClick="reqlpriceClick"
        ></reqlprice>
        <!-- 收银结算 -->
        <settlement
            ref="settlement"
            :settlementVisible=settlementVisible
            :payment_type="payment_type"
            :payment_text="payment_text"
            :amount_real="amount_real"
            :keynum="keynum"
            :serrlementObj="serrlementObj"
            @settlementClick="settlementClick"
            @eventsettlementClick="eventsettlementClick"
            :returnGoods="returnGoods"
            @surplusClick="surplusClick"
        ></settlement>
        <!-- 储值卡支付 -->
        <stored
            ref="stored"
            :storedVisible=storedVisible
            @storedClick="storedClick"
            @storedClick1="storedClick1"
            :userform=userform
            :title_text=title_text
            :surplus_real=surplus_real
        ></stored>
        <!-- 会员卡列表 -->
        <vipCardList
            ref="vipCard"
            :vipCardVisible=vipCardVisible
            @vipCardClick="vipCardClick"
            :keynum="keynum"
        ></vipCardList>
        <!-- 会员储值 -->
        <topUp
            ref="topUp"
            :topUpVisible=topUpVisible
            @topUpClick="topUpClick"
            :topUpForm="topUpForm"
        ></topUp>
        <!-- 取单 -->
        <takeOrder
            ref="takeOrder"
            :takeOrderVisible=takeOrderVisible
            @takeOrderClick="takeOrderClick"
            :formShop="formShop"
            :returnGoods="returnGoods"
            :keynum="keynum">
        </takeOrder>
        <!-- 交班 -->
        <shiftOver
            ref="shift"
            :shiftVisible=shiftVisible
            @shiftClick="shiftClick"
        >
        </shiftOver>
        <!--单品备注-->
        <singleRemark
            ref="singleRemark"
            :singleVisible=singleVisible
            @singleClick="singleClick"
            :singleText ='singleText'
        >
        </singleRemark>
        <!--整单备注-->
        <remark
            ref="remark"
            :remarkVisible=remarkVisible
            @remarkClick="remarkClick"
            :remark ='remark'
        >
        </remark>
        <!--仓库-->
        <warehouse
            ref="warehouse"
            :warehouseVisible=warehouseVisible
            @warehouseClick="warehouseClick"
            :keynum="keynum"
        >
        </warehouse>
        <!-- 本店库存-->
        <inventoryList
            ref="inventory"
            :inventoryVisible=inventoryVisible
            @inventoryClick="inventoryClick"
            :keynum="keynum"
        ></inventoryList>
        <!-- 商品信息 -->
        <shopDetail
            ref="details"
            :detailsVisible=detailsVisible
            @detailsClick="detailsClick"
            :tableDataIndex="tableDataIndex"
            :keynum="keynum">
        </shopDetail>
        <!-- 友店库存 -->
        <Youdian
            ref="Youdian"
            :youVisible=youVisible
            :chooseShopDetail="chooseShopDetail"
            @youClick="youClick">
        </Youdian>
        <!-- 选择商品 -->
        <chooseShop
            ref="chooseShop"
            :chooseShopVisible=chooseShopVisible
            @chooseShopClick="chooseShopClick"
            :keynum="keynum"
            :chooseNum="chooseNum"
        >
        </chooseShop>
        <!-- 调单退货-->
        <retailReturn
            ref="retailReturn"
            :retailReturnVisible=retailReturnVisible
            @retailReturnClick="retailReturnClick"
        ></retailReturn>
        <!-- 小票列表-->
        <receiptList
            ref="receipt"
            :receiptVisible=receiptVisible
            @receiptClick="receiptClick"
            :order_no="order_no"
            :keynum="keynum"
        ></receiptList>
        <!-- 库存盘点-->
        <takeStock
            ref="takeStock"
            :takeStockVisible=takeStockVisible
            @takeStockClick="takeStockClick"
            @editStockClick="editStockClick"
            :keynum="keynum"
        ></takeStock>
        <!-- 新增盘点-->
        <newCount
            ref="newCount"
            :newCountVisible=newCountVisible
            @newCountClick="newCountClick"
            :keynum="keynum"
            :shopListNew="shopListNew"
            :editStockDetail="editStockDetail"
            :title_text="title_text"
        ></newCount>
        <!-- 多选商品 -->
        <chooseSelectionShop
            ref="selection"
            :selectionVisible=selectionVisible
            @selectionClick="selectionClick"
            :keynum="keynum"
        ></chooseSelectionShop>
        <!-- 现金台账 -->
        <cash
            ref="cash"
            :cashVisible=cashVisible
            @cashClick="cashClick"
        ></cash>
        <!-- 小票查询 -->
        <order
            ref="order"
            :orderVisible=orderVisible
            @orderClick="orderClick"
            :keynum="keynum"
        ></order>
    </div>
</template>

<script>
import membershipCard from '../components/popUp/membershipCard';
import hairpin from '../components/popUp/hairpin';
import vipList from '../components/popUp/vipList';
import shopList from '../components/popUp/shopList'
import price from '../components/popUp/price'
import number from '../components/popUp/number'
import discount from '../components/popUp/discount'
import reqlprice from '../components/popUp/reqlprice'
import settlement from '../components/popUp/settlement'
import stored from  '../components/popUp/stored'
import vipCardList from  '../components/popUp/vipCardList'
import topUp from '../components/popUp/topUp'
import takeOrder from '../components/popUp/takeOrder'
import shiftOver from '../components/popUp/shiftOver'
import singleRemark from "@/components/popUp/singleRemark";
import remark from "@/components/popUp/remark";
import warehouse from "@/components/popUp/warehouse";
import inventoryList from "@/components/popUp/inventoryList";
import shopDetail from "@/components/popUp/shopDetail";
import Youdian from "@/components/popUp/Youdian";
import chooseShop from '@/components/popUp/chooseShop'
import retailReturn from "@/components/popUp/retailReturn";
import takeStock from "@/components/popUp/takeStock";
import receiptList from "@/components/popUp/receiptList";
import newCount from "@/components/popUp/newCount";
import chooseSelectionShop from "@/components/popUp/chooseSelectionShop";
import cash from "@/components/popUp/cash";
import order from "@/components/popUp/order";
export default {
    name: "Index",
    components: {
        membershipCard,
        hairpin,
        vipList,
        shopList,
        price,
        number,
        discount,
        reqlprice,
        settlement,
        stored,
        topUp,
        vipCardList,
        takeOrder,
        shiftOver,
        singleRemark,
        remark,
        warehouse,
        inventoryList,
        shopDetail,
        Youdian,
        chooseShop,
        retailReturn,
        takeStock,
        receiptList,
        newCount,
        chooseSelectionShop,
        cash,
        order
    },
    data() {
        return {
            otherShow:false,
            loading: false,
            dialogFormVisible:false,
            hairpinVisible:false,
            vipVisible:false,
            shopVisible:false,
            priceVisible:false,
            numberVisible:false,
            discountVisible:false,
            reqlpriceVisible:false,
            settlementVisible:false,
            storedVisible: false,
            vipCardVisible:false,
            topUpVisible:false,
            takeOrderVisible:false,
            shiftVisible:false,
            singleVisible:false,
            remarkVisible:false,
            warehouseVisible:false,
            inventoryVisible:false,
            detailsVisible:false,
            youVisible:false,
            chooseShopVisible:false,
            retailReturnVisible:false,
            takeStockVisible:false,
            receiptVisible:false,
            newCountVisible:false,
            selectionVisible:false,
            cashVisible:false,
            orderVisible:false,
            // retailReturnNum:0,
            tableTitle: [
                // {
                //     name: '',
                //     value: '',
                //     width:'40',
                //     formatter: (row) => {
                //         console.log('111',row)
                //         let a = ''
                //         if(row.giveAway == true) {
                //             a = '赠'
                //         }
                //         return a;
                //     }
                // },
                // {
                //     name: '行号',
                //     value: 'index',
                //     width:'70',
                //      formatter: (row) => {
                //         return  row.index+1;
                //      }
                // },
                //
                // {
                //     name: '货号',
                //     value: 'goods_code',
                //     width:'140'
                // },
                // {
                //     name: '品名',
                //     value: 'goods_name',
                //     // width:'250'
                // },
                // {
                //     name: '单位',
                //     value: 'unit_name',
                //     width:'80'
                // },
                // {
                //     name: '商品条码',
                //     value: 'goods_sku',
                //     width:'300'
                // },
                // {
                //     name: '规格',
                //     value: 'goods_spec',
                //     // width:'160'
                // },
                // {
                //     name: '型号',
                //     value: 'goods_model',
                //     // width:'100'
                // },
                {
                    name: '数量',
                    value: 'stock_sales',
                    // width:'90',
                    formatter: (row) => {
                        let a = ''
                        if(this.returnGoods == 1) {
                             a = '-' + row.stock_sales
                        } else {
                            a =  row.stock_sales
                        }
                        return a;
                    }
                },
                {
                    name: '原价',
                    value: 'retail_price',
                    // width:'100'
                },

                {
                    name: '折扣',
                    value: 'discount_rate',
                    // width:'100'
                },
                {
                    name: '实价',
                    value: 'real_price',
                    // width:'100'
                },
                {
                    name: '成交金额',
                    value: 'deal_amount',
                    // width:'130',
                    formatter: (row) => {
                        let a = ''
                        if(this.returnGoods == 1) {
                            a = '-' + row.deal_amount
                        } else {
                            a =  row.deal_amount
                        }
                        return a;
                    }
                }
            ],
            tableDataIndex:[],
            nowTime:'',
            eventList:[
                {
                    id:'Q',
                    text:'数量'
                },
                {
                    id:'P',
                    text:'单价'
                },

                {
                    id:'*',
                    text:'金额'
                },
                {
                    // id:'V',
                    id:'A',
                    text:'会员'
                },
                {
                    // id:'/',
                    id:'Z',
                    text:'删除'
                },
                // {
                //     id:'S',
                //     text:'促销'
                // },

                // {
                //     id:'L',
                //     text:'导购'
                // },
                {
                    // id:'N',
                    id:'C',
                    text:'挂单'
                },
                {
                    // id:'M',
                    id:'V',
                    text:'提单'
                },
                {
                    id:'-',
                    text:'退货'
                },
                {
                    // id:'F4',
                    id:'X',
                    text:'调单退货'
                },
                {
                    // id:'Z',
                    id:'M',
                    text:'赠送'
                },
                {
                    id:'N',
                    text:'打印上单'
                },
                {
                    id:'B',
                    text:'小票查询'
                },
                {
                    // id:'W',
                    id:'',
                    text:'折扣'
                },


                // {
                //     id:'X',
                //     text:'开钱箱'
                // },
            ],
            otherList:[
                {
                    // id:'F',
                    id:'',
                    text:'整单打折'
                },

                {
                    id:'',
                    text:'交班对账'
                },
                {
                    id:'',
                    text:'本店库存'
                },
                {
                    id:'',
                    text:'库存盘点'
                },
                {
                    id:'',
                    text:'单品备注'
                },

                {
                    id:'',
                    text:'友店库存'
                },
                {
                    id:'',
                    text:'现金台账'
                },
                // {
                //     id:'',
                //     text:'现金存取'
                // },
                // {
                //     id:'',
                //     text:'备用金'
                // },

                // {
                //     id:'',
                //     text:'网络切换'
                // },
                // {
                //     id:'N',
                //     text:'数据重传'
                // },
                // {
                //     id:'C',
                //     text:'取消小票'
                // },

                // {
                //     id:'A',
                //     text:'发券'
                // },
                // {
                //     id:'',
                //     text:'挂账回款'
                // },
                // {
                //     id:'B',
                //     text:'修改'
                // },
                // {
                //     id:'',
                //     text:'取重'
                // },
                {
                    id:'',
                    text:'商品信息'
                },

                // {
                //     id:'',
                //     text:'清除促销'
                // },
                {
                    id:'',
                    text:'整单备注'
                },

            ],
            userform:{},
            keyword:'',
            last_orderform:{},
            other_info:{},
            userList:[],
            form: {
                'keywords': '',
            },
            formShop: {
                'keyword': '',
            },
            card_code:'',
            warehouseDetailIndex:{},
            totalText:{
                order_no:''
            },
            keynum:0,
            dataDetail:{},
            dataindex:0,
            amount_real:0,
            num_real:0,
            dis_real:0,
            payment_type:0,
            payment_text:'',
            textshow:false,
            serrlementObj:{},
            title_text:'',
            settlementF:{
                amount:'',
                card_code:''
            },
            topUpForm:{},
            textMain:'',
            markLabel:false,
            singleText:'',
            remark:'',
            warehouseDetail:{},
            chooseShopDetail:{},
            returnGoods:0,
            order_no:'',
            shopListNew:{},
            chooseNum:0,
            editStockDetail:{},
            surplus_real:0
        };
    },
    computed: {

    },
    watch: {

    },
    async mounted() {
        // this.getData()
        // this.InterfaceList()
        this.nowTimes()
        this.other_info = this.$store.state.user.userInfo.other_info
        this.get_last_order()
        this.orderNum()
        this.inputFocus()
        this.warehouseIn()
        // console.log('this.$store.state.user.userInfo.user_info',this.$store.state.user.userInfo.user_info)
    },
    created() {
        this.keyDown()
        this.$store.watch((state, getters) => {
            this.warehouseDetailIndex = state.warehouseDetail
        }, () => {

        })
    },
    beforeDestroy() {
        this.keyDownReview()
    },
    updated() {

    },
    methods: {
        /**
         * 未收金额
         * */
        surplusClick(e) {
            // console.log('qqqqq',e)
            this.surplus_real = e
            // console.log('this.surplus_real33333333',this.surplus_real)
        },
        /**
         * 小票查询事件 取值
         * */
        orderClick(eve) {

        },
        /**
         * 小票查询弹框关闭
         * */
        orderClose() {
            this.orderVisible = false
            this.markLabel = false
            this.keyDown()
            this.inputFocus()
        },
        /**
         * 现金台账 事件 传值
         * */
        cashClick(eve) {

        },
        /**
         * 现金台账弹框关闭
         * */
        cashClose() {
            this.cashVisible = false
            this.markLabel = false
            this.keyDown()
            this.inputFocus()
        },
        /**
         * 多选商品弹框点开
         * */
        selectionShow() {
            this.selectionVisible = true
            this.$refs.selection.onRefresh()
        },
        /**
         * 多选商品列表事件 传值
         * */
        selectionClick(eve) {
            this.shopListNew = eve
        },
        /**
         * 多选商品弹框关闭
         * */
        selectionClose() {
            this.selectionVisible = false
        },
        /**
         * 新增盘点事件 取值
         * */
        newCountClick(eve) {
            this.$refs.takeStock.onRefresh()
        },
        /**
         * 编辑盘点事件 取值
         * */
        editStockClick(eve) {
            this.title_text = '编辑盘点'
            this.editStockDetail = eve
            this.newCountVisible = true
            this.$refs.newCount.InterfaceList()
            setTimeout(() => {
                this.$refs.newCount.editAssignment()
            },300)
        },
        /**
         * 新增盘点弹框关闭
         * */
        newCountClose() {
            this.newCountVisible = false
            this.keyDown()
        },
        /**
         * 新增盘点点击
         * */
        addnewCount() {
            this.title_text = '新增盘点'
            this.newCountVisible = true
            this.$refs.newCount.InterfaceList()
            setTimeout(() => {
                this.$refs.newCount.assignment()
            },300)
        },
        /**
         * 小票列表弹框取值 事件
         * */
        async receiptClick(eve) {
            await this.$axios.get('api/pos_client/order/get_order_info',{
                params:{
                    order_no:eve.order_no,
                }}).then(res => {
                    // console.log('res.data',res.data)
                    if(res.data.data.length<=0) {
                        this.$message.error('没有此单号!');
                        // this.retailReturnNum = 0
                    } else {
                        this.tableDataIndex = res.data.data.orderitem
                        this.tableDataIndex.map((item,index) => {
                            item.retail_price = item.price_selling
                        })
                        if(res.data.data.user) {
                            if (res.data.data.user.card_code) {
                                this.card_code = res.data.data.user.card_code
                                this.getuser()
                            }
                        }


                        this.dataindex = 0
                        this.$axios.get('api/pos_client/base/get_goods_stock',{
                            params:{
                                ware_code:this.tableDataIndex[this.dataindex].ware_code,
                                goods_code:this.tableDataIndex[this.dataindex].goods_code,
                                goods_item_id:this.tableDataIndex[this.dataindex].goods_item_id,
                            }}).then(res => {
                                this.tableDataIndex[this.dataindex].stock = res.data.data.stock
                            }
                        )
                        this.orderNumType()
                        // this.$axios.get('api/pos_client/base/get_order_no',{
                        //     params:{
                        //         type:1
                        //     }
                        // }).then(res => {
                        //     this.totalText.order_no = res.data.data.order_no;
                        // })
                        this.tableDataIndex[this.dataindex].dis_real = Number(this.tableDataIndex[this.dataindex].retail_price) - Number(this.tableDataIndex[this.dataindex].real_price)
                        this.tableDataIndex[this.dataindex].dis_real = this.tableDataIndex[this.dataindex].dis_real.toFixed(2)
                        this.dataDetail = this.tableDataIndex[this.dataindex]
                        this.inputFocus()
                        this.markLabel = false
                        // this.retailReturnNum = 1
                        this.retailReturnClose()
                        this.totalClick()

                    }
                }
            )
        },
        /**
         * 小票弹框关闭
         * */
        receiptClose() {
            this.receiptVisible = false
            this.markLabel = false
            this.keyDown()
            this.inputFocus()
        },
        /**
         * 调单退货 事件 取值
         * */
         async retailReturnClick(eve) {
            await this.$axios.get('api/pos_client/order/get_order_list',{
                params:{
                    order_no:eve,
                    store_code:this.$store.state.user.userInfo.other_info.store_code,
                    desk_code:this.$store.state.user.userInfo.other_info.desk_code,
                }}).then(res => {
                    // console.log('res.data',res.data)
                    if(res.data.code == 500) {
                        return;
                    }
                    if(res.data.data.length<=0) {
                        this.$message.error('没有搜索到结果!');
                    } else {
                        // this.userList = res.data.data.list
                        this.order_no = eve
                        this.receiptVisible = true
                        this.retailReturnVisible = false
                        this.$refs.receipt.assignment()
                        setTimeout(() => {
                            this.$refs.receipt.onRefresh()
                        },200)
                        this.keyDown()
                    }
                }
            )
        },
        /**
         * 调单退货弹框关闭
         * */
        retailReturnClose() {
            this.retailReturnVisible = false
            this.markLabel = false
            this.keyDown()
            this.inputFocus()
        },
        /**
         * 库存盘点列表弹框关闭
         * */
        takeStockClose() {
            this.takeStockVisible = false
            this.markLabel = false
            this.keyDown()
            this.inputFocus()
        },
        /**
         * 库存盘点列表 事件 取值
         * */
        takeStockClick(eve) {

        },
        /**
         * 选择商品
         * */
        chooseShopClick(eve) {
            if(this.newCountVisible == true) {
                this.shopListNew = eve
                setTimeout(() => {
                    this.$refs.newCount.shopArrASS()
                },200)
            } else{
                this.chooseShopDetail = eve
                setTimeout(() => {
                    this.$refs.Youdian.shopassignment()
                },200)
            }
        },
        /**
         * 选择商品弹框退出
         * */
        chooseShopClose() {
            this.chooseShopVisible = false
            this.keyDown()
            this.inputFocus()
        },
        /**
         * 选择商品弹框点开
         * */
        chooseShopShow() {
            if(this.newCountVisible == true) {
                this.chooseNum = 1
            } else if(this.youVisible == true) {
                this.chooseNum = 0
            }
            this.chooseShopVisible = true
            setTimeout(() => {
                this.$refs.chooseShop.onRefresh()
            },300)
        },
        /**
         * 友店弹框退出
         * */
        youClose() {
            this.youVisible = false
            this.markLabel = false
            this.keyDown()
            this.inputFocus()
        },
        /**
         * 友店库存
         * */
        youClick(eve) {

        },
        /**
         * 商品信息
         * */
        detailsClick(eve) {

        },
        /**
         * 商品信息关闭
         * */
        detailsClose() {
            this.detailsVisible = false
            this.markLabel = false
            this.keyDown()
            this.inputFocus()
        },
        /**
         * 本店库存
         * */
        inventoryClick(eve) {

        },
        /**
         * 本店库存弹框关闭
         * */
        inventoryClose() {
            this.inventoryVisible = false
            this.markLabel = false
            this.keyDown()
            this.inputFocus()
        },
        /**
         * 仓库关闭
         * */
        warehouseClose() {
            this.warehouseVisible = false
            this.markLabel = false
            this.keyDown()
            this.inputFocus()
        },
        /**
         * 仓库传值 事件
         * */
        warehouseClick(eve) {
            this.warehouseDetail = eve
            this.$store.commit('setwarehouseDetail', this.warehouseDetail)
            this.warehouseVisible = false
        },
        /**
         * 进页面获取仓库
         * */
        async warehouseIn() {
            await this.$axios.get('api/pos_client/user/get_ware_list',{
                params:{
                    store_code:this.$store.state.user.userInfo.other_info.store_code,
                    desk_code:this.$store.state.user.userInfo.other_info.desk_code,
                }}).then(res => {
                res.data.data.map((item,index) => {
                    if(item.is_default == 1) {
                        this.warehouseDetail = item
                        this.$store.commit('setwarehouseDetail', this.warehouseDetail)
                    }
                })
            })
        },
        /**
         * 仓库点击
         * */
        async cangkuclick() {
            this.warehouseVisible = true
            this.$refs.warehouse.onRefresh()
            this.markLabel = true
            this.keyDown()

        },
        /**
         * 退出
         * */
        exit() {
            this.$confirm('确定退出系统吗', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                window.window.close()
            }).catch(() => {
            });
        },
        /**
         * 整单备注 取值 事件
         * */
        remarkClick(eve) {
            this.remark = eve
            this.remarkVisible = false
            this.keyDown()
        },
        /**
         * 单品备注弹框关闭
         * */
        remarkClose() {
            this.remarkVisible = false
            this.markLabel = false
            this.keyDown()
            this.inputFocus()
        },
        /**
         * 单品备注 取值 事件
         * */
        singleClick(eve) {
            this.tableDataIndex[this.dataindex].remark = eve
            this.singleVisible = false
            this.keyDown()
            this.singleClose()
            this.inputFocus()
        },
        /**
         * 单品备注弹框关闭
         * */
        singleClose() {
            this.singleVisible = false
            this.markLabel = false
            this.keyDown()
            this.inputFocus()
        },
        /**
         * 会员维护点击
         * */
        maintenance() {
            // this.form.keywords = ''
            // this.vipCardVisible = true
            // this.$refs.vipCard.onRefresh()
            // this.markLabel = true
            // this.textMain = 1
            // this.keyDown()
        },
        /**
         * 鼠标移除其他
         * */
        outOther() {
            this.otherShow = false
        },
        /**
         * 鼠标移入其他
         * */
        overOther() {
            this.otherShow = true
        },
        /**
         * pos交班传值  事件
         * */
        shiftClick(eve) {

        },
        /**
         * pos交班弹框关闭
         * */
        shiftClose() {
            this.markLabel = false
            this.shiftVisible = false
            this.keyDown()
            this.inputFocus()
        },
        /**
         * 挂单
         * */
        async pendingOrder() {
            await this.$axios.post('api/pos_client/order/pending_order',{
                order_no:this.totalText.order_no,
                uuid:this.userform.id,
                store_code:this.$store.state.user.userInfo.other_info.store_code,
                desk_code:this.$store.state.user.userInfo.other_info.desk_code,
                amount_real:this.amount_real,
                goods_item:this.tableDataIndex,
                order_type:this.returnGoods==0?1:2

            }).then(res => {
                    if(res.data.code == 200) {
                        this.tableDataIndex = []
                        this.dis_real = 0
                        this.num_real = 0
                        this.amount_real = 0
                        this.dataDetail = {}
                        this.dataindex = 0
                        this.get_last_order()
                        this.orderNum()
                        // this.getuser()

                        this.userform = {}
                    }
                    this.keyDown()
                    this.inputFocus()
                }
            )
        },
        /**
         * 提单
         * */
        takeOrderClick(eve) {
            // console.log('eve',eve)
            this.tableDataIndex = eve.orderitem
            this.tableDataIndex.map((item,index) => {
                item.retail_price = item.price_selling
            })
            if (eve.card_code) {
                this.card_code = eve.card_code
                this.getuser()
            }
            this.totalClick()
            this.dataindex = 0
            this.$axios.get('api/pos_client/base/get_goods_stock',{
                params:{
                    ware_code:this.tableDataIndex[this.dataindex].ware_code,
                    goods_code:this.tableDataIndex[this.dataindex].goods_code,
                    goods_item_id:this.tableDataIndex[this.dataindex].goods_item_id,
                }}).then(res => {
                    this.tableDataIndex[this.dataindex].stock = res.data.data.stock
                }
            )
            this.tableDataIndex[this.dataindex].dis_real = Number(this.tableDataIndex[this.dataindex].retail_price) - Number(this.tableDataIndex[this.dataindex].real_price)
            this.tableDataIndex[this.dataindex].dis_real = this.tableDataIndex[this.dataindex].dis_real.toFixed(2)
            this.dataDetail = this.tableDataIndex[this.dataindex]
            this.totalText.order_no = eve.order_no
            this.inputFocus()
            this.markLabel = false
            // this.dis_real = eve.orderitem
        },

        /**
         * 会员储值传值 事件
         * */
        topUpClick(eve) {
            if(this.card_code !=='') {
                this.getuser()
            }
            this.topUpForm = {}
            this.markLabel = false
        },
        /**
         * 会员卡列表传值 事件
         * */
        vipCardClick (eve) {
            // console.log('eve',eve)
            if(this.textMain == 1) {
                this.markLabel = true
                this.dialogFormVisible = true
                this.inputBlurs()
                this.keyDownReview()
                this.topUpForm = eve
                this.title_text = "会员卡修改"
                setTimeout(() => {
                    this.$refs.membershipCard.getData()
                    this.$refs.membershipCard.membershipDetail()
                },200)
            } else {
                this.inputBlurs()
                this.markLabel = true
                this.topUpVisible = true
                this.topUpForm = eve
                this.$refs.topUp.assignment()
            }

        },
        /**
         * 结算点击
         * */
        payClick(eve,text) {
            if(this.tableDataIndex.length<=0) {
                this.$message.error('没有商品!');
                this.inputFocus()
                this.keyDown()
                return;
            }
            this.markLabel = true
            this.payment_type = eve
            this.payment_text = text
            this.settlementVisible = true
            // this.$refs.settlement.onRefresh()
            this.inputBlurs()
            this.$refs.settlement.inputFocus()
            this.$refs.settlement.assignment()
            if(this.payment_type == 6) {
                this.title_text = "积分支付"
                if(this.surplus_real == 0) {
                    this.surplus_real = this.amount_real
                }
                // console.log('this.surplus_real',this.surplus_real)
                this.storedVisible = true
                this.$refs.settlement.inputBlurs()
                setTimeout(() => {
                    this.$refs.stored.inputFocus()
                    this.$refs.stored.assignment()
                },200)
            }
            this.keyDown()
        },
        /**
         * 结算点击
         * */
        jiesuanClick() {
            if(this.tableDataIndex.length<=0) {
                this.$message.error('没有商品!');
                this.inputFocus()
                this.keyDown()
                return;
            }
            this.markLabel = true
            this.payment_type = 1
            this.payment_text = "现金"
            this.settlementVisible = true
            this.inputBlurs()
            this.$refs.settlement.inputFocus()
            this.$refs.settlement.assignment()
            this.keyDown()
        },
        /**
         * 首页输入框获取焦点
         * */
        inputFocus() {
            //获取输入框焦点
            this.$nextTick(() => {
                this.$refs.inputName.focus();
            })
        },
        /**
         * 首页输入框失去焦点
         * */
        inputBlurs() {
            //获取输入框焦点
            this.$nextTick(() => {
                this.$refs.inputName.blur();
            })
        },
        /**
         * 结算tab点击
         * */
        eventsettlementClick(eve) {
            this.payment_type = eve.num
            this.payment_text = eve.text
            if(this.surplus_real == 0) {
                this.surplus_real = this.amount_real
            }
            if(this.payment_type == 3) {
                this.storedVisible = true
                this.title_text = "储值卡支付"
                this.$refs.settlement.inputBlurs()
                this.$refs.stored.inputFocus()
                setTimeout(() => {
                    this.$refs.stored.assignment()
                },200)
            } else if(this.payment_type == 6) {
                this.title_text = "积分支付"
                this.storedVisible = true
                this.$refs.settlement.inputBlurs()
                this.$refs.stored.inputFocus()
                setTimeout(() => {
                    this.$refs.stored.assignment()
                },200)
            }
        },
        /**
         * 结算传值 事件
         * */
        async settlementClick(eve) {
            //this.returnGoods == 1 || this.retailReturnNum == 1
            if (this.returnGoods == 1 ) {
                await this.$axios.post('api/pos_client/order/refund_order',{
                    order_no:this.totalText.order_no,
                    uuid:this.userform.id,
                    store_code:this.$store.state.user.userInfo.other_info.store_code,
                    desk_code:this.$store.state.user.userInfo.other_info.desk_code,
                    amount_real:eve.amount_real,
                    payment_amount:eve.received,
                    zhaoling_amount:eve.zhaoling_amount,
                    goods_item:this.tableDataIndex,
                    remark:this.remark,
                    amount_reduced:eve.amount_reduced,
                    payment_item:eve.list
                }).then(res => {
                        // console.log(res.data)
                        if(res.data.code == 200) {
                            this.print()
                            this.settlementVisible = false
                            this.keyDown()
                            this.tableDataIndex = []
                            this.dis_real = 0
                            this.num_real = 0
                            this.amount_real = 0
                            this.dataDetail = {}
                            this.dataDetail = 0
                            this.get_last_order()
                            if(this.returnGoods == 1) {
                                this.orderNumType()
                            } else {
                                this.orderNum()
                            }
                            // if (this.retailReturnNum == 1 && this.returnGoods == 0) {
                            //     this.orderNum()
                            // }
                            // if(this.returnGoods == 1 && this.retailReturnNum == 1) {
                            //     this.orderNumType()
                            // }
                            // this.getuser()
                            this.$refs.settlement.inputBlurs()
                            this.inputFocus()
                            this.userform = {}
                            this.markLabel = false
                            this.remark = ''
                            this.singleText = ''
                            // this.retailReturnNum = 0
                        } else if(res.data.code == 0) {
                            this.$refs.settlement.inputFocus()
                        }
                    }
                )
            } else {
                await this.$axios.post('api/pos_client/order/add_order',{
                    order_no:this.totalText.order_no,
                    uuid:this.userform.id,
                    store_code:this.$store.state.user.userInfo.other_info.store_code,
                    desk_code:this.$store.state.user.userInfo.other_info.desk_code,
                    amount_real:eve.amount_real,
                    payment_amount:eve.received,
                    zhaoling_amount:eve.zhaoling_amount,
                    goods_item:this.tableDataIndex,
                    remark:this.remark,
                    amount_reduced:eve.amount_reduced,
                    payment_item:eve.list
                }).then(res => {
                        // console.log(res.data)
                        if(res.data.code == 200) {
                            this.print()
                            this.settlementVisible = false
                            this.keyDown()
                            this.tableDataIndex = []
                            this.dis_real = 0
                            this.num_real = 0
                            this.amount_real = 0
                            this.dataDetail = {}
                            this.dataDetail = 0
                            this.get_last_order()
                            this.orderNum()
                            // this.getuser()
                            this.$refs.settlement.inputBlurs()
                            this.inputFocus()
                            this.userform = {}
                            this.markLabel = false
                            this.remark = ''
                            this.singleText = ''
                        } else if(res.data.code == 0) {
                            this.$refs.settlement.inputFocus()
                        }
                    }
                )
            }

        },
        /**
         * 打印
         * */
        async print() {
            await this.$axios.get('api/pos_client/order/order_print',{params:{
                    desk_code:this.$store.state.user.userInfo.other_info.desk_code,
                    store_code:this.$store.state.user.userInfo.other_info.store_code,
                    order_no:this.totalText.order_no
                }
            })
        },

        /**
         * 选择某一行
         */
        handledbsupplier(row) {
            // console.log('row',row)
            this.dataindex=row.index
            this.$axios.get('api/pos_client/base/get_goods_stock',{
                params:{
                    ware_code:this.tableDataIndex[this.dataindex].ware_code,
                    goods_code:this.tableDataIndex[this.dataindex].goods_code,
                    goods_item_id:this.tableDataIndex[this.dataindex].goods_item_id,
                }}).then(res => {
                    this.tableDataIndex[this.dataindex].stock = res.data.data.stock
                }
            )
            this.tableDataIndex[this.dataindex].dis_real = Number(this.tableDataIndex[this.dataindex].retail_price) - Number(this.tableDataIndex[this.dataindex].real_price)
            this.tableDataIndex[this.dataindex].dis_real = this.tableDataIndex[this.dataindex].dis_real.toFixed(2)
            this.dataDetail=this.tableDataIndex[this.dataindex]
            this.inputFocus()
        },
        /**
         * 改变表格选中某一行    的颜色
         */
        rowStyle(row) {
            let rowBackground = {}
            if(row.rowIndex == this.dataindex){
                rowBackground.background = '#DCEDFF'
            }
            if(this.returnGoods == 1){
                rowBackground.color = '#CF7AE2'
            }
            return rowBackground
        },

        /**
         * 输入框回车
         * */
        async shopEnter() {
            if(this.formShop.keyword !== '' && this.markLabel == false) {
                await this.$axios.get('api/pos_client/base/get_goods_list',{
                    params:{
                        keyword:this.formShop.keyword,
                        ware_code:this.warehouseDetailIndex.ware_code,
                        page:1,
                        length:10
                    }}).then(res => {
                        // console.log('res.data',res.data)
                        if(res.data.data.list.length<=0) {
                            this.$message.error('没有搜索到结果!');
                            this.inputFocus()
                        } else if (res.data.data.list.length == 1) {
                            this.shopClick(res.data.data.list[0])
                        } else {
                            this.markLabel = true
                            this.shopVisible = true
                            this.$refs.shop.assignment()
                            this.$refs.shop.onRefresh()

                        }
                    }
                )
            }
            this.keyDown()
        },
        /**
         * 输入框输入事件
         * */
        inputChange() {
            // console.log(this.formShop.keyword)
            if(this.formShop.keyword !== '') {
                this.keyDownReview()
            } else {
                this.keyDown()
                this.inputFocus()
            }
        },
        /**
         * 输入框失去焦点
         * */
        inputblur () {
            this.keyDown()
        },
        /**
         * shop传值 事件
         * */
        shopClick(eve) {
            let bool = false
            for (let i = 0; i < this.tableDataIndex.length; i++) {
                if (this.tableDataIndex[i].goods_item_id == eve.goods_item_id) {
                    bool = true
                }
            }
            let b = false
            if(bool) {
                this.tableDataIndex.map((item,index) => {
                    if(item.goods_item_id == eve.goods_item_id) {
                        if(item.is_give == 1) {
                            b = true
                            console.log(222)
                        } else {
                            console.log(333)
                            b = false
                            item.stock_sales = Number(item.stock_sales)+1
                            item.deal_amount = (Number(item.retail_price)*(Number(item.discount_rate)/100)) *Number(item.stock_sales)
                            item.deal_amount = item.deal_amount.toFixed(2)
                            item.real_price = Number(item.retail_price)*(Number(item.discount_rate)/100)
                            item.real_price = item.real_price.toFixed(2)
                            item.dis_real = Number(item.retail_price) - Number(item.real_price)
                            item.dis_real.toFixed(2)
                            this.dataDetail = item
                            this.dataindex = index
                            this.$nextTick(() => {
                                this.scrollFileLocation()
                            })
                        }

                    }
                })
                if(b) {
                    console.log(444)
                    // item.deal_amount = 0
                    let goods_item = {}
                    goods_item.discount_rate = 100
                    goods_item.goods_code = eve.code
                    goods_item.goods_item_id = eve.goods_item_id
                    goods_item.stock_sales = 1
                    goods_item.retail_price = eve.price_item.retail_price
                    goods_item.deal_amount = (Number(goods_item.retail_price)*(Number(goods_item.discount_rate)/100)) *Number(goods_item.stock_sales)
                    goods_item.deal_amount = goods_item.deal_amount.toFixed(2)
                    goods_item.real_price = Number(goods_item.retail_price)*(Number(goods_item.discount_rate)/100)
                    goods_item.real_price = goods_item.real_price.toFixed(2)
                    goods_item.goods_name = eve.name
                    goods_item.unit_name = eve.price_item.name
                    goods_item.goods_spec = eve.goods_spec
                    goods_item.goods_model = eve.goods_model
                    goods_item.goods_sku = eve.goods_sku
                    goods_item.remark = eve.remark
                    goods_item.discounts = 0
                    goods_item.dis_real = Number(goods_item.retail_price) - Number(goods_item.real_price)
                    goods_item.dis_real.toFixed(2)
                    goods_item.stock = eve.stock
                    goods_item.ware_code = eve.ware_code
                    goods_item.ware_name = eve.ware_name
                    goods_item.is_give = 0
                    goods_item.unit_id = eve.price_item.unit_id
                    this.tableDataIndex.push(goods_item)
                    this.dataDetail = goods_item
                    this.dataindex = this.tableDataIndex.length-1
                    this.$nextTick(() => {
                        console.log(444)
                        this.scrollFileLocation()
                        // this.$refs.IndexTable.bodyWrapper.scrollTop = this.$refs.IndexTable.bodyWrapper.scrollHeight;
                    })
                }
            } else{
                console.log(555)
                //eve.stock> 0 || this.returnGoods == 1 || this.retailReturnNum == 1
                if(eve.stock> 0 || this.returnGoods == 1) {
                    console.log(666)
                    let goods_item = {}
                    goods_item.discount_rate = 100
                    goods_item.goods_code = eve.code
                    goods_item.goods_item_id = eve.goods_item_id
                    goods_item.stock_sales = 1
                    goods_item.retail_price = eve.price_item.retail_price
                    goods_item.deal_amount = (Number(goods_item.retail_price)*(Number(goods_item.discount_rate)/100)) *Number(goods_item.stock_sales)
                    goods_item.deal_amount = goods_item.deal_amount.toFixed(2)
                    goods_item.real_price = Number(goods_item.retail_price)*(Number(goods_item.discount_rate)/100)
                    goods_item.real_price = goods_item.real_price.toFixed(2)
                    goods_item.goods_name = eve.name
                    goods_item.unit_name = eve.price_item.name
                    goods_item.goods_spec = eve.goods_spec
                    goods_item.goods_model = eve.goods_model
                    goods_item.goods_sku = eve.goods_sku
                    goods_item.remark = eve.remark
                    goods_item.discounts = 0
                    goods_item.dis_real = Number(goods_item.retail_price) - Number(goods_item.real_price)
                    goods_item.dis_real.toFixed(2)
                    goods_item.stock = eve.stock
                    goods_item.ware_code = eve.ware_code
                    goods_item.ware_name = eve.ware_name
                    goods_item.is_give = 0
                    goods_item.unit_id = eve.price_item.unit_id
                    this.tableDataIndex.push(goods_item)
                    this.dataDetail = goods_item
                    this.dataindex = this.tableDataIndex.length-1
                    this.$nextTick(() => {
                        this.scrollFileLocation()
                        // this.$refs.IndexTable.bodyWrapper.scrollTop = this.$refs.IndexTable.bodyWrapper.scrollHeight;
                    })
                } else {
                    this.$message.error('该商品没有库存，不能销售!');
                }

            }
            this.markLabel = false
            this.formShop.keyword = ''
            this.keyDown()
            this.totalClick()
            this.inputFocus()

        },
        /**
         * 总数
         * */
        totalClick() {
            // console.log(111)
            this.dis_real = 0
            this.num_real = 0
            this.amount_real = 0
            this.tableDataIndex.map((item,index) => {
                this.amount_real = Number(item.deal_amount)+Number(this.amount_real)//合计
                this.amount_real = Number(this.amount_real.toFixed(2))
                this.num_real = Number(item.stock_sales)+Number(this.num_real)//合计数量
                let num = Number(item.stock_sales)*Number(item.retail_price)
                let num1 = num-Number(item.deal_amount)
                this.dis_real = Number(num1)+Number(this.dis_real)//优惠金额
                this.dis_real = this.dis_real.toFixed(2)
            })
        },
        /**
         * 会员信息接口
         * */
        async getuser() {
            if(this.card_code !== '') {
                await this.$axios.get('api/pos_client/user/get_card_info',{params:{
                        card_code:this.card_code
                    }}).then(res => {
                    this.userform = res.data.data;
                })
            }
        },
        /**
         * 单号接口
         * */
        async orderNum() {
            await this.$axios.get('api/pos_client/base/get_order_no',{
                params:{
                    desk_code:this.other_info.desk_code,
                }
            }).then(res => {
                this.totalText.order_no = res.data.data.order_no;
            })
        },
        /**
         * 单号接口
         * */
        async orderNumType() {
            await this.$axios.get('api/pos_client/base/get_order_no',{
                params:{
                    type:1,
                    desk_code:this.other_info.desk_code,

                }
            }).then(res => {
                this.totalText.order_no = res.data.data.order_no;
            })
        },
        /**
         * membershipCard传值  事件
         * */
        membershipCardClick(eve) {
          // console.log('e',eve)
            this.markLabel = false
            this.inputFocus()
            this.keyDown()
            this.card_code = eve
            this.topUpForm = {}
            this.getuser()
        },
        /**
         * vip传值 事件
         * */
        vipClick(eve) {
            // console.log(eve)
            this.card_code = eve.card_code
            this.markLabel = true
            this.inputFocus()
            this.keyDown()
           setTimeout(() => {
               if(this.userform.card_code) {
                   this.$confirm('本单已有会员，卡号：'+this.userform.card_code+'，点【确定】替换会员，是否替换会员？', '提示', {
                       confirmButtonText: '确定',
                       cancelButtonText: '取消',
                       type: 'warning'
                   }).then(() => {
                       this.getuser()
                   }).catch(() => {
                   });
               } else {
                   this.getuser()
               }
           },200)
        },
        storedClick1(eve) {
            this.settlementF = eve
        },
        /**
         * 储值卡  积分传值 事件
         * */
        async storedClick(eve) {
            await this.$axios.get('api/pos_client/user/get_card_info',{params:{
                card_code:eve.card_code
            }}).then(res => {
                // console.log('res.data',res.data)
                if(res.data.code == 200) {
                    if(this.payment_type == 3) {
                        if((Number(eve.amount) > Number(res.data.data.usercard.balance)) && this.returnGoods == 0) {
                            this.$message.error('当前储值卡余额小于输入金额!');
                        } else {
                            this.serrlementObj.amount = eve.amount
                            this.serrlementObj.payment_type = 3
                            this.serrlementObj.payment_text = '储值卡'
                            this.serrlementObj.card_code = eve.card_code
                            this.storedVisible = false
                            this.$refs.settlement.addchuzhi()
                            this.storedClose()
                            this.$refs.settlement.inputFocus()
                            this.$refs.stored.qingkong()
                            this.settlementF = {}
                            this.payment_type = 1
                            this.payment_text = '现金'
                        }
                    } else if (this.payment_type == 6) {
                        if((Number(eve.amount) > Number(res.data.data.account.integral)) && this.returnGoods == 0) {
                            this.$message.error('当前积分小于输入积分!');
                        } else {
                            this.serrlementObj.amount = eve.amount
                            this.serrlementObj.payment_type = 6
                            this.serrlementObj.payment_text = '积分'
                            this.serrlementObj.card_code = eve.card_code
                            this.storedVisible = false
                            this.$refs.settlement.addchuzhi()
                            this.storedClose()
                            this.$refs.settlement.inputFocus()
                            this.$refs.stored.qingkong()
                            this.settlementF = {}
                            this.payment_type = 1
                            this.payment_text = '现金'
                        }
                    }
                } else {
                    this.$refs.stored.inputFocus1()
                    this.$refs.stored.qingkong1()
                    // this.$refs.stored.inputBlurs1()
                }
                // this.userform = res.data.data;
            })
        },
        /**
         * 金额传值 事件
         * */
        reqlpriceClick(eve) {
            this.tableDataIndex[this.dataindex].real_price = eve
            this.reqlpriceVisible = false
            this.tableDataIndex[this.dataindex].discount_rate = (Number(this.tableDataIndex[this.dataindex].real_price)/Number(this.tableDataIndex[this.dataindex].retail_price))*100
            this.tableDataIndex[this.dataindex].discount_rate = this.tableDataIndex[this.dataindex].discount_rate.toFixed(2)
            if(  this.tableDataIndex[this.dataindex].is_give == 1) {
                this.tableDataIndex[this.dataindex].deal_amount = 0
            } else {
                this.tableDataIndex[this.dataindex].deal_amount = (Number(this.tableDataIndex[this.dataindex].retail_price)*(Number(this.tableDataIndex[this.dataindex].discount_rate)/100))*Number(this.tableDataIndex[this.dataindex].stock_sales)
                this.tableDataIndex[this.dataindex].deal_amount = this.tableDataIndex[this.dataindex].deal_amount.toFixed(2)
            }
            this.tableDataIndex[this.dataindex].real_price = Number(this.tableDataIndex[this.dataindex].retail_price)*(Number(this.tableDataIndex[this.dataindex].discount_rate)/100)
            this.tableDataIndex[this.dataindex].real_price =this.tableDataIndex[this.dataindex].real_price.toFixed(2)
            this.tableDataIndex[this.dataindex].dis_real = Number(this.tableDataIndex[this.dataindex].retail_price) - Number(this.tableDataIndex[this.dataindex].real_price)
            this.tableDataIndex[this.dataindex].dis_real = this.tableDataIndex[this.dataindex].dis_real.toFixed(2)
            this.totalClick()
            this.keyDown()
            this.reqlpriceClose()
            this.inputFocus()
        },
        /**
         * 价格传值 事件
         * */
        priceClick(eve) {
            this.tableDataIndex[this.dataindex].retail_price = eve
            if( this.tableDataIndex[this.dataindex].is_give == 1) {
                this.tableDataIndex[this.dataindex].deal_amount = 0
            } else {
                this.tableDataIndex[this.dataindex].deal_amount = (Number(this.tableDataIndex[this.dataindex].retail_price)*(Number(this.tableDataIndex[this.dataindex].discount_rate)/100))*Number(this.tableDataIndex[this.dataindex].stock_sales)
                this.tableDataIndex[this.dataindex].deal_amount = this.tableDataIndex[this.dataindex].deal_amount.toFixed(2)
            }
            this.tableDataIndex[this.dataindex].real_price = Number(this.tableDataIndex[this.dataindex].retail_price)*(Number(this.tableDataIndex[this.dataindex].discount_rate)/100)
            this.tableDataIndex[this.dataindex].real_price = this.tableDataIndex[this.dataindex].real_price.toFixed(2)
            this.tableDataIndex[this.dataindex].dis_real = Number(this.tableDataIndex[this.dataindex].retail_price) - Number(this.tableDataIndex[this.dataindex].real_price)
            this.tableDataIndex[this.dataindex].dis_real = this.tableDataIndex[this.dataindex].dis_real.toFixed(2)
            this.totalClick()
            this.priceVisible = false
            this.keyDown()
            this.PriceClose()
            this.inputFocus()
        },
        /**
         * 数量传值 事件
         * */
        numberClick(eve) {
            // console.log(22222222)
            this.tableDataIndex[this.dataindex].stock_sales = eve
            if(  this.tableDataIndex[this.dataindex].is_give == 1) {
                this.tableDataIndex[this.dataindex].deal_amount = 0
            } else {
                this.tableDataIndex[this.dataindex].deal_amount = (Number(this.tableDataIndex[this.dataindex].retail_price)*(Number(this.tableDataIndex[this.dataindex].discount_rate)/100))*Number(this.tableDataIndex[this.dataindex].stock_sales)
                this.tableDataIndex[this.dataindex].deal_amount = this.tableDataIndex[this.dataindex].deal_amount.toFixed(2)
            }
            this.tableDataIndex[this.dataindex].real_price = Number(this.tableDataIndex[this.dataindex].retail_price)*(Number(this.tableDataIndex[this.dataindex].discount_rate)/100)
            this.tableDataIndex[this.dataindex].real_price = this.tableDataIndex[this.dataindex].real_price.toFixed(2)
            this.tableDataIndex[this.dataindex].dis_real = Number(this.tableDataIndex[this.dataindex].retail_price) - Number(this.tableDataIndex[this.dataindex].real_price)
            this.tableDataIndex[this.dataindex].dis_real = this.tableDataIndex[this.dataindex].dis_real.toFixed(2)
            this.totalClick()
            this.numberVisible = false
            this.keyDown()
            this.NumberClose()
            this.inputFocus()
        },
        /**
         * 折扣传值 事件
         * */
        discountClick(eve) {
            this.tableDataIndex[this.dataindex].discount_rate = eve
            if(  this.tableDataIndex[this.dataindex].is_give == 1) {
                this.tableDataIndex[this.dataindex].deal_amount = 0
            } else {
                this.tableDataIndex[this.dataindex].deal_amount = (Number(this.tableDataIndex[this.dataindex].retail_price)*(Number(this.tableDataIndex[this.dataindex].discount_rate)/100))*Number(this.tableDataIndex[this.dataindex].stock_sales)
                this.tableDataIndex[this.dataindex].deal_amount = this.tableDataIndex[this.dataindex].deal_amount.toFixed(2)
            }
            this.tableDataIndex[this.dataindex].real_price = Number(this.tableDataIndex[this.dataindex].retail_price)*(Number(this.tableDataIndex[this.dataindex].discount_rate)/100)
            this.tableDataIndex[this.dataindex].real_price = this.tableDataIndex[this.dataindex].real_price.toFixed(2)
            this.tableDataIndex[this.dataindex].dis_real = Number(this.tableDataIndex[this.dataindex].retail_price) - Number(this.tableDataIndex[this.dataindex].real_price)
            this.tableDataIndex[this.dataindex].dis_real = this.tableDataIndex[this.dataindex].dis_real.toFixed(2)
            this.totalClick()
            this.discountVisible = false
            this.keyDown()
            this.discountClose()
            this.inputFocus()
        },
        /**
         * hairpin传值  事件
         * */
        async hairpinClick(eve) {
            // console.log('hairpin传值',eve)
            await this.$axios.get('api/pos_client/user/get_user_card_list',{
                params:{
                    keywords:eve,
                    page:1,
                    length:10
                }}).then(res => {
                    // console.log('res.data',res.data)
                    if(res.data.data.list.length<=0) {
                        this.$message.error('没有搜索到结果!');
                    } else {
                        // this.userList = res.data.data.list
                        this.form.keywords = eve
                        this.vipVisible = true
                        this.hairpinVisible = false
                        this.$refs.table.onRefresh()
                        this.$refs.table.assignment()
                        this.keyDown()
                    }
                }
            )
        },
        /**
         * 会员卡发卡点击
         * */
        hairpin() {
            // this.dialogFormVisible = true
            // this.inputBlurs()
            // this.keyDownReview()
            // this.title_text = "会员卡发卡"
            // this.$refs.membershipCard.getData()
            // this.markLabel = true
        },
        /**
         * 会员卡储值点击
         * */
        topUp() {
            // this.form.keywords = ''
            // this.vipCardVisible = true
            // this.$refs.vipCard.onRefresh()
            // this.keyDown()
            // this.textMain = 0
            // this.markLabel = true
                    // this.topUpVisible = true
                    // this.inputBlurs()
                    // this.keyDownReview()

        },

        /**
         * 获取上一单接口
         * */
        async get_last_order() {
            //获取卡类型
            await this.$axios.get('api/pos_client/order/get_last_order',{params:{
                    desk_code:this.other_info.desk_code,
                    store_code:this.other_info.store_code
                }}).then(res => {
                this.last_orderform = res.data.data;
            })
        },
        /**
         * 提单弹框关闭
         * */
        takeOrderClose() {
            this.markLabel = false
            this.takeOrderVisible = false
            this.keyDown()
            this.inputFocus()
        },
        /**
         * 会员卡弹框关闭
         * */
        vipCardClose() {
            this.markLabel = false
            this.vipCardVisible = false
            this.keyDown()
            this.inputFocus()
        },
        /**
         *  充值中心弹框关闭
         * */
        topUpClose() {
            this.markLabel = false
            this.topUpVisible = false
            this.keyDown()
            this.inputFocus()
            this.topUpForm = {}
        },
        /**
         *  储值卡弹框关闭
         * */
        storedClose() {
            this.storedVisible = false
            this.keyDown()
            this.inputFocus()
            this.payment_type = 1
            this.payment_text = '现金'
        },
        /**
         *  结算弹框关闭N
         * */
        settlementClose() {
            this.markLabel = false
            this.settlementVisible = false
            this.keyDown()
            this.inputFocus()

        },
        /**
         *  折扣弹框关闭
         * */
        discountClose() {
            this.discountVisible = false
            this.markLabel = false
            this.keyDown()
            this.inputFocus()
        },
        /**
         *  数量弹框关闭
         * */
        NumberClose() {
            this.numberVisible = false
            this.markLabel = false
            this.keyDown()
            this.inputFocus()
        },
        /**
         *  价格弹框关闭
         * */
        PriceClose() {
            this.priceVisible = false
            this.markLabel = false
            this.keyDown()
            this.inputFocus()
        },
        /**
         *  金额弹框关闭
         * */
        reqlpriceClose() {
            this.reqlpriceVisible = false
            this.markLabel = false
            this.keyDown()
            this.inputFocus()
        },
        /**
         * 会员卡发卡弹框关闭
         * */
        DialogClose() {
            this.dialogFormVisible = false
            this.markLabel = false
            this.keyDown()
            this.inputFocus()
            this.topUpForm = {}
        },
        /**
         * 会员弹框关闭
         * */
        HairpinClose() {
            this.hairpinVisible = false
            this.markLabel = false
            this.keyDown()
            this.inputFocus()
        },
        // assignment() {
        //     this.hairpinVisible = false
        //     this.markLabel = false
        //     this.keyDown()
        //     this.inputFocus()
        // },
        /**
         * 商品列表弹框关闭
         * */
        shopClose() {
            this.formShop.keyword = ''
            this.shopVisible = false
            this.markLabel = false
            this.keyDown()
            this.inputFocus()
        },
        /**
         * 会员卡列表弹框关闭
         * */
        VipClose() {
            this.vipVisible = false
            this.markLabel = false
            this.keyDown()
            this.inputFocus()

        },
        /**
         * 底部按钮点击
         * */
        eventClick(eve) {
            if(eve.id == "A") {
                this.hairpinVisible = true
                this.inputBlurs()
                this.$refs.hairpin.assignment()
                this.$refs.hairpin.inputFocus()
                this.keyDownReview()
                this.markLabel = true
            } else if (eve.id == 'X') {
                if(this.returnGoods == 0) {
                    this.$message.error('当前为正常销售状态!');
                    return;
                }
                if(this.tableDataIndex.length>0) {
                    this.$message.error('有商品且未结算不能退货!');
                    this.keyDown()
                    this.inputFocus()
                } else {
                    if(!this.markLabel) {
                        this.markLabel = true
                        this.retailReturnVisible = true
                        this.inputBlurs()
                        this.$refs.retailReturn.inputFocus()
                        this.$refs.retailReturn.assignment()
                        this.keyDownReview()

                    }
                }
            }else if (eve.id == "Q") {
                if(this.tableDataIndex.length>0) {
                    this.numberVisible = true
                    this.inputBlurs()
                    this.$refs.number.inputFocus()
                    // this.keyDownReview()
                    this.$refs.number.assignment()
                    this.markLabel = true
                } else {
                    this.textshow = true
                    this.inputFocus()
                }
            } else if (eve.id == "P") {
                if(this.tableDataIndex.length>0) {
                    this.priceVisible = true
                    // this.keyDownReview()
                    this.inputBlurs()
                    this.$refs.price.inputFocus()
                    this.$refs.price.assignment()
                    this.markLabel = true
                } else {
                    this.textshow = true
                    this.inputFocus()
                }
            }
            // else if (eve.id == "W") {
            //     if(this.tableDataIndex.length>0) {
            //         this.discountVisible = true
            //         // this.keyDownReview()
            //         this.inputBlurs()
            //         this.$refs.discount.inputFocus()
            //         this.$refs.discount.assignment()
            //         this.markLabel = true
            //     } else {
            //         this.textshow = true
            //         this.inputFocus()
            //     }
            // }
            else if (eve.id == "*") {
                if(this.tableDataIndex.length>0) {
                    this.reqlpriceVisible = true
                    // this.keyDownReview()
                    this.inputBlurs()
                    this.$refs.reqlprice.inputFocus()
                    this.$refs.reqlprice.assignment()
                    this.markLabel = true
                } else {
                    this.textshow = true
                    this.inputFocus()
                }
            } else if (eve.id == "/") {
                // if(this.tableDataIndex.length>0) {
                //     this.tableDataIndex.splice(this.dataindex, 1)
                //     this.dataindex = 0
                //     this.dataDetail = this.tableDataIndex[this.dataindex]
                //     this.totalClick()
                // } else {
                //     this.textshow = true
                //     this.inputFocus()
                // }
                // if(this.tableDataIndex.length == 0) {
                //     // if( this.returnGoods == 1) {
                //     //     this.orderNumType()
                //     // } else if (this.returnGoods == 1 && this.retailReturnNum == 1) {
                //     //     this.orderNumType()
                //     // } else {
                //     //     this.orderNum()
                //     // }
                //     if( this.returnGoods == 1) {
                //         this.orderNumType()
                //     } else {
                //         this.orderNum()
                //     }
                // }
            } else if (eve.id == "C") {
                // if(this.returnGoods == 1) {
                //     this.$message.error('当前为退货状态!');
                //     return;
                // }
                if(!this.markLabel) {
                    this.pendingOrder()
                    this.inputFocus()
                }

            } else if(eve.id == "N") {
                this.$axios.get('api/pos_client/order/order_print',{params:{
                        desk_code:this.$store.state.user.userInfo.other_info.desk_code,
                        store_code:this.$store.state.user.userInfo.other_info.store_code,
                        order_no:this.last_orderform.order_no
                    }
                })
                this.inputFocus()
                this.keyDown()
            } else if (eve.id == "M") {//m
                // if(this.returnGoods == 1) {
                //     this.$message.error('当前为退货状态!');
                //     return;
                // }
                // if(this.tableDataIndex.length>0) {
                //     this.$message.error('有商品且未结算不能提单!');
                //     this.keyDown()
                //     this.inputFocus()
                // } else {
                //     this.takeOrderVisible = true
                //     this.$refs.takeOrder.assignment()
                //     this.$refs.takeOrder.onRefresh()
                //     this.markLabel = true
                //     this.keyDown()
                // }
                if(this.tableDataIndex.length>0) {
                    if (this.tableDataIndex[this.dataindex].is_give == 1) {
                        this.tableDataIndex[this.dataindex].is_give = 0
                        this.tableDataIndex[this.dataindex].deal_amount = (Number(this.tableDataIndex[this.dataindex].retail_price)*(Number(this.tableDataIndex[this.dataindex].discount_rate)/100))*Number(this.tableDataIndex[this.dataindex].stock_sales)
                        this.tableDataIndex[this.dataindex].deal_amount = this.tableDataIndex[this.dataindex].deal_amount.toFixed(2)
                    } else {
                        this.tableDataIndex[this.dataindex].is_give = 1
                        this.tableDataIndex[this.dataindex].deal_amount = 0
                    }

                    this.totalClick()
                    this.inputFocus()
                    this.keyDown()
                } else {
                    this.textshow = true
                    this.inputFocus()
                }

            } else if(eve.id == "V") {
                if(this.tableDataIndex.length>0) {
                    this.$message.error('有商品且未结算不能提单!');
                    this.keyDown()
                    this.inputFocus()
                } else {
                    this.takeOrderVisible = true
                    this.$refs.takeOrder.assignment()
                    this.$refs.takeOrder.onRefresh()
                    this.markLabel = true
                    this.keyDown()
                }
            } else if (eve.id == "Z") {//z
                // if(this.returnGoods == 1) {
                //     this.$message.error('当前为退货状态!');
                //     return;
                // }
                // if(this.tableDataIndex.length>0) {
                //     if (this.tableDataIndex[this.dataindex].is_give == 1) {
                //         this.tableDataIndex[this.dataindex].is_give = 0
                //         this.tableDataIndex[this.dataindex].deal_amount = (Number(this.tableDataIndex[this.dataindex].retail_price)*(Number(this.tableDataIndex[this.dataindex].discount_rate)/100))*Number(this.tableDataIndex[this.dataindex].stock_sales)
                //         this.tableDataIndex[this.dataindex].deal_amount = this.tableDataIndex[this.dataindex].deal_amount.toFixed(2)
                //     } else {
                //         this.tableDataIndex[this.dataindex].is_give = 1
                //         this.tableDataIndex[this.dataindex].deal_amount = 0
                //     }
                //
                //     this.totalClick()
                //     this.inputFocus()
                //     this.keyDown()
                // } else {
                //     this.textshow = true
                //     this.inputFocus()
                // }
                if(this.tableDataIndex.length>0) {
                    this.tableDataIndex.splice(this.dataindex, 1)
                    this.dataindex = 0
                    this.dataDetail = this.tableDataIndex[this.dataindex]
                    this.totalClick()
                } else {
                    this.textshow = true
                    this.inputFocus()
                }
                if(this.tableDataIndex.length == 0) {
                    // if( this.returnGoods == 1) {
                    //     this.orderNumType()
                    // } else if (this.returnGoods == 1 && this.retailReturnNum == 1) {
                    //     this.orderNumType()
                    // } else {
                    //     this.orderNum()
                    // }
                    if( this.returnGoods == 1) {
                        this.orderNumType()
                    } else {
                        this.orderNum()
                    }
                }
            } else if(eve.id == "-") {
                if(this.tableDataIndex.length>0) {
                    if (this.returnGoods == 1) {
                        this.$message.error('有商品且未退货,不能切换正常销售状态!');
                    } else {
                        this.$message.error('有商品且未结算,不能切换退货状态!');
                    }
                } else {
                    if(this.returnGoods == 0) {
                        this.returnGoods = 1
                    } else {
                        this.returnGoods = 0
                    }
                }
                this.keyDown()
                this.inputFocus()
            } else if(eve.id == "B") {
                this.markLabel = true
                this.orderVisible = true
                this.$refs.order.assignment()
                // this.$refs.order.onRefresh()
                this.keyDown()
            } else if (eve.id == "") {
                if(eve.text == '折扣') {
                    if(this.tableDataIndex.length>0) {
                        this.discountVisible = true
                        // this.keyDownReview()
                        this.inputBlurs()
                        this.$refs.discount.inputFocus()
                        this.$refs.discount.assignment()
                        this.markLabel = true
                    } else {
                        this.textshow = true
                        this.inputFocus()
                    }
                }
                if(eve.text == "整单备注")  {
                    if(this.tableDataIndex.length>0) {
                        this.markLabel = true
                        this.remarkVisible = true
                        this.inputBlurs()
                        this.keyDown()
                        // this.remark =  this.remark
                        this.$refs.remark.inputFocus()
                        this.$refs.remark.assignment()
                    } else {
                        this.textshow = true
                        this.inputFocus()
                    }
                }
                // if(eve.text == "小票查询") {
                //     this.markLabel = true
                //     this.orderVisible = true
                //     this.$refs.order.assignment()
                //     // this.$refs.order.onRefresh()
                //     this.keyDown()
                // }
                if(eve.text == "现金台账") {
                    this.markLabel = true
                    this.cashVisible = true
                    this.$refs.cash.assignment()
                    this.$refs.cash.onRefresh()
                    this.keyDown()
                }
                if(eve.text == "库存盘点") {
                    this.markLabel = true
                    this.takeStockVisible = true
                    this.$refs.takeStock.assignment()
                    this.$refs.takeStock.onRefresh()
                    this.keyDown()
                }
                if(eve.text == "友店库存")  {
                    this.markLabel = true
                    this.youVisible = true
                    this.chooseShopDetail = {}
                    this.$refs.Youdian.assignment()
                    this.$refs.Youdian.onRefresh()
                    this.keyDown()
                }
                if(eve.text == "商品信息")  {
                    this.markLabel = true
                    this.detailsVisible = true
                    this.$refs.details.assignment()
                    this.keyDown()
                }
                if(eve.text == "本店库存")  {
                    this.markLabel = true
                    this.inventoryVisible = true
                    this.$refs.inventory.onRefresh()
                    this.$refs.inventory.assignment()
                    this.keyDown()
                }
                if(eve.text == "单品备注")  {
                    if(this.tableDataIndex.length>0) {
                        this.markLabel = true
                        this.singleVisible = true
                        this.inputBlurs()
                        this.keyDown()
                        this.singleText = this.dataDetail.remark
                        this.$refs.singleRemark.inputFocus()
                        this.$refs.singleRemark.assignment()
                    } else {
                        this.textshow = true
                        this.inputFocus()
                    }
                }
                if(eve.text == "交班对账")  {
                    if(this.tableDataIndex.length>0) {
                        this.$message.error('有商品且未结算的单据不能交班!');
                        this.keyDown()
                        this.inputFocus()
                    } else {
                        this.markLabel = true
                        this.shiftVisible = true
                        this.inputBlurs()
                        this.keyDown()
                        this.$refs.shift.time()
                    }

                    // setTimeout(() => {
                    //     this.$refs.shift.shiftEnter()
                    // },200)
                }
                // if(eve.text == "打印上单")  {
                //     this.$axios.get('api/pos_client/order/order_print',{params:{
                //             desk_code:this.$store.state.user.userInfo.other_info.desk_code,
                //             store_code:this.$store.state.user.userInfo.other_info.store_code,
                //             order_no:this.last_orderform.order_no
                //         }
                //     })
                //     this.inputFocus()
                //     this.keyDown()
                // }
            }
        },
        /**
         * 按键恢复
         * */
        keyDownReview () {
            document.onkeydown = function (event) {
                var e = event || window.event;
                e.returnValue = true;
            }
        },
        /**
         * 按钮监听键盘
         * */
        keyDown () {
            var that = this
            //监听键盘按钮
            document.onkeydown = function (event) {
                var e = event || window.event;
                // console.log('e',e)
                var keyCode = e.keyCode || e.which;
                // console.log(keyCode)
                if (e.keyCode == 80 || e.keyCode == 46  || e.keyCode == 81 || e.keyCode == 106 ||e.keyCode == 109
                    ||e.keyCode == 65||e.keyCode == 67||e.keyCode == 69|| e.keyCode == 90
                    ||e.keyCode == 83 || e.keyCode == 86 || e.keyCode == 87|| e.keyCode == 88|| e.keyCode == 107 || e.keyCode == 78|| e.keyCode == 77|| e.keyCode == 66) {
                    if (e && e.preventDefault) {
                        e.preventDefault();
                    } else {
                        window.event.returnValue = false;
                    }
                }
                if(keyCode == 80) { //p
                    if(that.tableDataIndex.length>0) {
                        if(!that.markLabel) {
                            that.priceVisible = true
                            that.inputBlurs()
                            that.$refs.price.inputFocus()
                            that.$refs.price.assignment()
                            that.markLabel = true
                            // that.keyDownReview()
                        }
                    } else {
                        that.textshow = true
                        that.inputFocus()
                    }
                } else if (keyCode == 66) { //B
                    that.markLabel = true
                    that.orderVisible = true
                    that.$refs.order.assignment()
                        // that.$refs.order.onRefresh()
                    that.keyDown()
                } else if(keyCode == 109) {//-
                    if(that.tableDataIndex.length>0) {
                        if (that.returnGoods == 1) {
                            that.$message.error('有商品且未退货,不能切换正常销售状态!');
                        } else {
                            that.$message.error('有商品且未结算,不能切换退货状态!');
                        }
                    } else {
                        if(that.returnGoods == 0) {
                            that.returnGoods = 1
                            that.orderNumType()
                        } else {
                            that.returnGoods = 0
                            that.orderNum()
                        }
                    }
                    that.keyDown()
                    that.inputFocus()

                } else if (keyCode == 107) {//+
                    if(that.tableDataIndex.length<=0) {
                        that.$message.error('没有商品!');
                        that.inputFocus()
                        that.keyDown()
                        return;
                    }
                    if(!that.markLabel) {
                        that.markLabel = true
                        that.payment_type = 1
                        that.payment_text = "现金"
                        that.settlementVisible = true
                        that.inputBlurs()
                        that.$refs.settlement.inputFocus()
                        that.$refs.settlement.assignment()
                        that.keyDown()
                    }
                } else if (keyCode == 46 ) {//del|| keyCode == 110
                    if(that.settlementVisible == true && that.markLabel && !that.storedVisible) {
                        that.$refs.settlement.del()
                    }
                    if(that.takeOrderVisible == true) {
                        that.$refs.takeOrder.del()
                    }
                } else if (keyCode == 67) {//c
                    // if(this.returnGoods == 1) {
                    //     this.$message.error('当前为退货状态!');
                    //     return;
                    // }
                    if(!that.markLabel) {
                        that.pendingOrder()
                    }

                }  else if (keyCode == 78) { //n
                    that.$axios.get('api/pos_client/order/order_print',{params:{
                            desk_code:that.$store.state.user.userInfo.other_info.desk_code,
                            store_code:that.$store.state.user.userInfo.other_info.store_code,
                            order_no:that.last_orderform.order_no
                        }
                    })
                    that.inputFocus()
                    that.keyDown()
                } else if (keyCode == 77) {//m
                    // if(this.returnGoods == 1) {
                    //     this.$message.error('当前为退货状态!');
                    //     return;
                    // }
                    // if(!that.markLabel) {
                    //     if(that.tableDataIndex.length>0) {
                    //         that.$message.error('有商品且未结算不能提单!');
                    //         that.keyDown()
                    //         that.inputFocus()
                    //     } else {
                    //         that.markLabel = true
                    //         that.takeOrderVisible = true
                    //         that.$refs.takeOrder.assignment()
                    //         that.$refs.takeOrder.onRefresh()
                    //         that.keyDown()
                    //     }
                    // }
                    if(that.tableDataIndex.length>0) {
                        if(!that.markLabel) {
                            if (that.tableDataIndex[that.dataindex].is_give == 1) {
                                that.tableDataIndex[that.dataindex].is_give = 0
                                that.tableDataIndex[that.dataindex].deal_amount = (Number(that.tableDataIndex[that.dataindex].retail_price)*(Number(that.tableDataIndex[that.dataindex].discount_rate)/100))*Number(that.tableDataIndex[that.dataindex].stock_sales)
                                that.tableDataIndex[that.dataindex].deal_amount = that.tableDataIndex[that.dataindex].deal_amount.toFixed(2)
                            } else {
                                // that.markLabel = true
                                that.tableDataIndex[that.dataindex].is_give = 1
                                that.tableDataIndex[that.dataindex].deal_amount = 0
                            }
                            that.totalClick()
                            that.inputFocus()
                            that.keyDown()
                        }
                    } else {
                        that.textshow = true
                        that.inputFocus()
                    }
                } else if (keyCode == 81) {//q
                    if(that.tableDataIndex.length>0) {
                        if(!that.markLabel) {
                            that.numberVisible = true
                            that.inputBlurs()
                            that.$refs.number.inputFocus()
                            that.$refs.number.assignment()
                            that.markLabel = true
                            // that.keyDownReview()

                        }
                    } else {
                        that.textshow = true
                        that.inputFocus()
                    }
                } else if (keyCode == 90) {//z
                    // if(that.returnGoods == 1) {
                    //     that.$message.error('当前为退货状态!');
                    //     return;
                    // }
                    // if(that.tableDataIndex.length>0) {
                    //     if(!that.markLabel) {
                    //         if (that.tableDataIndex[that.dataindex].is_give == 1) {
                    //             that.tableDataIndex[that.dataindex].is_give = 0
                    //             that.tableDataIndex[that.dataindex].deal_amount = (Number(that.tableDataIndex[that.dataindex].retail_price)*(Number(that.tableDataIndex[that.dataindex].discount_rate)/100))*Number(that.tableDataIndex[that.dataindex].stock_sales)
                    //             that.tableDataIndex[that.dataindex].deal_amount = that.tableDataIndex[that.dataindex].deal_amount.toFixed(2)
                    //         } else {
                    //             // that.markLabel = true
                    //             that.tableDataIndex[that.dataindex].is_give = 1
                    //             that.tableDataIndex[that.dataindex].deal_amount = 0
                    //         }
                    //         that.totalClick()
                    //         that.inputFocus()
                    //         that.keyDown()
                    //     }
                    // } else {
                    //     that.textshow = true
                    //     that.inputFocus()
                    // }
                    if(that.tableDataIndex.length>0) {
                        if(!that.markLabel) {
                            that.tableDataIndex.splice(that.dataindex, 1)
                            that.dataindex = 0
                            that.dataDetail = that.tableDataIndex[that.dataindex]
                            that.totalClick()
                            that.scrollFileLocation()
                        }
                    } else {
                        that.textshow = true
                        that.inputFocus()
                    }
                    if(that.tableDataIndex.length == 0 ) {
                        // if( that.returnGoods == 1) {
                        //     that.orderNumType()
                        // } else if (that.returnGoods == 1 && that.retailReturnNum == 1) {
                        //     that.orderNumType()
                        // } else {
                        //     that.orderNum()
                        // }
                        if( that.returnGoods == 1) {
                            that.orderNumType()
                        }else {
                            that.orderNum()
                        }
                    }
                } else if (keyCode == 106) {//*
                    if(that.tableDataIndex.length>0) {
                        if(!that.markLabel) {
                            that.markLabel = true
                            that.reqlpriceVisible = true
                            // that.keyDownReview()
                            that.inputBlurs()
                            that.$refs.reqlprice.inputFocus()
                            that.$refs.reqlprice.assignment()
                        }
                    } else {
                        that.textshow = true
                        that.inputFocus()
                    }
                } else if (keyCode == 65) {//a
                    if(that.settlementVisible == true) {
                        if(!that.storedVisible) {
                            that.payment_type = 1
                            that.payment_text = '现金'
                        }
                    } else {
                        if(!that.markLabel) {
                            that.markLabel = true
                            that.hairpinVisible = true
                            that.inputBlurs()
                            that.$refs.hairpin.inputFocus()
                            that.$refs.hairpin.assignment()
                            that.keyDownReview()
                        }
                    }
                } else if (keyCode == 68) {//d
                    if(that.settlementVisible == true) {
                        if(!that.storedVisible) {
                            if(that.surplus_real == 0) {
                                that.surplus_real = that.amount_real
                            }
                            that.payment_type = 6
                            that.payment_text = '积分'
                            that.title_text = "积分支付"
                            that.storedVisible = true
                            that.$refs.settlement.inputBlurs()
                            setTimeout(() => {
                                that.$refs.stored.inputFocus()
                                that.$refs.stored.assignment()
                            },200)

                        }
                    }
                } else if (keyCode == 69) {//e
                    if(that.settlementVisible == true) {
                        if(!that.storedVisible) {
                            that.payment_type = 5
                            that.payment_text = '支付宝'
                        }
                    }
                } else if (keyCode == 83) {//s
                    if(that.settlementVisible == true) {
                        if(!that.storedVisible) {
                            that.payment_type = 2
                            that.payment_text = '银行卡'
                        }

                    }
                }  else if (keyCode == 86) {//v
                    if(that.settlementVisible == true) {
                        if(!that.storedVisible) {
                            that.payment_type = 4
                            that.payment_text = '微信'
                        }
                    } else{
                        if(!that.markLabel) {
                            if(that.tableDataIndex.length>0) {
                                that.$message.error('有商品且未结算不能提单!');
                                that.keyDown()
                                that.inputFocus()
                            } else {
                                that.markLabel = true
                                that.takeOrderVisible = true
                                that.$refs.takeOrder.assignment()
                                that.$refs.takeOrder.onRefresh()
                                that.keyDown()
                            }
                        }
                    }
                    // else {
                    //     if(!that.markLabel) {
                    //         that.markLabel = true
                    //         that.hairpinVisible = true
                    //         that.inputBlurs()
                    //         that.$refs.hairpin.inputFocus()
                    //         that.$refs.hairpin.assignment()
                    //         that.keyDownReview()
                    //     }
                    // }
                }  else if (keyCode == 88) {//f4
                    if(that.returnGoods == 0) {
                        that.$message.error('当前为正常销售状态!');
                        return;
                    }
                    if(that.tableDataIndex.length>0) {
                        that.$message.error('有商品且未结算不能退货!');
                        that.keyDown()
                        that.inputFocus()
                    } else {
                        if(!that.markLabel) {
                            that.markLabel = true
                            that.retailReturnVisible = true
                            that.inputBlurs()
                            that.$refs.retailReturn.inputFocus()
                            that.$refs.retailReturn.assignment()
                            that.keyDownReview()
                        }
                    }

                }else if (keyCode == 87) {//w
                    if(that.settlementVisible == true) {
                        if(!that.storedVisible) {
                            if(that.surplus_real == 0) {
                                that.surplus_real = that.amount_real
                            }
                            that.payment_type = 3
                            that.payment_text = '储值卡'
                            that.storedVisible = true
                            that.title_text = "储值卡支付"
                            that.$refs.settlement.inputBlurs()
                            setTimeout(() => {
                                that.$refs.stored.inputFocus()
                                that.$refs.stored.assignment()
                            },200)
                        }
                    } else {
                        // if(that.tableDataIndex.length>0) {
                        //     if(!that.markLabel) {
                        //         that.markLabel = true
                        //         that.discountVisible = true
                        //         // that.keyDownReview()
                        //         that.inputBlurs()
                        //         that.$refs.discount.inputFocus()
                        //         that.$refs.discount.assignment()
                        //     }
                        // } else {
                        //     that.textshow = true
                        //     that.inputFocus()
                        // }
                    }
                } else if (keyCode == 27) { //esc
                    // that.HairpinClose()
                    that.DialogClose()
                    that.VipClose()
                    that.PriceClose()
                    that.NumberClose()
                    that.discountClose()
                    that.shopClose()
                    that.reqlpriceClose()
                    that.vipCardClose()
                    that.topUpClose()
                    that.takeOrderClose()
                    that.shiftClose()
                    that.inputFocus()
                    that.inventoryClose()
                    that.detailsClose()
                    that.cashClose()
                    that.orderClose()
                    that.warehouseClose()
                    if(that.newCountVisible == true) {
                        if(that.selectionVisible == true) {
                            that.selectionClose()
                        } else {
                            if(that.chooseShopVisible == false) {
                                that.newCountClose()
                            } else {
                                that.chooseShopClose()
                            }
                        }
                    } else {
                        that.takeStockClose()
                    }
                    that.receiptClose()
                    if(that.chooseShopVisible == false) {
                        that.youClose()
                    } else {
                        that.chooseShopClose()
                    }
                    if(that.storedVisible == false) {
                        that.settlementClose()
                    }
                    if(that.storedVisible == true) {
                        that.storedClose()
                        that.payment_type = 1
                        that.payment_text = '现金'
                        that.$refs.settlement.inputFocus()
                        that.$refs.stored.qingkong()
                        that.markLabel = true
                    } else {
                        that.markLabel = false
                    }
                } else if (keyCode == 191 || keyCode == 111 ){//    /
                    // if(that.tableDataIndex.length>0) {
                    //     if(!that.markLabel) {
                    //         that.tableDataIndex.splice(that.dataindex, 1)
                    //         that.dataindex = 0
                    //         that.dataDetail = that.tableDataIndex[that.dataindex]
                    //         that.totalClick()
                    //         that.scrollFileLocation()
                    //     }
                    // } else {
                    //     that.textshow = true
                    //     that.inputFocus()
                    // }
                    // if(that.tableDataIndex.length == 0 ) {
                    //     // if( that.returnGoods == 1) {
                    //     //     that.orderNumType()
                    //     // } else if (that.returnGoods == 1 && that.retailReturnNum == 1) {
                    //     //     that.orderNumType()
                    //     // } else {
                    //     //     that.orderNum()
                    //     // }
                    //     if( that.returnGoods == 1) {
                    //         that.orderNumType()
                    //     }else {
                    //         that.orderNum()
                    //     }
                    // }
                } else if (keyCode == 37){// 左
                    if(that.takeStockVisible == true) {
                        that.keynum = 37
                        setTimeout(() => {
                            that.$refs.takeStock.keyupVip()
                        },300)
                    }
                    if(that.vipVisible == true) {
                        that.keynum = 37
                        setTimeout(() => {
                            that.$refs.table.keyupVip()
                        },300)
                    }
                    if(that.shopVisible == true) {
                        that.keynum = 37
                        setTimeout(() => {
                            that.$refs.shop.keyupVip()
                        },300)
                    }
                    if(that.inventoryVisible == true) {
                        that.keynum = 37
                        setTimeout(() => {
                            that.$refs.inventory.keyupVip()
                        },300)
                    }
                    if(that.vipCardVisible == true) {
                        that.keynum = 37
                        setTimeout(() => {
                            that.$refs.vipCard.keyupVip()
                        },300)
                    }
                    if(that.chooseShopVisible == true) {
                        that.keynum = 37
                        setTimeout(() => {
                            that.$refs.chooseShop.keyupVip()
                        },300)
                    }
                } else if (keyCode == 39){// 右
                    if(that.takeStockVisible == true) {
                        that.keynum = 39
                        setTimeout(() => {
                            that.$refs.takeStock.keyupVip()
                        },300)
                    }
                    if(that.vipVisible == true) {
                        that.keynum = 39
                        setTimeout(() => {
                            that.$refs.table.keyupVip()
                        },300)
                    }
                    if(that.shopVisible == true) {
                        that.keynum = 39
                        setTimeout(() => {
                            that.$refs.shop.keyupVip()
                        },300)
                    }
                    if(that.inventoryVisible == true) {
                        that.keynum = 39
                        setTimeout(() => {
                            that.$refs.inventory.keyupVip()
                        },300)
                    }
                    if(that.vipCardVisible == true) {
                        that.keynum = 39
                        setTimeout(() => {
                            that.$refs.vipCard.keyupVip()
                        },300)
                    }
                    if(that.chooseShopVisible == true) {
                        that.keynum = 39
                        setTimeout(() => {
                            that.$refs.chooseShop.keyupVip()
                        },300)
                    }
                } else if(keyCode == 38) {//上
                    if(that.vipVisible == true) {
                        that.keynum = 38
                        setTimeout(() => {
                            that.$refs.table.keyupVip()
                        },300)
                    } else if(that.takeStockVisible == true && that.newCountVisible == false && that.chooseShopVisible == false) {
                        that.keynum = 38
                        setTimeout(() => {
                            that.$refs.takeStock.keyupVip()
                        },300)
                    } else if(that.newCountVisible == true && that.chooseShopVisible == false) {
                        that.keynum = 38
                        setTimeout(() => {
                            that.$refs.newCount.keyupVip()
                        },300)
                    } else if(that.chooseShopVisible == true) {
                        that.keynum = 38
                        setTimeout(() => {
                            that.$refs.chooseShop.keyupVip()
                        },300)
                    } else if(that.orderVisible == true) {
                        that.keynum = 38
                        setTimeout(() => {
                            that.$refs.order.keyupVip()
                        },300)
                    } else if(that.receiptVisible == true) {
                        that.keynum = 38
                        setTimeout(() => {
                            that.$refs.receipt.keyupVip()
                        },300)
                    }  else if(that.shopVisible == true) {
                        that.keynum = 38
                        setTimeout(() => {
                            that.$refs.shop.keyupVip()
                        },300)
                    }  else  if(that.inventoryVisible == true) {
                        that.keynum = 38
                        setTimeout(() => {
                            that.$refs.inventory.keyupVip()
                        },300)
                    } else if(that.takeOrderVisible == true) {
                        that.keynum = 38
                        setTimeout(() => {
                            that.$refs.takeOrder.keyupVip()
                        },300)
                    }  else if(that.detailsVisible == true) {
                        that.keynum = 38
                        setTimeout(() => {
                            that.$refs.details.keyupVip()
                        },300)
                    } else if(that.vipCardVisible == true) {
                        that.keynum = 38
                        setTimeout(() => {
                            that.$refs.vipCard.keyupVip()
                        },300)
                    } else if(that.settlementVisible == true) {
                        that.keynum = 38
                        setTimeout(() => {
                            that.$refs.settlement.keyupVip()
                        },300)
                    } else if(that.warehouseVisible == true) {
                        that.keynum = 38
                        setTimeout(() => {
                            that.$refs.warehouse.keyupVip()
                        },300)
                    } else {
                        that.keynum = 38
                        let Detail = {}
                        Detail = that.utils.keyupVipCilck(that.tableDataIndex,that.keynum,-1,-1,that.dataindex,that.dataDetail)
                        that.dataindex = Detail.dataindex
                        that.$axios.get('api/pos_client/base/get_goods_stock',{
                            params:{
                                ware_code:that.tableDataIndex[that.dataindex].ware_code,
                                goods_code:that.tableDataIndex[that.dataindex].goods_code,
                                goods_item_id:that.tableDataIndex[that.dataindex].goods_item_id,
                            }}).then(res => {
                                that.tableDataIndex[that.dataindex].stock = res.data.data.stock
                            }
                        )
                        that.tableDataIndex[that.dataindex].dis_real = Number(that.tableDataIndex[that.dataindex].retail_price) - Number(that.tableDataIndex[that.dataindex].real_price)
                        that.tableDataIndex[that.dataindex].dis_real = that.tableDataIndex[that.dataindex].dis_real.toFixed(2)
                        that.dataDetail =  that.tableDataIndex[that.dataindex]
                        that.scrollFileLocation()
                        // const table = that.$refs.IndexTable;
                        // // 拿到表格中承载数据的div元素
                        // const divData = table.bodyWrapper;
                        // if(that.dataindex > 8) {
                        //     if(that.dataindex == that.tableDataIndex.length-1) {
                        //         console.log('111111111111')
                        //         // // 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果(此配置为每1秒移动20像素)
                        //         that.$nextTick(() => {
                        //             that.$refs.IndexTable.bodyWrapper.scrollTop = that.$refs.IndexTable.bodyWrapper.scrollHeight;
                        //         })
                        //     } else {
                        //         divData.scrollTop -= 70
                        //     }
                        // }else if (that.dataindex < 8 ) {
                        //     divData.scrollTop = 0;
                        // }
                    }
                } else if (keyCode == 40) { //下
                    if(that.vipVisible == true) {
                        that.keynum = 40
                        setTimeout(() => {
                            that.$refs.table.keyupVip()
                        },300)
                    } else if(that.orderVisible == true) {
                        that.keynum = 40
                        setTimeout(() => {
                            that.$refs.order.keyupVip()
                        },300)
                    } else if(that.receiptVisible == true) {
                        that.keynum = 40
                        setTimeout(() => {
                            that.$refs.receipt.keyupVip()
                        },300)
                    } else if(that.takeStockVisible == true && that.newCountVisible == false && that.chooseShopVisible == false) {
                        that.keynum = 40
                        setTimeout(() => {
                            that.$refs.takeStock.keyupVip()
                        },300)
                    } else if(that.newCountVisible == true && that.chooseShopVisible == false) {
                        that.keynum = 40
                        setTimeout(() => {
                            that.$refs.newCount.keyupVip()
                        },300)
                    }  else if(that.chooseShopVisible == true) {
                        that.keynum = 40
                        setTimeout(() => {
                            that.$refs.chooseShop.keyupVip()
                        },300)
                    }  else if(that.takeOrderVisible == true) {
                        that.keynum = 40
                        setTimeout(() => {
                            that.$refs.takeOrder.keyupVip()
                        },300)
                    }  else if(that.detailsVisible == true) {
                        that.keynum = 40
                        setTimeout(() => {
                            that.$refs.details.keyupVip()
                        },300)
                    } else  if(that.shopVisible == true) {
                        that.keynum = 40
                        setTimeout(() => {
                            that.$refs.shop.keyupVip()
                        },300)
                    } else  if(that.inventoryVisible == true) {
                        that.keynum = 40
                        setTimeout(() => {
                            that.$refs.inventory.keyupVip()
                        },300)
                    } else if(that.vipCardVisible == true) {
                        that.keynum = 40
                        setTimeout(() => {
                            that.$refs.vipCard.keyupVip()
                        },300)
                    }  else if(that.settlementVisible == true) {
                        that.keynum = 40
                        setTimeout(() => {
                            that.$refs.settlement.keyupVip()
                        },300)
                    } else if(that.warehouseVisible == true) {
                        that.keynum = 40
                        setTimeout(() => {
                            that.$refs.warehouse.keyupVip()
                        },300)
                    } else {
                        that.keynum = 40
                        let Detail = {}
                        Detail = that.utils.keyupVipCilck(that.tableDataIndex,that.keynum,-1,-1,that.dataindex,that.dataDetail)
                        that.dataindex = Detail.dataindex
                        that.$axios.get('api/pos_client/base/get_goods_stock',{
                            params:{
                                ware_code:that.tableDataIndex[that.dataindex].ware_code,
                                goods_code:that.tableDataIndex[that.dataindex].goods_code,
                                goods_item_id:that.tableDataIndex[that.dataindex].goods_item_id,
                            }}).then(res => {
                                that.tableDataIndex[that.dataindex].stock = res.data.data.stock
                            }
                        )
                        that.tableDataIndex[that.dataindex].dis_real = Number(that.tableDataIndex[that.dataindex].retail_price) - Number(that.tableDataIndex[that.dataindex].real_price)
                        that.tableDataIndex[that.dataindex].dis_real = that.tableDataIndex[that.dataindex].dis_real.toFixed(2)
                        that.dataDetail = that.tableDataIndex[that.dataindex]
                        that.scrollFileLocation()
                        // const table = that.$refs.IndexTable;
                        // // 拿到表格中承载数据的div元素
                        // const divData = table.bodyWrapper;
                        // if(that.dataindex > 8) {
                        //     // // 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果(此配置为每1秒移动20像素)
                        //     divData.scrollTop += 100;
                        // } else if (that.dataindex<8) {
                        //     divData.scrollTop = 0;
                        // }

                    }
                } else if (keyCode == 13) {//enter
                    if(that.selectionVisible == true) {
                        that.$refs.selection.selectionEnter()
                    }
                    if(that.receiptVisible == true) {
                        that.$refs.receipt.receiptEnter()
                    }
                    if(that.retailReturnVisible == true) {
                        that.$refs.retailReturn.retailReturnEnter()
                    }
                    if(that.chooseShopVisible == true) {
                        that.$refs.chooseShop.chooseShopEnter()
                    }
                    if(that.takeOrderVisible == true) {
                        that.$refs.takeOrder.takeOrderEnter()
                    }
                    if(that.storedVisible == true) {
                        if(that.settlementF.amount !=='' && that.settlementF.card_code !=='') {
                            that.$refs.stored.storedEnter()
                            // that.$refs.settlement.inputFocus()
                        }
                    }
                    if(that.warehouseVisible == true) {
                        that.$refs.warehouse.warehouseEnter()
                        that.inputFocus()
                    }
                    if(that.topUpVisible == true) {
                        that.$refs.topUp.topUpEnter()
                        that.inputFocus()
                    }
                    if(that.vipCardVisible == true) {
                        that.$refs.vipCard.vipCardEnter()
                        that.inputFocus()
                    }
                    if(that.hairpinVisible == true) {
                        that.$refs.hairpin.hairpinEnter()
                        that.inputFocus()
                    }
                    if(that.vipVisible == true) {
                        that.$refs.table.vipEnter()
                        that.VipClose()
                        that.inputFocus()
                    }
                    if(that.shopVisible == true) {
                        that.$refs.shop.shopEnter()
                        that.shopClose()
                        that.inputFocus()
                    }
                    if(that.priceVisible == true) {
                        that.$refs.price.priceEnter()
                        that.PriceClose()
                        that.inputFocus()
                    }
                    if(that.numberVisible == true) {
                        that.$refs.number.numberEnter()
                        that.NumberClose()
                        that.inputFocus()
                    }
                    if(that.discountVisible == true) {
                        that.$refs.discount.discountEnter()
                        that.discountClose()
                        that.inputFocus()
                    }
                    if(that.reqlpriceVisible == true) {
                        that.$refs.reqlprice.reqlpriceEnter()
                        that.reqlpriceClose()
                        that.inputFocus()
                    }
                    if(that.singleVisible == true) {
                        that.$refs.singleRemark.singleEnter()
                        that.singleClose()
                        that.inputFocus()
                    }
                }

            }
        },
        /**
         * table 滚动条
         * */
        scrollFileLocation() {
            // 整个table的高度
            const tableHeight = this.$refs.content_center.offsetHeight
            // console.log('整个table的高度',tableHeight)
            // table表格的头高度
            const tableHeader = this.$refs.IndexTable.$refs.bodyWrapper.children[0].children[1].children[0].clientHeight
            // console.log('table表格的头高度',tableHeader)
            const tableBodyHeight = tableHeight - tableHeader;
            // console.log('整个table内容高度',tableBodyHeight)
            // 每个table中元素的高度
            const itemHeight = this.$refs.IndexTable.$refs.bodyWrapper.children[0].children[1].children[0].clientHeight
            // console.log("每个table中元素的高度",itemHeight)
            this.$nextTick(() => {
                // 一个窗口可以展示的数据数量
                const showNum = tableBodyHeight / itemHeight;
                // console.log('一个窗口可以展示的数据数量',showNum)
                // 当前滚动条已经滚动的高度
                let scrolled = this.$refs.IndexTable.bodyWrapper.scrollTop;
                // console.log('当前滚动条已经滚动的高度',scrolled)
                // 获取当前窗口第一个元素的下标
                const start = Math.round(scrolled / itemHeight);
                // console.log('获取当前窗口第一个元素的下标',start)
                // 获取当前窗口最后一个元素的下标
                const end = Math.round(start + showNum) - 1;
                // console.log('获取当前窗口最后一个元素的下标',end)
                // 为了文件完整显示的偏移量
                let offSet = 0;
                // 如果滚动条移动的很远再按上下键会重新定位
                if (start - this.dataindex > 1) {
                    this.$refs.IndexTable.bodyWrapper.scrollTop = this.dataindex * itemHeight;
                    return;
                }
                if (this.dataindex - end > 1) {
                    this.$refs.IndexTable.bodyWrapper.scrollTop = (this.dataindex - showNum + 1) * itemHeight;
                    return;
                }
                // 如果上下选择文件大于窗口则移动滚动条
                if (this.dataindex < start) {
                    offSet = scrolled % itemHeight;
                    scrolled -= itemHeight + offSet;
                    // console.log('this.dataindex < start',scrolled)
                } else if (this.dataindex > end) {
                    offSet = (scrolled + itemHeight * showNum) % itemHeight;
                    scrolled += 50;
                    // console.log('this.dataindex > end',scrolled)
                } else {
                    return;
                }
                // console.log('end',scrolled)
                this.$refs.IndexTable.bodyWrapper.scrollTop = scrolled;
            });
        },
        /**
         * 显示当前时间（年月日时分秒）
         * */
        timeFormate(timeStamp) {
            let year = new Date(timeStamp).getFullYear();
            let month =new Date(timeStamp).getMonth() + 1 < 10? "0" + (new Date(timeStamp).getMonth() + 1): new Date(timeStamp).getMonth() + 1;
            let date =new Date(timeStamp).getDate() < 10? "0" + new Date(timeStamp).getDate(): new Date(timeStamp).getDate();
            let hh =new Date(timeStamp).getHours() < 10? "0" + new Date(timeStamp).getHours(): new Date(timeStamp).getHours();
            let mm =new Date(timeStamp).getMinutes() < 10? "0" + new Date(timeStamp).getMinutes(): new Date(timeStamp).getMinutes();
            let ss =new Date(timeStamp).getSeconds() < 10? "0" + new Date(timeStamp).getSeconds(): new Date(timeStamp).getSeconds();
            this.nowTime = year + "-" + month + "-" + date +""+" "+hh+":"+mm+':'+ss ;
        },
        nowTimes(){
            this.timeFormate(new Date());
            setInterval(this.nowTimes,1000);
            this.clear()
        },
        clear(){
            clearInterval(this.nowTimes)
            this.nowTimes = null;
        },
        tableRowClassName({ row, rowIndex }) {
            row.index = rowIndex;
        },
        InterfaceList() {
            this.$vLoading.show();
            this.$axios.post('api/kuerp/order/index/goodsGoodsList', this.$qs.stringify(
                this.form
            )).then(res => {
                if(res.data.code == 200) {
                    this.$vLoading.hide();
                }

            })
        },
    },
}
</script>
<style>

</style>
<style lang="stylus">
.top_left, .top_right {
    height: (56/96)rem;
    display: flex;
    align-items: center;
    .img {
        width: (22/96)rem;
        height: (22/96)rem;
        cursor: pointer;
    }
}
.top {
    position: relative;
    height: (55/96)rem;
    background: #2F302E;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 (40/96)rem 0 (80/96)rem;
}
.top_left .top_left_logo {
    width: (263/96)rem;
    height:(37/96)rem;
    cursor: pointer;
    //img {
    //    width: (263/96)rem;
    //    height:(37/96)rem;
    //}
}
.login-content-tab {
    display: flex;
    color: #FFFFFF;
    font-weight: 600;
    font-size (18/96)rem
    margin-left: (80/96)rem;
    >div {
        margin-right: (30/96)rem;
    }
}
.mr-10 {
    margin-right: (10/96)rem;
    color: #999999;
}
.shop_box {
    background: #FFFFFF;
    border-radius: (2/96)rem;
    margin-bottom: (10/96)rem;
    padding: 0 0 (24/96)rem 0;
    .shop_box_content {
        >div {
            display: flex;
            font-size: (16/96)rem;
            font-weight: 500;
            color: #999999;
            margin-top: (20/96)rem;
            margin-left: (20/96)rem;
            >div:nth-child(2) {
                color: #333333;
                margin-left: (20/96)rem;
            }
        }
    }
    .shop_box_title {
        background: #999999 linear-gradient(180deg, #444643 0%, #0C0C0C 100%);
        font-size: (18/96)rem;
        font-weight: 600;
        color: #FFFFFF;
        justify-content: space-between;
        padding: (16/96)rem 0;
        >div{
            width: 50%;
            text-align: center;
            //border-right: 1px solid #979797;
            cursor: pointer;
        }
        >div:nth-child(2) {
            border: none!important;
        }
    }
}
.member_box {
    padding: (15/96)rem;
    background: #FFFFFF;
    border-radius: (2/96)rem;
    margin-bottom: (10/96)rem;
    .member_box_button {
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: (15/96)rem;
        >div {
            width: 49%;
            height: (40/96)rem;
            display: flex;
            align-items: center;
            background: #F6FBFF;
            border-radius: (2/96)rem;
            justify-content: center;
            font-size: (16/96)rem;
            font-weight: 400;
            color: #333333;
            margin-bottom: (5/96)rem;
            cursor: pointer;
            img {
                margin-left: (5/96)rem;
            }
        }
    }
    .member_box_ziliao {
        img {
            width: (55/96)rem;
            height: (55/96)rem;
            margin-right: (17/96)rem;
            border-radius: 50%;
        }
        font-size: (20/96)rem;
        font-weight: 500;
        color: #333333;
        display: flex;
        align-items: center;
        margin-bottom: (15/96)rem;
        .member_box_ziliao_type {
            font-size: (14/96)rem;
            font-weight: 500;
            color: #999999;
            margin-left: (10/96)rem;
            padding-left: (10/96)rem;
            border-left: (1/96)rem solid #999999;
        }
        .cardImage_box {
            display: flex;
            align-items: center;
            font-size: (12/96)rem;
            font-weight: 400;
            color: #999999;
            margin-top: (5/96)rem;
            img {
                width: (17/96)rem;
                height: (12/96)rem;
                margin-right: (10/96)rem;
                border-radius: 0;
            }
        }
    }
    .member_box_title {
        >div {
            width: 33%;
            text-align: center;
            font-size:(20/96)rem;
            font-weight: 600;
            color: #333333;
            border-right: (1/96)rem solid #E5E5E5;
            >div:nth-child(2) {
                font-size: (11/96)rem;
                font-weight: 400;
                color: #999999;
            }

        }
        >div:nth-child(3) {
            border: none!important;
        }
    }
}
.menu {
    width: 100%;
    height: 100%;
    .button {
        width: 100%;
        background: #4B83F0;
        border-radius: (2/96)rem;
        font-size: (20/96)rem;
        font-weight: 500;
        color: #FFFFFF;
        margin-top: (12/96)rem;
        text-align:center;
        padding :(15/96)rem 0 ;
    }
}

.grid-content {
    background: #FFFFFF;
    border-radius: (2/96)rem;
    padding: (20/96)rem (12/96)rem (12/96)rem (15/96)rem;
    img {
        width: (25/96)rem;
        height: (25/96)rem;
        margin-right: (11/96)rem;
        margin-left: (22/96)rem;
    }
    .accounts {
        width: 100%;
        font-size: (20/96)rem;
        font-weight: 600;
        color: #333333;
        margin-bottom: (20/96)rem;
    }
    .pay {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .pay_but {
        width: (162/96)rem;
        height: (55/96)rem;
        cursor:pointer;
        background-color: #F6FBFF;
        border-radius: (2/96)rem;
        font-size: (18/96)rem;
        font-weight: 400;
        color: #333333;
        display: flex;
        align-items: center;
        margin-bottom: (5/96)rem;
    }
}
.search_box {
    background: #FFFFFF;
    border-radius: (2/96)rem;
    display: flex;
    align-items: center;
    padding: 0 (15/96)rem;
    margin: (10/96)rem 0;
    img {
        width: (18/96)rem;
        height: (18/96)rem;
    }
    ::v-deep {
        .el-input__inner {
            height: (48/96)rem;
        }
        .el-input {

        }
    }
}
.one_box {
    background: #FFFFFF;
    border-radius: (2/96)rem;
    margin-top: (8/96)rem;
    padding: (15/96)rem (10/96)rem;
    font-size: (14/96)rem;
    .top_text {
        font-size: (20/96)rem;
        font-weight: 500;
        color: #333333;
    }
    .text {
        width: 50%;
        margin-top: (12/96)rem;
        display: flex;
    }
}
.content_bottom_event {
    margin: (10/96)rem 0;
    position: relative;
    font-size:(16/96)rem;
    .content_bottom_other {
        padding: (10/96)rem  0 0 (10/96)rem;
        width: (940/96)rem;
        position: absolute;
        bottom: (60/96)rem;
        right: 0;
        background: #EEF4F9;
        border-radius: (2/96)rem;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: flex-start;
        .content_bottom_event_box {
            margin-right: (12/96)rem;
            margin-bottom: (10/96)rem;
        }

    }
    .content_bottom_event_box {
        width:(104/96)rem;
        //padding: 0.1rem 0.33rem;
        padding: (10/96)rem 0;
        height: (60/96)rem;
        background: #FFFFFF;
        border-radius:(2/96)rem;
        //text-align: center;
        cursor: pointer;
        display: flex;
        //align-items: flex-start;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        //align-content:flex-start;
        >div:nth-child(1) {
            width: 100%;
            //margin-top: 10px;
        }
        >div:nth-child(2) {
            //margin-top: 2px;
        }
        >div {
            text-align: center;
        }
    }
}
.content_bottom {
    background: #8CA0B3;
    border-radius: (2/96)rem;
    padding: (10/96)rem (25/96)rem;
    display: flex;
    justify-content: space-between;
    font-size: (14/96)rem;
    font-weight: 600;
    color: #FFFFFF;
    margin-top: (10/96)rem;
}

.content_top {
    margin: (5/96)rem 0;
    .content_top_box {
        width: 24%;
        text-align: center;
        padding: (11/96)rem 0;
        background: #FFFFFF;
        border-radius:(2/96)rem;
        .content_top_box_title {
            font-size: (20/96)rem;
            font-weight: 400;
            color: #666666;
            margin-top: (15/96)rem;
        }
        .content_top_box_value {
            font-size: (45/96)rem;
            font-weight: bold;
            color: #4B83F0;
        }
    }
}
</style>
<style scoped lang="scss">
.content_top {
    @extend .flex-between;
}
.content_bottom_event {
    @extend .flex-between;
}
.layout {
    width: 100vw;
    height: 100vh;
    background: #EEF4F9;

}
.content_center {
    //height: 75%;
    background: #FFFFFF;
    height: 7.86rem;
    //overflow-y: scroll;
    ::v-deep {
        .el-table {
            height: 7.86rem;
            overflow-y: auto;
            .el-button {
                padding: 0!important;
                border: none!important;
            }
        }
        .el-table::before {
            height: 0!important;
        }
        .el-table__cell {
            padding: 0.1rem!important;
        }
        .el-table .cell {
            //max-height: 0.2rem;
            line-height: 0.2rem;
            font-size: 0.14rem;
            overflow: hidden; //超出的文本隐藏
            display: -webkit-box;
            -webkit-line-clamp: 1; // 超出多少行
            -webkit-box-orient: vertical;
        }
    }
}
::v-deep {
    .el-table::before {
        height: 0!important;
    }
    .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
        background: none;
    }
}
#index {
    width: 100%;
    //height: 95vh;
    display: flex;
    justify-content: space-between;
}

.flex {
    display: flex;
}

.search_box {
    ::v-deep {
        .el-input__inner {
            border: none!important;
        }
        .el-input {

        }
    }
}

.flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.color-dff {
    background-color: #DCEDFF!important;
}
.color-1ff {
    background-color: #FFF1FF!important;
}
.color-ae2 {
    color: #CF7AE2!important;
}

</style>
