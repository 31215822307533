<template>
    <div @click.stop>
        <el-dialog custom-class="dialog"
                   :close-on-click-modal = "false"
                   :destroy-on-close="true"
                   :close-on-press-escape="true"
                   title=""
                   :visible.sync="Visible"
                   ref="dialog"
                   @close="remarkClose"
                   width="30vw"
                   top="12vw"
        >
            <div slot="title" class="new-evaluationTitle">
                {{title_text}}
            </div>
            <div class="new-evaluation">
                <el-form :model="queryParams" @submit.native.prevent>
                    <el-form-item label="备注">
                        <el-input v-model="queryParams.remark" ref="inputSingle" @keyup.enter.native="remarkEnter"></el-input>
                    </el-form-item>
<!--                    <el-button type="primary" class="cardbutton" @click="cardclick()">读IC卡</el-button>-->
                </el-form>

            </div>
            <div slot="footer" class="dialog-footer padding-30 no-padding-left no-padding-right no-padding-top">
                <div class="button_box">
                    <el-button type="primary" @click="remarkEnter">确认(Enter)</el-button>
                    <el-button type="warning" style="background: #FFFFFF;border-color: #E9E9E9;color: #666666;"  @click="remarkClose">取消(Esc)</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "singleRemark",
    props:{
        /**
         * 弹框点开关闭
         * */
        remarkVisible: {
            default: false,
            type: Boolean
        },
        /**
         * 头部文字
         * */
        title_text: {
            default: '请输入备注',
            type: String
        },
        /**
         * 备注文字
         * */
        remark: {
            default: '',
            type: String
        },
    },
    data() {
        return {
            queryParams:{
                remark:''
            },
            Visible:false

        }
    },
    watch:{
        remarkVisible(newVal,oldVal) {
            this.Visible = newVal
        }
    },
    methods: {
        assignment() {
            this.queryParams.remark = this.remark
        },
        /**
         * 输入框获取焦点
         * */
        inputFocus() {
            //获取输入框焦点
            this.$nextTick(() => {
                this.$refs.inputSingle.focus();
            })
        },
        /**
         * 输入框失去焦点
         * */
        inputBlurs() {
            //获取输入框焦点
            this.$nextTick(() => {
                this.$refs.inputSingle.blur();
            })
        },
        /**
         * 弹框关闭
         * */
        remarkClose() {
            this.$parent.remarkClose();
        },
        /**
         * 输入款回车事件 确认事件
         * */
        remarkEnter() {
            this.$emit('remarkClick', this.queryParams.remark);
            this.inputBlurs()
        },
    }
}
</script>

<style scoped lang="scss">
::v-deep {
    .el-dialog__footer {
        text-align: center;
    }
    .el-dialog__header {
        border-bottom: 0.01rem solid #E9E9E9;
    }
    .el-input {
        width: 4rem;
    }
    .el-form-item {
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }
    .el-form {
        padding: 0 0.3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

}
.cardbutton {
    background: linear-gradient(180deg, #2F302E 0%, #606060 100%);
    border-radius: 0.05rem;
    border: none;
}
</style>
