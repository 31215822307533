<template>
    <div @click.stop>
        <el-dialog custom-class="dialog"
                   :destroy-on-close="true"
                   :close-on-click-modal = "false"
                   title=""
                   :visible.sync="settlementVisible"
                   ref="dialog"
                   :before-close="settlementClose"
                   :close-on-press-escape="false"
                   width="40vw"
                   top="3vw"
        >
            <div slot="title" class="new-evaluationTitle">
                {{title_text}}
            </div>
            <div class="new-evaluation">
                <div class="event_box">
                    <div v-for="(item,index) in eventList" @click="eventClick(item)" :key="index" class="eventBox" :class="payment_type == item.num?'background':'backgroundnone'">
                        <div>{{item.text}}</div>
                        <div>{{item.id}}</div>
                        <img src="../../assets/images/gou.png" v-if="payment_type == item.num">
                    </div>
                    <div class="button" @click="settlement">结算</div>
                </div>
                <div class="money_box">
                   <div class="flex text_title">
                       <div>金额输入</div>
                       <div>当前收银方式：{{ payment_text }}</div>
                   </div>
                    <div>
                        <el-input type="text" ref="inputSettlement" @input="allNumber()"  v-model="amount" @focus="elFocus" @blur="elBlur" @keyup.enter.native="InEnter"></el-input>
                    </div>
                    <div class="flex text_title " style="margin-top: 0.2rem">
                        <div>优惠金额</div>
                    </div>
                    <div>
                        <el-input type="text" @input="allNumbeReducedr()"  v-model="amount_reduced"  @blur="elBlurReduced" @keyup.enter.native="elBlurReduced"></el-input>
                    </div>
                </div>
                <div class="shou_box">
                    <div style="padding: 0.25rem;font-size: 0.16rem;font-weight: 500;color: #333333;">收款信息</div>
                    <div class="flex text_box" style="background: #F6FBFF;">
                        <div class="flex">
                            <div>合计金额：</div>
                            <div>￥{{ amount_real }}</div>
                        </div>
                        <div class="flex text_color">
                            <div>已收金额：</div>
                            <div>￥{{ received }}</div>
                        </div>
                    </div>
                    <div class="flex text_box">
                        <div class="flex">
                            <div>未收金额：</div>
                            <div>￥{{ surplus_real }}</div>
                        </div>
                        <div class="flex text_color">
                            <div>找零金额：</div>
                            <div>￥{{ zhaoling_amount }}</div>
                        </div>
                    </div>
                </div>
                <div>
                    <el-table
                        style="width: 100%"
                        class=""
                        stripe
                        ref="multipleTabless"
                        :data="tableData"
                        :row-style="rowStyle"
                        @row-click="handledbsupplier"
                        :row-class-name="tableRowClassName"
                    >
                        <el-table-column v-for="item in tableTitle" :key="item.name" :prop="item.value" :label="item.name"
                                         :width="item.width" :align="item.align || 'left'  " :formatter="item.formatter">
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div slot="footer" >
                <div class="flex text">
                    <div>录入快捷键可切换收银方式</div> <div>Delete键可删除选中的收款信息</div>
                </div>
            </div>
<!--            <div slot="footer" class="dialog-footer padding-30 no-padding-left no-padding-right no-padding-top">-->
<!--                <el-button type="primary" @click="settlementEnter">确认(Enter)</el-button>-->
<!--                <el-button type="warning" style="background: #FFFFFF;border-color: #E9E9E9;color: #666666;"  @click="settlementClose">取消(Esc)</el-button>-->
<!--            </div>-->
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "settlement",
    props:{
        /**
         * 弹框点开关闭
         * */
        settlementVisible: {
            default: false,
            type: Boolean
        },
        /**
         * 头部文字
         * */
        title_text: {
            default: 'POS收银结算',
            type: String
        },
        /**
         * 状态
         * */
        returnGoods: Number,
        /**
         * 结算方式
         */
        payment_type: Number,
        /**
         * 应收金额
         */
        amount_real:Number,
        /**
         * 结算文字
         * */
        payment_text: {
            default: '微信',
            type: String
        },
        /**
         * 键盘事件
         * */
        keynum: Number,
        /**
         *  储值卡值
         * */
        serrlementObj: Object,

    },
    data() {
        return {
            real_price:'',
            eventList:[
                {
                    id:'A',
                    text:'现金',
                    num:1
                },
                {
                    id:'S',
                    text:'银行卡',
                    num:2
                },
                {
                    id:'W',
                    text:'储值卡',
                    num:3
                },
                {
                    id:'D',
                    text:'积分',
                    num:6
                },
                {
                    id:'V',
                    text:'微信',
                    num:4
                },
                {
                    id:'E',
                    text:'支付宝',
                    num:5
                }

            ],
            tableTitle: [
                {
                    name: '行号',
                    value: 'index',
                    width:'100',
                    formatter: (row) => {
                        return row.index+1;
                    }
                },
                {
                    name: '付款方式',
                    value: 'payment_text',
                    width:'200'
                },
                {
                    name: '付款金额',
                    value: 'amount',
                    width:'200'
                },
                {
                    name: '卡号/券号',
                    value: 'card_code',
                },
            ],
            tableData: [],
            amount:'',
            dataDetail:'',// 当前已选择行
            dataindex: 0, // 当前选择行的index
            received:0,
            zhaoling_amount:0,
            surplus_real:0,
            amount_reduced:0

        }
    },
    methods: {
        /**
         * 进页面重新赋值
         * */
        assignment() {
            this.amount = this.amount_real
            this.dataindex = 0
            this.received = 0
            this.zhaoling_amount = 0
            this.surplus_real = 0
            this.tableData = []
            this.amount_reduced = 0
            this.$emit('surplusClick', this.surplus_real);
        },
        addchuzhi() {

            let bool = false
            for (let i = 0; i < this.tableData.length; i++) {
                if (this.tableData[i].payment_type == this.serrlementObj.payment_type) {
                    bool = true
                }
            }
            if(bool) {
                this.tableData.map((item,index) => {
                    if(item.payment_type == this.serrlementObj.payment_type) {
                        item.amount = Number(item.amount)+Number(this.serrlementObj.amount)
                    }
                })
            } else {
                let a = {}
                a.amount = this.serrlementObj.amount
                a.payment_type = this.serrlementObj.payment_type
                a.payment_text = this.serrlementObj.payment_text
                a.card_code = this.serrlementObj.card_code
                this.tableData.push(a)
            }
            // console.log('this.tableData',this.tableData)
            this.received = 0
            this.tableData.map((item,index) => {
                this.received = Number(item.amount) +  Number(this.received)
            })
            if( this.received > (this.amount_real - Number(this.amount_reduced)) ) {
                this.surplus_real = 0
                this.zhaoling_amount = Number(this.received) -  Number( this.amount_real ) - Number(this.amount_reduced)
            } else if (this.received < (this.amount_real - Number(this.amount_reduced))) {
                this.zhaoling_amount = 0
                this.surplus_real = Number(this.amount_real) - Number(this.received) - Number(this.amount_reduced)
            }
            this.amount = this.surplus_real
            this.$emit('surplusClick', this.surplus_real);
            this.dataindex = this.tableData.length-1
            this.$forceUpdate()
            if (this.received == (this.amount_real-Number(this.amount_reduced))) {
                this.settlement()
            }
        },
        allNumber() {
            this.amount = this.utils.onlyNumOnePoint(this.amount);
        },
        allNumbeReducedr() {
            this.amount_reduced = this.utils.onlyNumOnePoint(this.amount_reduced);
        },
        /**
         * 输入框获取焦点
         * */
        inputFocus() {
            //获取输入框焦点
            this.$nextTick(() => {
                this.$refs.inputSettlement.focus();
            })
        },
        /**
         * 输入框失去焦点
         * */
        inputBlurs() {
            //获取输入框焦点
            this.$nextTick(() => {
                this.$refs.inputSettlement.blur();
            })
        },
        elBlurReduced() {
            if( this.received > (this.amount_real-Number(this.amount_reduced)) ) {
                this.surplus_real = 0
                this.zhaoling_amount = Number(this.received) -  Number( this.amount_real ) - Number(this.amount_reduced)
            } else if (this.received <=  (this.amount_real-Number(this.amount_reduced))) {
                this.zhaoling_amount = 0
                this.surplus_real = Number(this.amount_real) - Number(this.received) - Number(this.amount_reduced)
            }
            this.amount = ''
            // console.log(this.received,(this.amount_real+Number(this.amount_reduced)))
            if (this.amount_real == (this.received+Number(this.amount_reduced))) {

                this.settlement()
            }
            this.amount = this.surplus_real
            this.$emit('surplusClick', this.surplus_real);
        },
        //del
        del() {
            this.tableData.splice(this.dataindex, 1)
            this.dataindex = 0
            this.received = 0
            this.tableData.map((item,index) => {
                this.received = Number(item.amount) +  Number(this.received)
            })
            if( this.received > (this.amount_real-Number(this.amount_reduced)) ) {
                this.surplus_real = 0
                this.zhaoling_amount = Number(this.received) -  Number( this.amount_real ) - Number(this.amount_reduced)
            } else if (this.received <=  (this.amount_real-Number(this.amount_reduced))) {
                this.zhaoling_amount = 0
                this.surplus_real = Number(this.amount_real) - Number(this.received) - Number(this.amount_reduced)
            }
            this.amount = this.surplus_real
            this.$emit('surplusClick', this.surplus_real);
        },
        /**
         * 结算按钮
         * */
        settlement() {
            if(this.received == 0) {
                this.$message.error('收款金额为零!');
                return;
            }
            if(this.received < (this.amount_real - Number(this.amount_reduced))) {
                this.$message.error('未全部支付!');
                return;
            }
            // console.log('this.tableData.length',this.tableData.length,this.tableData[0])
            if (this.tableData.length == 1 && this.tableData[0].payment_type == 1) {
                let e = {
                    list:this.tableData,
                    zhaoling_amount:this.zhaoling_amount,
                    received:this.received,
                    amount_real:this.amount_real,
                    amount_reduced:this.amount_reduced
                }
                this.$emit('settlementClick', e);
            } else {
                if(this.zhaoling_amount > 0) {
                    this.$message.error('收付总额-找零  不能大于单据总额!');
                } else {
                    let e = {
                        list:this.tableData,
                        zhaoling_amount:this.zhaoling_amount,
                        received:this.received,
                        amount_real:this.amount_real,
                        amount_reduced:this.amount_reduced
                    }
                    this.$emit('settlementClick', e);
                }
            }


        },
        keyupVip() {
            let Detail = {}
            Detail = this.utils.keyupVipCilck(this.tableData,this.keynum,-1,-1,this.dataindex,this.dataDetail)
            this.dataindex = Detail.dataindex
            this.dataDetail = Detail.dataDetail
        },
        //改变表格选中某一行    的颜色
        rowStyle(row) {
            if(row.rowIndex == this.dataindex){
                let rowBackground = {}
                rowBackground.background = '#DCEDFF'
                return rowBackground
            }
        },
        tableRowClassName({ row, rowIndex }) {
            row.index = rowIndex;
        },
        //选择某一行
        handledbsupplier(row) {
            // console.log('row',row)
            this.dataDetail=row
            this.dataindex=row.index
        },
        elFocus() {
            this.$parent.keyDown();
        },
        elBlur() {
            this.$parent.keyDown();
        },

        /**
         * 弹框关闭
         * */
        settlementClose() {
            this.$parent.settlementClose();
        },
        /**
         * 输入款回车事件 确认事件
         * */
        // settlementEnter() {
        //     this.$emit('settlementClick', this.real_price);
        //     this.real_price = ''
        //     this.inputFocus
        // },
        /**
         * 输入框回车事件
         * */
        InEnter() {
            if(this.payment_type == 3 || this.payment_type == 6) {

            } else {
                this.$parent.keyDown();
                let bool = false
                for (let i = 0; i < this.tableData.length; i++) {
                    if (this.tableData[i].payment_type == this.payment_type) {
                        bool = true
                    }
                }
                if(bool) {
                    this.tableData.map((item,index) => {
                        if(item.payment_type == this.payment_type) {
                            item.amount = Number(item.amount)+Number(this.amount)
                        }
                    })
                } else {
                    let a = {}
                    a.amount = this.amount
                    a.payment_type = this.payment_type
                    a.payment_text = this.payment_text
                    a.card_code = ''
                    this.tableData.push(a)
                }
                this.received = 0
                this.tableData.map((item,index) => {
                    this.received = Number(item.amount) +  Number(this.received)
                })
                if( this.received >  (this.amount_real-Number(this.amount_reduced)) ) {
                    this.surplus_real = 0
                    this.zhaoling_amount = Number(this.received) -  Number( this.amount_real ) - Number(this.amount_reduced)
                } else if (this.received <=  (this.amount_real-Number(this.amount_reduced))) {
                    this.zhaoling_amount = 0
                    this.surplus_real = Number(this.amount_real) - Number(this.received) - Number(this.amount_reduced)
                }
                this.amount = ''
                this.dataindex = this.tableData.length-1
                if (this.received == (this.amount_real- Number(this.amount_reduced))) {
                    this.settlement()
                }
                this.amount = this.surplus_real
                this.$emit('surplusClick', this.surplus_real);
            }

        },
        /**
         * event点击
         * */
        eventClick(item) {
            this.$emit('eventsettlementClick', item);
            // this.payment_type = item.num
        },
    }
}
</script>

<style scoped lang="scss">
::v-deep {
    .el-table th.el-table__cell > .cell {
        font-size: 0.16rem;
        font-weight: 500;
        color: #333333;
    }
    .dialog {
        background: #F1F1F1;
        height: 10rem;
    }
    .el-table {
        height: 3.36rem;
        background-color: #FFF;
        overflow-y: auto;
    }
    .el-dialog__footer {
        padding: 0.01rem!important;
        font-size: 0.16rem;
        .text {
            justify-content: center;
            >div:nth-child(1) {
                margin-right: 0.1rem;
            }
        }
    }
}
.shou_box {
    //padding: 25px;
    background: #FFFFFF;
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
    .text_box {
        justify-content: space-between;
        padding:0.2rem 0.25rem;
        font-size: 0.14rem;
        font-weight: 400;
        color: #333333;
    }
    .text_color{
        font-size: 0.14rem;
        font-weight: 400;
        color: #4B83F0;
    }
}
.money_box {
    padding: 0.25rem;
    background: #FFFFFF;
    margin-top: 0.1rem;
    ::v-deep {
        .el-input {
            width: 100%;
            height: 0.55rem;
            .el-input__inner {
                height: 0.55rem;
                border: none;
                border-bottom: 0.01rem solid #E4E4E4;
                font-size: 0.24rem;
                font-weight: 500;
                color: #4B83F0;
            }
            .el-input__inner::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }
        }
    }

    .text_title {
        font-size: 0.16rem;
        font-weight: 500;
        color: #333333;
        align-items: center;
        >div:nth-child(2) {
            font-size: 0.14rem;
            font-weight: 400;
            color: #999999;
            margin-left: 0.1rem;
        }
    }
}
.event_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .eventBox {
        position: relative;
        img {
            position: absolute;
            right: 0;
            top: 0;
        }
    }
    .background {
        border: 0.01rem solid #4B83F0;
        background: #F3F7FF;
        border-radius: 0.05rem;
    }
    .backgroundnone {
        background: #FFFFFF;
    }
    >div {
        width: 0.98rem;
        text-align: center;
        height: 0.65rem;
        background: #FFFFFF;
        border-radius: 0.05rem;
        font-size: 0.14rem;
        font-weight: 500;
        color: #333333;
        >div:nth-child(1) {
            margin-top: 0.14rem;
        }
        >div:nth-child(2) {
            margin-top: 0.02rem;
            color: #999999;
            font-weight: bold;
        }
    }
    .button {
        background: #4B83F0;
        font-size: 0.2rem;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 0.65rem;
    }

}
::v-deep {
    .el-dialog__footer {
        text-align: center;
    }
    .el-dialog__body {
        background: #F1F1F1;
        padding: 0.15rem;
    }
    .el-dialog__header {
        border-bottom: 0.01rem solid #E9E9E9;
        background: #2F302E;
        color: #FFFFFF;
    }
    .el-input {
        width: 4rem;
    }
    .el-form-item {
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }
    .el-form {
        padding: 0 0.3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

}
.cardbutton {
    background: linear-gradient(180deg, #2F302E 0%, #606060 100%);
    border-radius: 0.05rem;
    border: none;
}
</style>
