<template>
    <div>
        <div class="login">
            <div class="logo"><img src="../assets/images/logo.png"></div>
            <div class="login-content">
                <div class="login-content-cen">
                    <!--账号密码登录-->
                    <form class="login-content_form">
                        <div class="text_title">用户名</div>
                        <div class="item">
                            <img src="../assets/images/login_user.png" alt="">
                            <el-input v-model="username" type="text" placeholder="输入账号" @blur="userBlur"></el-input>
                        </div>
                        <div class="text_title">POS/收银台</div>
                        <div class="item" >
                            <img src="../assets/images/login_syt.png" alt="">
                            <el-select v-model="desk_code" placeholder="请选择" style="width: 100%;" @change="marriageChange($event)">
                                <el-option v-for="item in desk_codeArr" :key="item.desk_code" :label="item.desk_name"
                                           :value="item.desk_code" ></el-option>
                            </el-select>
                        </div>
                        <div class="text_title">密码</div>
                        <div class="item">
                            <img src="../assets/images/login_pwd.png" alt="">
                            <el-input v-model="password" show-password type="password" placeholder="输入密码"></el-input>
                        </div>
                        <div class="text_title">登录日期</div>
                        <div class="item">
                            <img src="../assets/images/login_date.png" alt="">
                            <el-date-picker type="date" placeholder="选择日期" style="width:100%" value-format="yyyy-MM-dd"
                                            v-model="in_time" disabled></el-date-picker>
                        </div>
<!--                        <div class="jizhu">-->
<!--                            <el-checkbox v-model="remember">记住账号密码</el-checkbox>-->
<!--                        </div>-->
                        <el-button round class="login-btn" @click="login">立即登录</el-button>
                    </form>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
// var userAgent = navigator.userAgent.toLowerCase()
// if(userAgent.indexOf('electron/')>-1) {
//     console.log('桌面应用')
//     var Store = window.require('electron-store')
//     var storeName = new Store();
// }else {
//     console.log('浏览器')
// }
// import ValidCode from "@/components/code";

export default {
    name    : "Login",
    // components: {
    //     ValidCode
    // },
    data() {
        return {
            num:1,
            username: '',
            password: '',
            // seccode:'',
            // checkCode:'',
            // remember: false,
            // validCode: '',
            in_time:new Date(),
            desk_code:'',
            desk_codeArr:[]
        };
    },
    computed: {},
    watch   : {},
    mounted() {
        // var userAgent = navigator.userAgent.toLowerCase()
        // if(userAgent.indexOf('electron/')>-1) {
        //     if(storeName.has("xlctzkname")){
        //         this.password = storeName.get('xlctzkpassword')
        //         this.username = storeName.get('xlctzkname')
        //         this.remember = true
        //     } else  {
        //         this.remember = false
        //     }
        // }else {
        //     if (this.$store.state.user.account) {
        //         let data      = this.$store.state.user.account;
        //         this.password = data.password;
        //         this.username = data.username;
        //         this.remember = true;
        //     }
        // }
        // this.createCode();

    },
    methods : {
        /**
         * 账号失去焦点
         */
        userBlur() {
            if(this.username !== '') {
                this.getWard()
            }
        },
        /**
         * 收银台接口
         * */
        async getWard() {
            // console.log('this.username',this.username)
            await this.$axios.get('api/pos_client/login/get_user_desk',{params:{
                    username:this.username
                }}).then(res => {
                    // console.log('res',res)
                    let data = res.data.data
                    if(data.desk_codes.length<=0) {
                        this.$message.error('当前用户没有POS/收银台');
                    } else {
                        this.desk_codeArr = data.desk_codes;
                    }
            })
        },
        /**
         * POS/收银台选择
         * */
        marriageChange(e){
            this.desk_code = e
        },
        /**
         * 登录
         * */
        async login() {
            if (!this.username) {
                this.$message.error('请输入用户名');
                return;
            }
            if (!this.password) {
                this.$message.error('请输入密码');
                return;
            }
            if (!this.desk_code) {
                this.$message.error('请选择POS/收银台');
                return;
            }
            // if (!this.seccode) {
            //     this.$message.error('请输入验证码');
            //     this.$refs.code.createdCode()
            //     return;
            // }
            // if(this.seccode.toUpperCase() !==this.validCode.toUpperCase()) {
            //     this.$message.error('验证码错误');
            //     this.$refs.code.createdCode()
            //     return;
            // }
            await this.$axios.post('api/pos_client/login/login', {
                username: this.username,
                password: this.password,
                desk_code :this.desk_code

            }).then(res => {
                if (res.data.code == 200) {
                    // console.log('res',res)
                    this.$store.commit('setToken', res.data.data.user_info.token.token)
                    this.$store.commit('setUserInfo', {data: res.data.data, status: true, username: res.data.data.user_info.username});
                    // console.log('跳转首页')
                    this.$router.push('/Index');
                    // var userAgent = navigator.userAgent.toLowerCase()
                    // if(userAgent.indexOf('electron/')>-1) {
                    //     if (this.remember) {
                    //         //记住密码
                    //         storeName.set("xlctzkname",this.username);
                    //         storeName.set("xlctzkpassword",this.password);
                    //     } else {
                    //         storeName.delete('xlctzkname');
                    //         storeName.delete('xlctzkpassword');
                    //     }
                    // }else {
                    //     if (this.remember) {
                    //         //记住密码
                    //         this.$store.commit('setAccount', {username: this.username, password: this.password});
                    //     } else {
                    //         this.$store.commit('setAccount', '');
                    //     }
                    // }
                }
            })
        },
    },


}
</script>
<style scoped lang="scss">
.login {
    width: 100vw;
    height: 100vh;
    background: url('../assets/images/loginBG.png') no-repeat center;
    background-size: cover;
    overflow: hidden;
}
.logo {
    width: 6rem;
    margin: 0 auto;
    margin-top: 10%;
    img {
        width: 6rem;
        height: 0.875rem;
    }
}
.login-content {
    width: 9.8rem;
    height: 6.3rem;
     margin: 0 auto;
    background: url('../assets/images/logo_content.png') no-repeat center;
    background-size: cover;
    overflow: hidden;
    //transform: translate(-50%, -56%);
}
.login-content-cen {
    width: 4rem;
    margin: 0.83rem 4.7rem;
}
.login-content_form {
    height: 3.2rem;
    .text_title {
        font-size: 0.17rem;
        color: #999999;
        margin-bottom: 0.1rem;
    }
}
.login-content p {
    color: #999999;
    font-size: 0.375rem;
    margin-bottom: 0.31rem;
    text-align: center;
    font-weight: bold;
}

.login-content .item {
    display: flex;
    align-items: center;
    border-radius: 0.02rem;
    height: 0.55rem;
    margin-bottom: 0.2rem;
    padding: 0 0.17rem;
    background: #FFFFFF;
    border: 0.01rem solid #DFDFDF;
}

.login-content .item img {
    width: 0.17rem;
    height: 0.18rem;
}
//.login-content .item .item-code {
//    width: 110px;
//    font-size: 24px;
//    font-weight: 1000;
//    color: #4B83F0;
//    padding:  0 10px;
//    text-align-last: center;
//}
.login-content .item input {
    flex: 1;
    margin-left: 0.156rem;
    font-size: 0.17rem;
}

.login-content .item input::-webkit-input-placeholder {
    color: #999999;
}

.login-btn {
    display: block;
    background: #4B83F0;
    color: #ffffff;
    font-weight: bold;
    font-size: 0.17rem;
    border-radius: 0.04rem !important;
    width: 4rem;
    height: 0.52rem;
    margin: 0 auto;
}

.jizhu {
    display: flex;
    align-items: center;
    font-size: 0.145rem;
    color: #CDCDCD;
    margin-bottom: 0.375rem;
}

.jizhu input {
    width: 0.17rem;
    height: 0.17rem;
    margin: 0 0.09rem 0 0;
}


::v-deep .el-input__inner {
    height: 0.49rem!important;
    border: none;
    background-color: #FFFFFF;
}
::v-deep {
    .el-input.is-disabled .el-input__inner {
        background-color: #FFFFFF;
    }
    .el-input__prefix {
        display: none;
    }
    .el-input--prefix .el-input__inner {
        padding-left: 0.156rem;
    }
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #CDCDCD;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    color: #4B83F0;
    background-color: #4B83F0;
    border-color: #4B83F0;
}
</style>
