<template>
    <div @click.stop>
        <el-dialog custom-class="dialog"
                   :destroy-on-close="true"
                   :close-on-click-modal = "false"
                   title=""
                   :visible.sync="youVisible"
                   ref="dialog"
                   :before-close="youClose"
                   width="50vw"
                   top="8vw"
        >
            <div slot="title" class="new-evaluationTitle">
                {{title_text}}
            </div>
            <div class="flex flex-justify-content-between flex-child-center" style="margin-bottom: 0.2rem">
                <div style="display: flex;align-items: center;">
                    <div style="display: flex;align-items: center;cursor: pointer;" @click="shopClick">
                        <div style="margin-right: 0.1rem;color: #999999;">商品全名</div>
                        <el-input v-model="chooseShopDetail.name" placeholder="请选择" disabled></el-input>
                    </div>
                    <div style="display: flex;align-items: center;">
                        <div style="margin-right: 0.1rem;color: #999999;">库存情况</div>
                        <el-select v-model="formShop.type" placeholder="请选择"@change="staffType($event)">
                            <el-option v-for="item in staff_typeList" :key="item.id" :label="item.name"
                                       :value="item.id"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="button">友好仓库</div>
            </div>
            <div class="table">
                <div class="flex table_list">
                    <div v-for="(item,index) in tableData" :key="index" class="table_box">
                        <div>{{item.name}}</div>
                        <div>{{item.value}}</div>
                    </div>
                </div>

            </div>
<!--            <el-table-->
<!--                v-loading="loading"-->
<!--                style="width: 100%"-->
<!--                class=""-->
<!--                stripe-->
<!--                ref="multipleTabless"-->
<!--                :data="tableData"-->

<!--            >-->
<!--                <el-table-column v-for="item in tableTitle" :key="item.name" :prop="item.value" :label="item.name"-->
<!--                                 :width="item.width" :align="item.align || 'left'  " :formatter="item.formatter">-->
<!--                </el-table-column>-->
<!--            </el-table>-->

            <div slot="footer" class="dialog-footer padding-30 no-padding-left no-padding-right no-padding-top">
                <div class="button_box">
                    <!--                <el-button type="primary" @click="shopEnter">确认(Enter)</el-button>-->
                    <el-button type="warning" style="background: #FFFFFF;border-color: #E9E9E9;color: #666666;"  @click="youClose">退出(Esc)</el-button>
                </div>
            </div>
        </el-dialog>

    </div>
</template>

<script>
export default {
    name: "Youdian",
    props:{
        /**
         * 弹框点开关闭
         * */
        youVisible: {
            default: false,
            type: Boolean
        },
        /**
         * 头部文字
         * */
        title_text: {
            default: '友店库存查询',
            type: String
        },
        /**
         *  商品选中值
         * */
        chooseShopDetail: Object,
    },

    data() {
        return {
            staff_typeList:[
                {
                    id:0,
                    name:'全部'
                },
                {
                    id:1,
                    name:'库存不为0'
                },
                {
                    id:2,
                    name:'库存为0'
                },
            ],
            tableTitle: [
                // {
                //     name: '行号',
                //     value: 'code',
                //     width:'80',
                //     formatter: (row) => {
                //         return  row.index+1;
                //     }
                // },
                // {
                //     name: '货号',
                //     value: 'code',
                //     width:'160'
                // },
                // {
                //     name: '商品名称',
                //     value: 'name',
                //     width:'180'
                // },
                // {
                //     name: 'sku',
                //     value: 'goods_sku',
                //     width:'170'
                // },
                // {
                //     name: '规格',
                //     value: 'goods_spec',
                //     width:'180'
                // },
                // {
                //     name: '型号',
                //     value: 'goods_model',
                //     width:'100'
                // },
                // {
                //     name: '实际数量',
                //     value: 'stock',
                //     width:'150'
                // },
            ],
            tableData: [],
            loading: false,
            dataDetail:'',// 当前已选择行
            dataindex: 0, // 当前选择行的index
            /**
             * 传参
             * */
            formShop: {
                goods_item_id:'',
                type: 0,
            },
            shop:{
                name:''
            }
        }
    },
    mounted() {
    },
    created() {
    },
    methods: {
        /**
         * 商品名称选择
         * */
        shopassignment() {
            this.formShop.goods_item_id = this.chooseShopDetail.goods_item_id
            this.search()
        },
        /**
         * 商品点击
         * */
        shopClick() {
            this.$parent.chooseShopShow();
        },
        assignment() {
            this.formShop.goods_item_id = ''
            this.formShop.type = 0
            // this.chooseShopDetail = {}
        },
        /**
         *  库存情况选择
         * */
        staffType(e) {
            this.formShop.type = e
            this.search()
        },
        /**
         * 筛选
         * */
        search() {
            this.dataindex = 0
            this.dataDetail = this.tableData[this.dataindex]
            this.InterfaceList()
        },
        /**
         * 确认
         * */
        // inventoryEnter() {
        //     this.dataDetail = this.tableData[this.dataindex]
        //     this.$emit('inventoryClick', this.dataDetail);
        //     this.$parent.inventoryClose();
        // },
        /**
         * 弹框关闭
         * */
        youClose() {
            this.$parent.youClose();
        },
        // 刷新
        onRefresh() {
            this.loading = true
            this.formShop.type = 0
            this.InterfaceList()
        },
        //列表接口
        async InterfaceList() {
            await this.$axios.get('api/pos_client/base/get_stock_other',{
                params:{
                    ...this.formShop
                }}).then(res => {
                    this.tableData = res.data.data
                    this.loading = false
                }
            )
        },
    }
}
</script>

<style scoped lang="scss">
.table {
    min-height: 5rem;
    .table_list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    .table_box {
        overflow: scroll;
        min-height: 0.5rem;
        line-height: 0.3rem;
        border: 0.01rem solid #E9E9E9;
        >div:nth-child(1) {
            width: 1.4rem;
            height: 0.5rem;
            line-height: 0.5rem;
            text-align: center;
            background: #F1F1F1!important;

        }
        >div:nth-child(2) {
            width: 1.4rem;
            text-align: center;
            background: #FFFFFF!important;
            //border: 0.01rem solid #E9E9E9;
            border-top: none;
        }
    }
}
::v-deep {
    .el-input {
        width: 2.18rem;
        height: 0.55rem!important;
        margin-right: 0.2rem;

    }
    .el-input__inner {
        height: 0.55rem!important;
        border: 0.01rem solid #CDCDCD;
    }
    .el-input.is-disabled .el-input__inner {
        background: #FFFFFF;
        border: 0.01rem solid #CDCDCD;
        color: #606266;
    }
    .el-button {
        width: 1rem;
    }
    .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
        background: none;
    }
    .dialog {
        background: #FFFFFF;
        height: 7.5rem;
    }
    .el-dialog__footer {
        text-align: right;
    }
    .el-dialog__header {
        //border-bottom: 0.01rem solid #E9E9E9;
    }
    .el-dialog__body {
        padding:0.1rem 0.2rem 0.1rem 0.2rem;
    }
    .el-button {
        width: 1rem;
    }
    .el-table {
        height: 5.28rem;
        background-color: #FFF;
        border: 0.01rem solid #E9E9E9;
        overflow-y: auto;
    }
    .el-table th.el-table__cell.is-leaf {
        background: #F1F1F1!important;
    }
    .el-table__header-wrapper {
        background: #F1F1F1!important;
    }
}
.button {
    width: 1.22rem;
    height: 0.36rem;
    border: 0.01rem solid #4B83F0;
    border-radius: 0.05rem;
    font-size: 0.16rem;
    font-weight: 400;
    color: #333333;
    line-height: 0.36rem;
    text-align:  center;
}
.bottom_bottom {
    padding: 0.05rem 0.45rem;
    background-color: #FFF;
}
</style>
