<template>
    <div @click.stop>
        <el-dialog custom-class="dialog"
                   :destroy-on-close="true"
                   :close-on-click-modal = "false"
                   title=""
                   :visible.sync="newCountVisible"
                   ref="dialog"
                   :before-close="newCountClose"
                   width="93vw"
                   top="2.5vw"
        >
            <div slot="title" class="new-evaluationTitle">
                {{title_text}}
            </div>
            <div class="flex flex-child-center" style="margin-bottom: 0.1rem">
                <div style="display: flex;align-items: center;" class="item_box">
                    <div style="margin-right: 0.1rem;color: #999999;width: 0.7rem;font-size: 0.14rem;">盘点人</div>
                    <el-select placeholder="请选择盘点人"
                               v-model="operation_id"
                               filterable :filter-method="dataFilter"
                               @visible-change="visibleHideSelectInput"
                               @change="onPatientChange($event)"
                               clearable
                    >
                        <el-option v-for="item in deviceIdList"
                                   :key="item.id"
                                   :value="item.id"
                                   :label="item.username"></el-option>
                    </el-select>
                </div>
                <div style="display: flex;align-items: center;" class="item_box">
                    <div style="margin-right: 0.1rem;color: #999999;width: 0.7rem;font-size: 0.14rem;">监盘人</div>
                    <el-select placeholder="请选择监盘人"
                               v-model="supervision_id"
                               filterable :filter-method="dataFilter"
                               @visible-change="visibleHideSelectInput"
                               @change="onsupervisionChange($event)"
                               clearable
                    >
                        <el-option v-for="item in deviceIdList"
                                   :key="item.id"
                                   :value="item.id"
                                   :label="item.username"></el-option>
                    </el-select>
                </div>
                <div style="display: flex;align-items: center;" class="item_box">
                    <div style="margin-right: 0.1rem;color: #999999;width: 0.7rem;font-size: 0.14rem;font-size: 0.14rem;">仓库</div>
                    <el-select placeholder="请选择仓库"
                               v-model="ware_code"
                               filterable :filter-method="dataFilterware"
                               @visible-change="visibleHideSelectInput"
                               @change="onwareChange($event)"
                               clearable
                    >
                        <el-option v-for="item in warehouse"
                                   :key="item.ware_code"
                                   :value="item.ware_code"
                                   :label="item.name"></el-option>
                    </el-select>
                </div>
                <div style="display: flex;align-items: center;margin-right: 0;" class="item_box" >
                    <div style="margin-right: 0.1rem;color: #999999;width: 0.7rem;font-size: 0.14rem;">截至日期</div>
                    <el-date-picker type="date" placeholder="选择日期"  value-format="yyyy-MM-dd"
                                    v-model="check_time"></el-date-picker>
                </div>
            </div>
            <div class="flex flex-child-center" style="margin-bottom: 0.1rem" >
                <div style="display: flex;align-items: center;" class="item_box" @click="shopClick">
                    <div style="margin-right: 0.1rem;color: #999999;width: 0.7rem;font-size: 0.14rem;">商品录入</div>
                    <el-input  placeholder="请输入" v-model="shopName" disabled></el-input>
                </div>
            </div>
            <div style="height: 7.15rem" ref="takeCenter">
                <el-table
                    v-loading="loading"
                    style="width: 100%"
                    class=""
                    stripe
                    height= "7.15rem"
                    ref="shopTable"
                    :data="tableData"
                    :row-style="rowStyle"
                    @row-click="handledbsupplier"
                    :row-class-name="tableRowClassName"
                >
                    <el-table-column v-for="(item,index) in tableTitle.slice(0,7)" :key="item.name" :prop="item.value" :label="item.name"
                                     :width="item.width" :align="item.align || 'center'  " :formatter="item.formatter">
                    </el-table-column>
                    <el-table-column label="盘点数量" width="200">
                        <!-- 通过isageSelected显示或隐藏 -->
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.goods_number" placeholder="输入"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column v-for="(item,index) in tableTitle.slice(8,13)" :key="item.name" :prop="item.value" :label="item.name"
                                     :width="item.width" :align="item.align || 'center'  " :formatter="item.formatter">
                    </el-table-column>
                    <el-table-column label="备注" width="200">
                        <!-- 通过isageSelected显示或隐藏 -->
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.remark" placeholder="输入"></el-input>
                        </template>
                    </el-table-column>
                </el-table>
            </div>


            <div slot="footer" class="dialog-footer padding-30 no-padding-left no-padding-right no-padding-top">
                <div class="button_box">
                    <el-button type="primary" @click="newCountEnter">保存</el-button>
                    <el-button type="primary" style="background: #CF7AE2;border-color: #CF7AE2;color: #FFFFFF;" @click="del">删除</el-button>
                    <el-button type="warning" style="background: #FFFFFF;border-color: #E9E9E9;color: #666666;"  @click="newCountClose">退出</el-button>
                </div>
            </div>
        </el-dialog>

    </div>
</template>

<script>

export default {
    name: "newCount",
    props:{
        /**
         * 弹框点开关闭
         * */
        newCountVisible: {
            default: false,
            type: Boolean
        },
        /**
         * 选择商品
         * */
        shopListNew:Object,
        /**
         * 键盘事件
         * */
        keynum: Number,
        /**
         * 头部文字
         * */
        title_text: {
            default: '新增盘点',
            type: String
        },
        /**
         * 编辑
         * */
        editStockDetail:Object,
    },

    data() {
        return {
            // searchData: [],
            tableTitle: [
                {
                    name: '行号',
                    value: 'index',
                    width:'70',
                    formatter: (row) => {
                        return  row.index+1;
                    }
                },
                {
                    name: '存货编号',
                    value: 'goods_code',
                    width:'120',
                },
                {
                    name: '存货全名',
                    value: 'goods_name',
                },
                // {
                //     name: '规格',
                //     value: 'goods_spec',
                // },
                {
                    name: '商品条码',
                    value: 'goods_sku',
                    width:'200',
                },
                {
                    name: '单位',
                    value: 'unit_name',
                    width:'80',
                },
                {
                    name: '库存数量',
                    value: 'stock',
                    width:'90',
                },
                // {
                //     name: '盘点数量',
                //     value: 'goods_number',
                // },
                {
                    name: '盘点盈亏',
                    value: 'number',
                    width:'90',
                    formatter: (row) => {
                        let a = ''
                        if(row.goods_number) {
                            a = row.goods_number - row.stock
                        }
                        return a;
                    }
                },
                {
                    name: '批次号',
                    value: 'batch_name',
                },
                {
                    name: '生产日期',
                    value: 'manufacture',
                },
                {
                    name: '保质期（天）',
                    value: 'guarantee',
                },
                {
                    name: '到期日期',
                    value: 'validity',
                },
                // {
                //     name: '备注',
                //     value: 'remark',
                // },
            ],
            tableData: [],
            loading: false,
            dataDetail:'',// 当前已选择行
            dataindex: 0, // 当前选择行的index
            warehouseDetail:{},
            searchData: [],
            staff_typeList:[],
            deviceIdList:[],
            patientList:[],
            check_time:new Date(),
            uuid:'',
            operation_id:'',
            supervision_id:'',
            ware_code:'',
            warehouseList:[],
            warehouse:[],
            shopName:''
        }
    },
    mounted() {
    },
    created() {
        this.$store.watch((state, getters) => {
            this.warehouseDetail = state.warehouseDetail
        }, () => {

        })
    },
    methods: {
        shopArrASS() {
            let a = this.shopListNew
            // console.log(a)
            let bool = false
            for (let i = 0; i < this.tableData.length; i++) {
                if (this.tableData[i].batch_id == a.batch_id) {
                    bool = true
                }
            }
            if(bool) {
                this.$message.error('请不要重复添加商品!');
            } else{
                let b = {}
                b.goods_number = 0
                b.goods_code = a.code
                b.goods_name = a.name
                b.goods_spec = a.goods_spec
                b.goods_sku = a.goods_sku
                b.unit_name = a.price_item.name
                b.stock = a.stock
                b.batch_name = a.batch_name
                b.manufacture = a.manufacture
                b.guarantee = a.guarantee
                b.validity = a.validity
                b.default_unit = a.main_unit
                b.batch_id = a.batch_id
                b.goods_id = a.id
                b.goods_item_id = a.goods_item_id
                b.remark = ''
                this.tableData.push(b)
                this.dataindex = this.tableData.length-1
                this.$nextTick(() => {
                    this.scrollFileLocation()
                    // this.$refs.countTable.bodyWrapper.scrollTop = this.$refs.countTable.bodyWrapper.scrollHeight;
                })
            }
        },
        /**
         * 商品选择
         * */
        shopClick() {
            // this.$parent.selectionShow()
            this.$parent.chooseShopShow()
        },
        /**
         * 选择盘点人
         * */
        onPatientChange(e) {
            this.operation_id = e
        },
        /**
         * 选择监盘人
         * */
        onsupervisionChange(e) {
            this.supervision_id = e
        },
        /**
         * 选择仓库
         * */
        onwareChange(e) {
            this.ware_code = e
            this.$store.commit('setWare', this.ware_code)
            this.tableData = []
            this.dataindex = 0
        },
        /**
         * 自定义筛选方法
         */
        dataFilter(val) {
            if (val) {
                let filterResult = [];
                let originalData = JSON.parse(JSON.stringify(this.patientList));
                originalData.filter((item) => {
                    if (item.username.includes(val)) {
                        filterResult.push(item);
                    }
                })
                this.deviceIdList = filterResult

            } else {
                this.deviceIdList = this.patientList;
            }
        },
        dataFilterware(val) {
            if (val) {
                let filterResult = [];
                let originalData = JSON.parse(JSON.stringify(this.warehouseList));
                originalData.filter((item) => {
                    if (item.name.includes(val)) {
                        filterResult.push(item);
                    }
                })
                this.warehouse = filterResult

            } else {
                this.warehouse = this.warehouseList;
            }
        },
        /**
         * 当下拉框出现时触发
         * */
        visibleHideSelectInput(val) {
            if(val) {
                this.deviceIdList = JSON.parse(JSON.stringify(this.patientList));
            }
        },
        staffType(e) {
            this.formData.ware_code = e
        },
        search() {
            this.loading = true
            this.InterfaceList()
        },
        assignment() {
            this.uuid =this.$store.state.user.userInfo.user_info.id
            this.operation_id = this.$store.state.user.userInfo.user_info.id
            this.ware_code = this.warehouseDetail.ware_code
            this.$store.commit('setWare', this.ware_code)
            this.tableData = []
            this.dataindex = 0
            this.check_time = new Date()
            this.supervision_id = ''
        },
        /**
         * 编辑赋值
         * */
        editAssignment() {
            // console.log('this.editStockDetail',this.editStockDetail)
            this.uuid =this.$store.state.user.userInfo.user_info.id
            this.operation_id = this.editStockDetail.operation_id
            this.ware_code = this.editStockDetail.ware_code
            this.$store.commit('setWare', this.ware_code)
            this.check_time = this.editStockDetail.check_time
            this.supervision_id = this.editStockDetail.supervision_id
            let a = []
            this.editStockDetail.checkitem.map((item,index) => {
                let b = {
                    goods_number:item.number,
                    goods_code:item.goods_code,
                    goods_name:item.goods_name,
                    goods_spec:item.goods_spec,
                    goods_sku:item.goods_sku,
                    unit_name:item.unit.name,
                    stock:item.stock,
                    batch_name:item.batch_name,
                    manufacture:item.manufacture,
                    guarantee:item.guarantee,
                    validity:item.validity,
                    default_unit:item.default_unit,
                    batch_id:item.batch_id,
                    goods_id:item.goods_id,
                    goods_item_id:item.goods_item_id,
                    remark:item.remark,
                }
                a.push(b)
            })
            this.tableData = a
            this.dataindex = 0
        },
        /**
         * 删除
         * */
        del() {
            this.tableData.splice(this.dataindex, 1)
        },
        /**
         * table 滚动条
         * */
        scrollFileLocation() {
            const tableHeight = this.$refs.takeCenter.offsetHeight
            const tableHeader = this.$refs.shopTable.$refs.bodyWrapper.children[0].children[1].children[0].clientHeight
            const tableBodyHeight = tableHeight - tableHeader;
            const itemHeight = this.$refs.shopTable.$refs.bodyWrapper.children[0].children[1].children[0].clientHeight
            this.$nextTick(() => {
                const showNum = tableBodyHeight / itemHeight;
                let scrolled = this.$refs.shopTable.bodyWrapper.scrollTop;
                const start = Math.round(scrolled / itemHeight);
                const end = Math.round(start + showNum) - 1;
                let offSet = 0;
                if (start - this.dataindex > 1) {
                    this.$refs.shopTable.bodyWrapper.scrollTop = this.dataindex * itemHeight;
                    return;
                }
                if (this.dataindex - end > 1) {
                    this.$refs.shopTable.bodyWrapper.scrollTop = (this.dataindex - showNum + 1) * itemHeight;
                    return;
                }
                if (this.dataindex < start) {
                    offSet = scrolled % itemHeight;
                    scrolled -= itemHeight + offSet;
                } else if (this.dataindex > end) {
                    offSet = (scrolled + itemHeight * showNum) % itemHeight;
                    scrolled += 50;
                } else {
                    return;
                }
                this.$refs.shopTable.bodyWrapper.scrollTop = scrolled;
            });
        },
        keyupVip() {
            let Detail = {}
            Detail = this.utils.keyupVipCilck(this.tableData,this.keynum,-1,-1,this.dataindex,this.dataDetail)
            this.dataindex = Detail.dataindex
            this.dataDetail = Detail.dataDetail
            if(this.keynum == 38) {
                this.scrollFileLocation()
            } else if(this.keynum == 40) {
                this.scrollFileLocation()
            }
        },
        //改变表格选中某一行    的颜色
        rowStyle(row) {
            if(row.rowIndex == this.dataindex){
                let rowBackground = {}
                rowBackground.background = '#DCEDFF'
                return rowBackground
            }
        },
        tableRowClassName({ row, rowIndex }) {
            row.index = rowIndex;
        },
        //选择某一行
        handledbsupplier(row) {
            // console.log('row',row)
            this.dataDetail=row
            this.dataindex=row.index
        },
        /**
         *  分页
         * */
        handleCurrentChangePage(e) {
            this.InterfaceList()
        },
        /**
         * 确认
         * */
        newCountEnter() {
            // this.dataDetail = this.tableData[this.dataindex]
            // this.$emit('newCountClick', this.dataDetail);
            // this.$parent.newCountClose();
            if(this.operation_id == '') {
                this.$message.error('请选择盘点人!');
                return;
            }
            if(this.ware_code == '') {
                this.$message.error('请选择仓库!');
                return;
            }
            if(this.check_time == '') {
                this.$message.error('请选择日期!');
                return;
            }
            if(this.supervision_id == '') {
                this.$message.error('请选择监盘人!');
                return;
            }
            let bool = false
            for (let i = 0; i < this.tableData.length; i++) {
                if (!this.tableData[i].goods_number) {
                    bool = true
                }
            }
            if(bool) {
                this.$message.error('盘点数不能为空!');
                return;
            }
            if(this.title_text == "新增盘点") {
                this.$axios.post('api/pos_client/order/add_stock_check', this.$qs.stringify({
                    uuid:this.uuid,
                    ware_code:this.ware_code,
                    operation_id:this.operation_id,
                    check_time:this.check_time,
                    supervision_id:this.supervision_id,
                    goods_item:this.tableData
                })).then(res => {
                    if (res.data.code == 200) {
                        this.$parent.newCountClose();
                        this.$emit('newCountClick');
                    }
                })
            } else {
                this.$axios.post('api/pos_client/order/edit_stock_check', this.$qs.stringify({
                    uuid:this.uuid,
                    check_code:this.editStockDetail.check_code,
                    ware_code:this.ware_code,
                    operation_id:this.operation_id,
                    check_time:this.check_time,
                    supervision_id:this.supervision_id,
                    goods_item:this.tableData
                })).then(res => {
                    if (res.data.code == 200) {
                        this.$parent.newCountClose();
                        this.$emit('newCountClick');
                    }
                })
            }

        },
        /**
         * 弹框关闭
         * */
        newCountClose() {
            this.$parent.newCountClose();
        },
        //列表接口
        async InterfaceList() {
            //获取员工
            await this.$axios.get('api/pos_client/base/get_staff_list').then(res => {
                this.patientList = res.data.data;
                this.deviceIdList = this.patientList;
            })
            //获取仓库
            this.$axios.get('api/pos_client/base/get_ware_list').then(res => {
                this.warehouseList =  res.data.data
                this.warehouse = this.warehouseList;
            })
        },
    }
}
</script>

<style scoped lang="scss">
.item_box {
    margin-right: 0.2rem;
}
//.button {
//    width: 1rem;
//    height: 0.55rem;
//    background: #4B83F0;
//    box-shadow: 0px 0px 0.2rem 0px rgba(146,146,146,0.5);
//    border-radius: 0.05rem;
//    font-size: 0.16rem;
//    font-weight: 400;
//    color: #FFFFFF;
//    line-height: 0.55rem;
//    text-shadow: 0px 0px 0.2rem rgba(146,146,146,0.5);
//    text-align:  center;
//    cursor: pointer;
//}
::v-deep {
    .el-input.is-disabled .el-input__inner {
        background: #FFFFFF;
        border: 0.01rem solid #CDCDCD;
        color: #606266;
    }
    .el-dialog__body {
        padding: 0.1rem 0.2rem!important;
    }
    .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
        background: none;
    }
    .dialog {
        background: #F1F1F1;
        height: 10rem;
    }
    .el-dialog__footer {
        text-align: right;
    }
    .el-dialog__header {
        border-bottom: 0.01rem solid #E9E9E9;
        font-weight: 600;
    }
    .el-input {
        width: 3.5rem!important;
        height: 0.55rem!important;
        //margin-right: 30px;

    }
    .el-input__inner {
        height: 0.55rem!important;
        border: none!important;
    }
    .el-dialog__body {
        padding:0.1rem 0.2rem 0.2rem 0.2rem;
    }
    .el-button {
        width: 1rem;
    }
    .el-table {
        height: 7.15rem;
        background-color: #FFF;
        overflow-y: auto;
        .el-input {
            width: 1.5rem!important;
            height: 0.3rem!important;
            //margin-right: 30px;

        }
        .el-input__inner {
            height: 0.3rem!important;
            border: none!important;
        }
    }
}
.background {
    //border: 1px solid #4B83F0;
    background: #DCEDFF;
    border-radius: 0.2rem;
}
.backgroundnone {
    background: #FFFFFF;
}
.tabBox {
    width: 2rem;
    background-color: #FFF;
    margin-right: 0.1rem;
    >div {
        height: 0.5rem;
        line-height: 0.5rem;
        text-align: center;
    }
    >div:nth-child(1) {
        font-size: 0.16rem;
        font-weight: 500;
        color: #333333;
    }
}
.bottom_bottom {
    padding: 0.05rem 0.45rem;
    //background-color: #FFF;
}
.bottom_bottom .el-pagination {
    display: flex;
    justify-content: flex-end;
}
</style>
