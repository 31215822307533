<template>
    <div @click.stop>
        <el-dialog custom-class="dialog"
                   :destroy-on-close="true"
                   :close-on-click-modal = "false"
                   title=""
                   :visible.sync="orderVisible"
                   ref="dialog"
                   :before-close="orderClose"
                   width="75vw"
                   top="2vw"
        >
            <div slot="title" class="new-evaluationTitle">
                {{title_text}}
            </div>
            <div class="flex " style="margin-bottom: 0.2rem">
                <div style="display: flex;align-items: center;">
                    <div style="margin-right: 0.1rem;color: #999999;">小票单号</div>
                    <el-input v-model="formShop.order_no" placeholder="输入单号" @keyup.enter.native="search"></el-input>
                </div>
                <div style="display: flex;align-items: center;">
                    <div style="margin-right: 0.1rem;color: #999999;">开始日期</div>
                    <el-date-picker type="date" placeholder="请选择" v-model="formShop.start_time"
                                    value-format="yyyy-MM-dd"></el-date-picker>
                </div>
                <div style="display: flex;align-items: center;">
                    <div style="margin-right: 0.1rem;color: #999999;">结束日期</div>
                    <el-date-picker type="date" placeholder="请选择" v-model="formShop.end_time"
                                    value-format="yyyy-MM-dd"></el-date-picker>
                </div>
                <div class="button" @click="search">查询</div>
                <div class="button" @click="print" style="margin-left: 0.2rem;background: #CF7AE2;border-color: #CF7AE2;">打印</div>
            </div>
            <div style="height:2.5rem; margin-bottom: 0.2rem " ref="takeCenter">
                <el-table
                    v-loading="loading"
                    style="width: 100%"
                    class=""
                    stripe
                    ref="shopTable"
                    :data="tableData1"
                    height= "2.5rem"
                    :row-style="rowStyle"
                    @row-click="handledbsupplier"
                    :row-class-name="tableRowClassName"
                >
                    <el-table-column
                        label="小票单号"
                        center
                        width="180"
                        >
                        <template slot-scope="scope">
                            <div style="display: flex">
                                {{scope.row.order_no}}
                                <div v-if="scope.row.order_type == 2" style="color: #CF7AE2;">
                                    （退）
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column v-for="item in tableTitle1" :key="item.name" :prop="item.value" :label="item.name"
                                     :width="item.width" :align="item.align || 'left'  " :formatter="item.formatter">
                    </el-table-column>
                </el-table>
            </div>
<!--            <div class="table">-->
<!--                <div class="flex table_list">-->
<!--                    <div class="table_box" style="width: 10%">-->
<!--                        <div >小票号</div>-->
<!--                        <div >{{details.order_no?details.order_no:''}}</div>-->
<!--                    </div>-->
<!--                    <div class="table_box" style="width: 10%">-->
<!--                        <div >结算金额</div>-->
<!--                        <div >{{details.amount_real?details.amount_real:''}}</div>-->
<!--                    </div>-->
<!--                    <div class="table_box" style="width: 20%">-->
<!--                        <div>会员卡号</div>-->
<!--                        <div >{{details.user?details.user.card_code:''}}</div>-->
<!--                    </div>-->
<!--                    <div class="table_box" style="width: 10%">-->
<!--                        <div>持卡人</div>-->
<!--                        <div>{{details.user?details.user.username:''}}</div>-->
<!--                    </div>-->
<!--&lt;!&ndash;                    <div class="table_box">&ndash;&gt;-->
<!--&lt;!&ndash;                        <div>收银员编号</div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <div>{{details.staff_id?details.staff_id:''}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--                    <div class="table_box" style="width: 10%">-->
<!--                        <div>收银员</div>-->
<!--                        <div>{{details.staff_name?details.staff_name:''}}</div>-->
<!--                    </div>-->
<!--                    <div class="table_box" style="width: 40%">-->
<!--                        <div >整单备注</div>-->
<!--                        <div>{{details.order_remark?details.order_remark:''}}</div>-->
<!--                    </div>-->
<!--                </div>-->

<!--            </div>-->
            <div style="height:5rem; ">
                <el-table
                    v-loading="loading"
                    style="width: 100%"
                    class=""
                    stripe
                    :data="tableData"
                    height= "5rem"
                >
                    <el-table-column v-for="item in tableTitle" :key="item.name" :prop="item.value" :label="item.name"
                                     :width="item.width" :align="item.align || 'left'  " :formatter="item.formatter">
                    </el-table-column>
                </el-table>
            </div>

            <div slot="footer" class="dialog-footer padding-30 no-padding-left no-padding-right no-padding-top">
                <div class="button_box">
                    <!--                <el-button type="primary" @click="shopEnter">确认(Enter)</el-button>-->
                    <el-button type="warning" style="background: #FFFFFF;border-color: #E9E9E9;color: #666666;"  @click="orderClose">退出(Esc)</el-button>
                </div>
            </div>
        </el-dialog>

    </div>
</template>

<script>
export default {
    name: "inventoryList",
    props:{
        /**
         * 弹框点开关闭
         * */
        orderVisible: {
            default: false,
            type: Boolean
        },
        /**
         * 头部文字
         * */
        title_text: {
            default: '小票查询',
            type: String
        },

        /**
         * 键盘事件
         * */
        keynum: Number,
    },

    data() {
        return {
            tableTitle1: [
                // {
                //     name: '小票号',
                //     value: 'order_no',
                //     formatter: (row) => {
                //         console.log('row',row)
                //         let a = ''
                //         if(row.order_type == 2) {
                //             a = row.order_no
                //         }
                //         //         return  row.index+1;
                //     }
                // },
                {
                    name: '结算金额',
                    value: 'amount_real',
                },
                {
                    name: '会员卡号',
                    value: 'user.card_code',
                },
                {
                    name: '持卡人',
                    value: 'user.username',
                    width:'80'
                },
                {
                    name: '收银员',
                    value: 'staff_name',
                },
                {
                    name: '整单备注',
                    value: 'order_remark',
                    // width:'180'
                }
            ],
            tableTitle: [
                // {
                //     name: '行号',
                //     value: 'code',
                //     width:'70',
                //     formatter: (row) => {
                //         return  row.index+1;
                //     }
                // },
                {
                    name: '货号',
                    value: 'goods_code',
                    // width:'160'
                },
                {
                    name: '商品名称',
                    value: 'goods_name',
                    // width:'180'
                },
                {
                    name: '单位',
                    value: 'unit_name',
                    width:'80'
                },
                {
                    name: '商品条码',
                    value: 'goods_sku',
                    width:'200'
                },
                // {
                //     name: '规格',
                //     value: 'goods_spec',
                //     // width:'180'
                // },
                // {
                //     name: '型号',
                //     value: 'goods_model',
                //     // width:'180'
                // },
                {
                    name: '零售价',
                    value: 'price_selling',
                    width:'100'
                },
                {
                    name: '数量',
                    value: 'stock_sales',
                    width:'100'
                },
                {
                    name: '折扣',
                    value: 'discount_rate',
                    // width:'100'
                },
                {
                    name: '成交金额',
                    value: 'deal_amount',
                    // width:'100'
                },
            ],
            tableData: [],
            tableData1:[],
            loading: false,
            warehouseDetail:{},
            dataDetail:'',// 当前已选择行
            dataindex: 0, // 当前选择行的index
            /**
             * 传参
             * */
            formShop: {
                order_no: '',
                end_time:'',
                start_time:'',
            },
            details:{}
        }
    },
    mounted() {
    },
    created() {
        this.$store.watch((state, getters) => {
            this.warehouseDetail = state.warehouseDetail
        }, () => {

        })
    },
    methods: {
        /**
         * 打印
         * */
        async print() {
            if(this.tableData1.length<=0) {
                this.$message.error('请先选择要打印的小票!');
                return;
            }
            await this.$axios.get('api/pos_client/order/order_print',{params:{
                    desk_code:this.$store.state.user.userInfo.other_info.desk_code,
                    store_code:this.$store.state.user.userInfo.other_info.store_code,
                    order_no:this.tableData1[this.dataindex].order_no
                }
            })
        },
        assignment() {
            this.formShop.start_time = ''
            this.formShop.end_time = ''
            this.tableData1 = []
            this.tableData=[]
        },
        /**
         * 筛选
         * */
        search() {
            this.dataindex = 0
            // if(this.tableData.length>0) {
            //     this.dataDetail = this.tableData[this.dataindex]
            // }
            this.InterfaceList()

        },
        /**
         * table 滚动条
         * */
        scrollFileLocation() {
            const tableHeight = this.$refs.takeCenter.offsetHeight
            const tableHeader = this.$refs.shopTable.$refs.bodyWrapper.children[0].children[1].children[0].clientHeight
            const tableBodyHeight = tableHeight - tableHeader;
            const itemHeight = this.$refs.shopTable.$refs.bodyWrapper.children[0].children[1].children[0].clientHeight
            this.$nextTick(() => {
                const showNum = tableBodyHeight / itemHeight;
                let scrolled = this.$refs.shopTable.bodyWrapper.scrollTop;
                const start = Math.round(scrolled / itemHeight);
                const end = Math.round(start + showNum) - 1;
                let offSet = 0;
                if (start - this.dataindex > 1) {
                    this.$refs.shopTable.bodyWrapper.scrollTop = this.dataindex * itemHeight;
                    return;
                }
                if (this.dataindex - end > 1) {
                    this.$refs.shopTable.bodyWrapper.scrollTop = (this.dataindex - showNum + 1) * itemHeight;
                    return;
                }
                if (this.dataindex < start) {
                    offSet = scrolled % itemHeight;
                    scrolled -= itemHeight + offSet;
                } else if (this.dataindex > end) {
                    offSet = (scrolled + itemHeight * showNum) % itemHeight;
                    scrolled += 50;
                } else {
                    return;
                }
                this.$refs.shopTable.bodyWrapper.scrollTop = scrolled;
            });
        },
        keyupVip() {
            let Detail = {}
            Detail = this.utils.keyupVipCilck(this.tableData1,this.keynum,-1,-1,this.dataindex,this.dataDetail)
            this.dataindex = Detail.dataindex
            this.dataDetail = Detail.dataDetail
            if(this.keynum == 38) {
                this.scrollFileLocation()
            } else if(this.keynum == 40) {
                this.scrollFileLocation()
            }
            this.tableData = this.tableData1[this.dataindex].orderitem
        },
        //改变表格选中某一行    的颜色
        rowStyle(row) {
            if(row.rowIndex == this.dataindex){
                let rowBackground = {}
                rowBackground.background = '#DCEDFF'
                return rowBackground
            }
        },
        tableRowClassName({ row, rowIndex }) {
            row.index = rowIndex;
        },
        //选择某一行
        handledbsupplier(row) {
            // console.log('row',row)
            this.dataDetail=row
            this.dataindex=row.index
            this.tableData = this.tableData1[this.dataindex].orderitem
        },
        /**
         *  分页
         * */
        handleCurrentChangePage(e) {
            this.InterfaceList()
        },
        /**
         * 确认
         * */
        // inventoryEnter() {
        //     this.dataDetail = this.tableData[this.dataindex]
        //     this.$emit('inventoryClick', this.dataDetail);
        //     this.$parent.inventoryClose();
        // },
        /**
         * 弹框关闭
         * */
        orderClose() {
            this.$parent.orderClose();
        },
        // 刷新
        // onRefresh() {
        //     this.loading = true
        //     this.InterfaceList()
        // },
        //列表接口
        async InterfaceList() {
            await this.$axios.get('api/pos_client/order/get_ticket_list',{
                params:{
                    store_code:this.$store.state.user.userInfo.other_info.store_code,
                    desk_code:this.$store.state.user.userInfo.other_info.desk_code,
                    ...this.formShop
                }}).then(res => {
                    // if(res.data.data.length == 0) {
                    //     this.$message.error('请输入正确的小票单号!');
                    //     return;
                    // }
                    this.dataindex = 0
                    this.tableData1 =  res.data.data
                    this.tableData = res.data.data[this.dataindex].orderitem
                    this.loading = false
                    this.scrollFileLocation()
                }
            )
        },
    }
}
</script>

<style scoped lang="scss">
.table {
    min-height: 1rem;
    margin-bottom: 0.2rem;
    border: 0.01rem solid #E9E9E9;
    .table_list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    .table_box {
        width: 16.6%;
        overflow: scroll;
        >div:nth-child(1) {
            //width: 16.6%;
            height: 0.5rem;
            line-height: 0.5rem;
            text-align: center;
            background: #F1F1F1!important;
            //border: 0.01rem solid #E9E9E9;
        }
        >div:nth-child(2) {
            //width: 16.6%;
            height: 0.5rem;
            line-height: 0.5rem;
            text-align: center;
            background: #FFFFFF!important;
            //border: 0.01rem solid #E9E9E9;
            border-top: none;
        }
    }
}
::v-deep {
    .el-input {
        width: 2.18rem;
        height: 0.55rem!important;
        margin-right: 0.2rem;

    }
    .el-input__inner {
        height: 0.55rem!important;
        border: 0.01rem solid #CDCDCD;
    }
    .el-input.is-disabled .el-input__inner {
        background: #FFFFFF;
        border: 0.01rem solid #CDCDCD;
        color: #606266;
    }
    .el-button {
        width: 1rem;
    }
    .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
        background: none;
    }
    .dialog {
        background: #FFFFFF;
        height: 10.2rem;
    }
    .el-dialog__footer {
        text-align: right;
    }
    .el-dialog__header {
        //border-bottom: 0.01rem solid #E9E9E9;
    }
    .el-dialog__body {
        padding:0.1rem 0.2rem 0.1rem 0.2rem;
    }
    .el-button {
        width: 1rem;
    }
    .el-table {
        height: 5.29rem;
        background-color: #FFF;
        border: 0.01rem solid #E9E9E9;
        overflow-y: auto;
    }
    .el-table th.el-table__cell.is-leaf {
        background: #F1F1F1!important;
    }
    .el-table__header-wrapper {
        background: #F1F1F1!important;
    }
}
.button {
    width: 1rem;
    height: 0.55rem;
    background: #4B83F0;
    box-shadow: 0px 0px 0.2rem 0px rgba(146,146,146,0.5);
    border-radius: 0.05rem;
    font-size: 0.16rem;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 0.55rem;
    text-shadow: 0px 0px 0.2rem rgba(146,146,146,0.5);
    text-align:  center;
}
.bottom_bottom {
    padding: 0.05rem 0.45rem;
    background-color: #FFF;
}
.bottom_bottom .el-pagination {
    display: flex;
    justify-content: flex-end;
}
</style>
