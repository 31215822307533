<template>
    <div @click.stop>
        <el-dialog custom-class="dialog"
                   :destroy-on-close="true"
                   :close-on-click-modal = "false"
                   title=""
                   :visible.sync="topUpVisible"
                   ref="dialog"
                   :before-close="topUpClose"
                   width="30vw"
                   top="12vw"
        >
            <div slot="title" class="new-evaluationTitle">
                {{title_text}}
            </div>
            <div class="new-evaluation">
                <el-form  :model="queryParams" @submit.native.prevent ref="form" :rules="rules">
                    <el-form-item label="VIP卡号">
                        <el-input v-model="topUpForm.card_code" ref="inputPrice" disabled></el-input>
                    </el-form-item>
                    <div class="flex flex-justify-content-between textBox">
                        <div>
                            <div>
                                持卡人：{{  topUpForm&&topUpForm.username? topUpForm.username:''}}
                            </div>
                            <div>
                                充值日期：{{ queryParams.recharge_time }}
                            </div>
                        </div>
                        <div>
                            <div>
                                充值前余额：{{ topUpForm.usercard && topUpForm.usercard.balance?topUpForm.usercard.balance:''}}
                            </div>
                            <div>
                                经手人：{{ $store.state.user.userInfo.user_info.username }}
                            </div>
                        </div>
                    </div>
                    <el-form-item label="充值金额" prop="money">
                        <el-input v-model="queryParams.money" @input="allNumber"  ref="inputPrice"></el-input>
                    </el-form-item>
                    <el-form-item label="赠送金额">
                        <el-input v-model="queryParams.give_money" @input="allgive" ref="inputPrice"></el-input>
                    </el-form-item>
                    <el-form-item label="备注内容">
                        <el-input v-model="queryParams.remark" ref="inputPrice" type="textarea" resize="none" :rows="4"  ></el-input>
                    </el-form-item>
                    <!--                    <el-button type="primary" class="cardbutton" @click="cardclick()">读IC卡</el-button>-->
                </el-form>

            </div>
            <div slot="footer" class="dialog-footer padding-30 no-padding-left no-padding-right no-padding-top">
                <div class="button_box">
                    <el-button type="primary" @click="topUpEnter">确认(Enter)</el-button>
                    <el-button type="warning" style="background: #FFFFFF;border-color: #E9E9E9;color: #666666;"  @click="topUpClose">取消(Esc)</el-button>
                </div>
            </div>
        </el-dialog>
    </div>

</template>

<script>
export default {
    name: "topUp",
    props:{
        /**
         * 弹框点开关闭
         * */
        topUpVisible: {
            default: false,
            type: Boolean
        },
        /**
         * 头部文字
         * */
        title_text: {
            default: 'VIP充值中心',
            type: String
        },
        /**
         *  会员卡选中值
         * */
        topUpForm: Object,

    },
    data() {
        return {
            queryParams:{
                recharge_time:this.utils.dateTypeFormat('YYYY-mm-dd', new Date()),
                give_money:'',
                money:'',
                remark:''
            },
            rules: {
                money: [
                    {required: true,message: '请输入金额', trigger: 'blur'}
                ],

            },
        }
    },
    methods: {
        /**
         * 进页面重新赋值
         * */
        assignment() {
            this.queryParams.money = ''
            this.queryParams.give_money = ''
            this.recharge_time.remark=''
            this.recharge_time.recharge_time = this.utils.dateTypeFormat('YYYY-mm-dd', new Date())
        },
        allNumber() {
            this.queryParams.money = this.utils.onlyNumOnePoint(this.queryParams.money);
        },
        allgive() {
            this.queryParams.give_money = this.utils.onlyNumOnePoint(this.queryParams.give_money);
        },
        /**
         * 弹框关闭
         * */
        topUpClose() {
            this.$parent.topUpClose();
        },
        /**
         * 输入款回车事件 确认事件
         * */
        topUpEnter() {
            this.queryParams.store_code = this.$store.state.user.userInfo.other_info.store_code
            this.queryParams.desk_code = this.$store.state.user.userInfo.other_info.desk_code
            this.queryParams.card_code = this.topUpForm.card_code
            this.queryParams.system_user_id = this.$store.state.user.userInfo.user_info.id
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    let formData = {...this.queryParams};
                    //添加
                    // console.log(formData)
                    this.$axios.post('api/pos_client/user/user_card_recharge', this.$qs.stringify(formData)).then(res => {
                        if (res.data.code == 200) {
                            this.$parent.topUpClose();
                            this.$refs.form.resetFields();
                            this.queryParams.give_money=''
                            this.queryParams.remark=''
                            this.$emit('topUpClick', this.queryParams);
                        }
                    })
                }
            });
            // this.$emit('topUpClick', this.queryParams);
            // this.inputBlurs()
        },
    }
}
</script>

<style scoped lang="scss">
::v-deep {
    .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before, .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label:before {
        content: "";
        margin-left: -0.05rem;
    }
    .el-input.is-disabled .el-input__inner {
        color: #333333;
        background: #ffffff;
    }
.el-dialog__footer {
    text-align: center;
}
.el-dialog__header {
    border-bottom: 0.01rem solid #E9E9E9;
}
.el-input {
    width: 3.6rem;
}
.el-textarea {
    width: 3.6rem;
    //max-height: 300px;
}
.el-form-item {
    display: flex;
    align-items: center;
    margin-bottom: 0.3rem;
}
.el-form {
    padding: 0 0.3rem;
    //display: flex;
    //align-items: center;
    //justify-content: space-between;
}
.textBox {
    width: 3.7rem;
    font-size: 0.14rem;
    font-weight: 400;
    color: #666666;
    margin-left: 0.62rem;
    >div {
        margin-bottom: 0.2rem;
        >div:nth-child(2) {
            margin-top: 0.2rem;
        }
    }
}
}
</style>
